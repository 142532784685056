const keys = {
    COMMUNITY: {
        DISPLAY_NAME: "display_name",
        ID: "id",
        BIO: "bio",
        LINK: "more_details_link",
        FACEBOOK_LINK: "facebook_link",
        WASTE_TYPE: "waste_type"
    },
    PRODUCT: {
        DISPLAY_NAME: "product_name",
        UPC: "gtin",
        ID: "objectID",
        INSTRUCTIONS: "instructions",
        CLASSIFICATION: "classification",
        IMAGE_URL: "image_url"
    },
    CATEGORIES: {
        DISPLAY_NAME: "display_name",
        ID: "id",
        IMAGE: "image",
    },
    REWARDS: {
        SCAN_POINTS: 25,
        MISSING_PRODUCT_POINTS: 10,
        MONTHLY_LIMIT: 2000,
    },
    VERSION: "1.2.13",
}

export default keys;