export const Recyclable = (outer, inner) => {
    if (outer && inner) {
        if (outer.recyclable === "TRUE" && inner.recyclable === "TRUE") return 'Recyclable';
        if ((outer.recyclable === "TRUE" && inner.recyclable === "FALSE") || (outer.recyclable === "FALSE" && inner.recyclable === "TRUE")) return 'Partially Recyclable';
        if (outer.recyclable === "FALSE" && inner.recyclable === "FALSE") return 'Not Recyclable';
    }

    if (outer) {
        if (outer.recyclable === "TRUE") return 'Recyclable';
        else return 'Not Recyclable';
    }

    if (inner) {
        if (inner.recyclable === "TRUE") return 'Recyclable';
        else return 'Not Recyclable';
    }
}

// calculate rewards price options based on the different types of Tango reward structs (fixed-price vs. min/max)
export const getPriceChoices = (reward) => {
    let choices = [];
    if (reward.items.length === 1) {
        // this reward type gives a min and max and we can choose the values
        const minimum = reward.items[0].minValue;
        const maximum = reward.items[0].maxValue;

        if (minimum <= 1.0) {
            choices = [3, 5, 10, 15, 20];
        } else {
            for (var i = 0; i < 5; i++) {
                if (minimum + (i*5) <= maximum) {
                    choices.push(minimum + (i * 5));
                }
            }
        }
    } else if (reward.items.length > 1) {
        // this reward type has fixed options
        let fixedPrices = [];
        reward.items.forEach(item => {
            fixedPrices.push(item.faceValue);
        });
        fixedPrices.sort(function(a, b){return a-b});
        choices = fixedPrices.slice(0, 6);
    }

    return choices;
}

// Detects if device is on iOS 
export const isMobile = () => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /iphone|ipad|ipod|android/.test( userAgent );
}

// Detects if device is in standalone mode
export const isInStandaloneMode = () => {
    return (('standalone' in window.navigator) && (window.navigator.standalone)) || (window.matchMedia('(display-mode: standalone)').matches);
}

// Detects if device is on iOS 
export const isAndroid = () => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /android/.test( userAgent );
}