import { connect } from 'react-redux';
import { SetShowChangeCommunityTip } from '../../actions/appActions';
import { SetUserCommunity, FetchCommunities, FetchAllCommunities, LaunchCommunityBrowser, ClearCommunitiesList, BatchLoadCommunities } from '../../actions/communityActions';
import Component from './component';

const mapStateToProps = state => {
    return {
        open: state.communities.launchCommunityBrowser,
        storedCommunityIds: state.app.storedCommunityIds,
        communities: state.communities.list,
        communitiesByLocation: state.communities.communitiesByLocation,
        fetching: state.inputs.fetchingCommunity,
        language: state.inputs.language,
        communityLoadFeedback: state.communities.loadFeedback,
        recycleCommunity: state.communities.recycle,
        compostCommunity: state.communities.compost,
        isUserSignedIn: state.user.signedIn,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        selectCommunity: (communityIds, language) => {
            dispatch(SetUserCommunity(communityIds, language));
        },
        closeCommunityBrowser: () => {
            dispatch(LaunchCommunityBrowser(false));
        },
        loadCommunities: (wasteType) => {
            dispatch(FetchAllCommunities());
        },
        clearCommunitiesList: () => {
            dispatch(ClearCommunitiesList());
        },
        setShowCommunityChangeTip: (value) => {
            dispatch(SetShowChangeCommunityTip(value));
        },
        batchSelectCommunities: (ids) => {
            dispatch(BatchLoadCommunities(ids, true, 'en'));
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Component);