import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#F9F9F9', // main background color
        height: '100vh',
        width: '100%',
        top: '10vh',
        position: 'absolute',
        // overflow: 'hidden',
        overflowY: 'scroll',
        
    },
    modifiedDialog: {
        position: 'absolute',
        borderRadius: '30px',
        bottom: -55,
        width: '100%',
        height: '30%',
        margin: 0,
        paddingTop: '30px',
        paddingLeft: '1%',
        paddingRight: '1%',
    },
    messageContainer: {
        margin: '.5em 0',
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    messageImage: {
        objectFit: 'contain',
        width: '100%',
    },
    quickInfo: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    sponsor: {
        zIndex: 1,
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: '100%',
        textAlign: 'center',
        [theme.breakpoints.up('xs')]: {
            height: '105px',
        },
        [theme.breakpoints.up('sm')]: {
            height: '150px',
        },
        [theme.breakpoints.up('md')]: {
            height: '160px',
        },
        [theme.breakpoints.up('lg')]: {
            height: '110px',
        },
    },
    quickInfoIcon: {
        height: '80%',
        width: '100%',
        fill: 'rgb(50, 226, 159)',
    },
    header: {
        color: 'rgb(53,53,61)',
        fontFamily: 'helvetica-neue-lt-std-65-medium',
        fontSize: 18,
        padding: '10px 0',
    },
    scannerContainer: {
        height: '80vh',
    },
    installText: {
        color: 'rgb(53,53,61)',
        fontFamily: 'helvetica-neue-lt-std-65-medium',
        fontSize: 18,
        padding: '5% 5% 0 5%',
    },
    shareIcon: {
        display: 'inline',
        height: '20px',
    }
}));

export default useStyles;