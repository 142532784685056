import { makeStyles } from '@material-ui/core/styles';

const drawerWidth = '78%';

const useStyles = makeStyles(theme => ({
    header: {
        display: 'flex',
        minWidth: '100%',
        zIndex: 1,
        maxWidth: '100%',
        position: 'fixed',
        flex: 0,
    },
    appBar: {
        display: 'flex',
        backgroundColor: (props) => props.backgroundColor,
        height: '10vh',
        alignItems: 'center',
        justifyItems: 'center',
        width: '100%',
        paddingLeft: '3vw',
        paddingRight: '3vw',
    },
    communityIconContainer: {
        // marginLeft: 5,
    },
    communityLogo: {
        color: '#f4fbf7',
        alignSelf: 'center',
        height: 45,
        width: 45,
    },
    betterbinLogo: {
        height: '4vh',
        color: '#f4fbf7',
        alignSelf: 'center',
    },
    googleAttribution: {
        color: 'green',
        position: 'absolute',
        top: '100%',
        right: '0'
    },
    form: {
        display: "flex",
        flexDirection: 'row',
        justifyContent: 'space-between',
        margin: 0,
        maxWidth: '100%',
        alignItems: 'center',
        flexGrow: 1,
    },
    backButton: {
        color: 'white',
        minWidth: 0,
        flexShrink: 0,
        padding: 0,
    },
    searchBar: {
        display: 'flex',
        backgroundColor: 'rgb(244, 251, 247)',
        height: '40px',
        borderRadius: 15,
        overflow: 'hidden',
        marginLeft: 10,
        marginRight: 10,
    },
    searchIcon: {
        margin: '2px 6px 2px 6px',
        height: '100%'
    },
    historyButtonContainer: {
        height: '100%',
        paddingLeft: 0,
        paddingRight: 10,
    },
    historyIcon: {
        height: '100%'
    },
    rewardsButton: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: 5,
    },
    rewardsPoints: {
        color: 'white',
        textAlign: 'center',
    },
    wasteTrackerButton: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: 55,
        width: 55,
    },
    wasteTrackerIcon: {
        height: 45,
        width: 45,
        color: (props) => props.textColor,
    },
}));

export default useStyles;