// analytics
const firebase = require('firebase/app');
require('firebase/analytics');
require('firebase/auth');
require('firebase/database');

firebase.initializeApp({
    apiKey: "AIzaSyAn0eg0mQm5mYbLHh0I80FRIkIsH8emoy4",
    authDomain: "recycle-intel.firebaseapp.com",
    databaseURL: "https://recycle-intel.firebaseio.com",
    projectId: "recycle-intel",
    storageBucket: "recycle-intel.appspot.com",
    messagingSenderId: "513370313442",
    appId: "1:513370313442:web:0c9955af0d9ada88d084bb",
    measurementId: "G-TWS4F5XC4V"
});
const analytics = firebase.analytics();
const database = firebase.database();
const auth = firebase.auth();

auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL);

// axios
const axios = require('axios');
const LOCAL = "LOCAL";
const DEV = "DEV";
const LIVE = "LIVE";
const TESTING = "TESTING";
const WINDOWS_LAN = "WINDOWS_LAN";
const PROD = 'PROD';
const APP_VERSION = '_vWeb';

const getBaseURL = (branch) => {
    switch (branch) {
        case LOCAL: return 'http://localhost:5001/recycle-intel/us-central1/dev';
        case DEV: return 'https://us-central1-recycle-intel.cloudfunctions.net/dev';
        case TESTING: return 'https://us-central1-recycle-intel.cloudfunctions.net/testing';
        case LIVE: return 'https://us-central1-recycle-intel.cloudfunctions.net/api';
        case WINDOWS_LAN: return 'http://10.0.0.191:5001/recycle-intel/us-central1/nativeProd' + APP_VERSION;
        case PROD: return 'https://us-central1-recycle-intel.cloudfunctions.net/nativeProd' + APP_VERSION;
        default: console.log('Axios API Setup Error: branch does not exist'); return;
    }
}

// const api = axios.create({ baseURL: getBaseURL(DEV), timeout: 30000 });
const nativeApi = axios.create({ baseURL: 'https://us-central1-recycle-intel.cloudfunctions.net/nativeProd_v1_2_10'/*'https://us-central1-recycle-intel.cloudfunctions.net/nativeProd_v1_2_8'*/, timeout: 30000 });

export { /*api,*/ nativeApi, analytics, database, auth, firebase }