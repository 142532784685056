import { connect } from 'react-redux';
import { StoreCommunityId } from '../../actions/appActions';
import { EmailPasswordLogin, ClearFeedbackMessage, SetLanguage } from '../../actions/userActions';
import Component from './component';

const mapStateToProps = state => {
    return {
        language: state.inputs.language,
        signedIn: state.user.signedIn,
        feedback: state.user.feedback
    }
}

const mapDispatchToProps = dispatch => {
    return {
        emailPasswordLogin: (email, password) => {
            dispatch(EmailPasswordLogin(email, password));
        },
        clearFeedbackMessage: () => {
            dispatch(ClearFeedbackMessage());
        },
        setLanguage: (language) => {
            dispatch(SetLanguage(language));
        },
        storeCommunityId: (id) => {
            dispatch(StoreCommunityId(id));
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Component);