import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    inputField: {
        // flex: 4,
        backgroundColor: 'rgb(244, 251, 247)',
        border: '0',
        '&:focus': {
            outline: "none",
        },
        WebkitAppearance: 'none',
        borderRadius: '.3em .3em .3em .3em',
        fontSize: 14,
        margin: 0,
        minWidth: 0,
        width: '100%',
    },

});

export default useStyles;