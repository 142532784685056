import { connect } from 'react-redux';
import Component from './component';
import { SubmitProductQuestion } from '../../actions/productActions';

const mapStateToProps = state => {
    return {
        community: state.communities.selectedCommunities[state.communities.activeIndex],
        selectedProduct: state.products.selected,
        searchValue: state.inputs.search ? state.inputs.search : "",
        wasteType: state.communities.selectedCommunities[state.communities.activeIndex] ? state.communities.selectedCommunities[state.communities.activeIndex].waste_type : 'recycle'
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onSubmitProductFeedback: (product, category, photo, message, wasteType, searchValue) => {
            dispatch(SubmitProductQuestion(product, category, photo, message, wasteType, searchValue));
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Component)