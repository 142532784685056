import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    container: {
        top: '10vh',
        position: 'absolute',
        borderRadius: 15,
        margin: 10,
    },
    card: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        width: '100%',
        backgroundColor: 'white',
        borderRadius: 15,
        overflow: "hidden",
        padding: 10,
        flexGrow: 1,
    },
    productFeedbackIcon: {
        alignSelf: 'flex-end',
    },
    mediaWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start'
    },
    media: {
        objectFit: 'contain',
        flexGrow: 1,
        width: '100%',
        height: 150,
    },
    title: {
        color: 'black',
        fontFamily: 'helvetica-neue-lt-std-65-medium',
        marginTop: 15,
        marginBottom: 15,
        textAlign: 'center',
    },
    text: {
        marginTop: 15,
        marginBottom: 15,
        fontFamily: 'helvetica-neue-lt-std-65-medium',
        fontSize: 16,
    },
    predictionNotice: {
        margin: '5% 0',
        backgroundColor: '#F4FBF7',
        border: '6px solid rgb(50, 226, 159)',
        borderRadius: '20px',
        marginBottom: '3%',
        padding: '7%',
    },
    predictionText: {
        fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
    },
    submitButton: {
        width: '100%',
        margin: '5% 0 1% 0',
        color: '#f5fbf9',
        height: '20%',
        backgroundColor: '#35353d',
    },
    footer: {
        display: 'flex',
        width: '100%',
        justifyContent: 'flex-end',
        padding: '.5em 1em'
    }
}));

export default useStyles;