const actionTypes = {

    SET_COMMUNITY_CONTACT_EMAIL: "SET_COMMUNITY_CONTACT_EMAIL",

    SET_QR_IDS: "SET_QR_IDS",
    SET_LOADING_COMMUNITY_BY_QR: "SET_LOADING_COMMUNITY_BY_QR",

    SET_SELECTED_COMMUNITIES: "SET_SELECTED_COMMUNITIES",
    SET_ACTIVE_INDEX: "SET_ACTIVE_INDEX",
    SET_COMMUNITY_NEWS: "SET_COMMUNITY_NEWS",
    SET_COMMUNITY_TIPS: "SET_COMMUNITY_TIPS",
    SET_COMMUNITY_ALTERNATE: "SET_COMMUNITY_ALTERNATE",
    SET_COMMUNITY_DROPOFFS: "SET_COMMUNITY_DROPOFFS",
    SET_COMMUNITY_SPONSORS: "SET_COMMUNITY_SPONSORS",
    SET_COMMUNITY_ICON: "SET_COMMUNITY_ICON",
    SET_COMMUNITY_ALTERNATE_HEADER_LOGO: "SET_COMMUNITY_ALTERNATE_HEADER_LOGO",

    SET_COMMUNITY_ALTERNATE_COMPOST: "SET_COMMUNITY_ALTERNATE_COMPOST",
    SET_COMMUNITY_ALTERNATE_RECYCLE: "SET_COMMUNITY_ALTERNATE_RECYCLE",
    SET_COMMUNITY_ALTERNATE_TAB: "SET_COMMUNITY_ALTERNATE_TAB",

    SET_CAMERA_DEVICE_ID: "SET_CAMERA_DEVICE_ID",

    SET_SHOW_CHANGE_COMMUNITY_TIP: "SET_SHOW_CHANGE_COMMUNITY_TIP",

    SET_PUBLIC_THREAD_REGISTRATION_CODE: "SET_PUBLIC_THREAD_REGISTRATION_CODE",
    SET_PUBLIC_THREAD_STATUS: "SET_PUBLIC_THREAD_STATUS",

    SET_STORED_COMMUNITY_IDS: "SET_STORED_COMMUNITY_IDS",
    SET_STORED_PUBLIC_THREAD_CODE: "SET_STORED_PUBLIC_THREAD_CODE",

    SET_COMMUNITY_LOAD_FEEDBACK: "SET_COMMUNITY_LOAD_FEEDBACK",

    FETCHING_USER_SCAN_HISTORY: "FETCHING_USER_SCAN_HISTORY",
    SET_USER_SCAN_HISTORY: "SET_USER_SCAN_HISTORY",

    // chat
    SET_CHAT_UNREAD_COUNT: "SET_CHAT_UNREAD_COUNT",
    SET_CHAT_CONVERSATIONS: "SET_CHAT_CONVERSATIONS",
    SELECT_CONVERSATION: "SELECT_CONVERSATION",
    SET_CHAT_LANGUAGE: "SET_CHAT_LANGUAGE",
    SET_TRANSLATING_CHAT: "SET_TRANSLATING_CHAT",
    SET_CHAT_INITIALIZED: "SET_CHAT_INITIALIZED",

    SET_WASTE_TYPE: "SET_WASTE_TYPE",

    SET_RECYCLE_FAQS: "SET_RECYCLE_FAQS",
    SET_COMPOST_FAQS: "SET_COMPOST_FAQS",

    SET_RECYCLE_COMMUNITY: "SET_RECYCLE_COMMUNITY",
    SET_COMPOST_COMMUNITY: "SET_COMPOST_COMMUNITY",
    SET_COMMUNITIES_BY_LOCATION: "SET_COMMUNITIES_BY_LOCATION",
    SET_COMMUNITY_TIPS_COMPOST: "SET_COMMUNITY_TIPS_COMPOST",
    SET_COMMUNITY_TIPS_RECYCLE: "SET_COMMUNITY_TIPS_RECYCLE",
    SET_COMMUNITY_NEWS_COMPOST: "SET_COMMUNITY_NEWS_COMPOST",
    SET_COMMUNITY_NEWS_RECYCLE: "SET_COMMUNITY_NEWS_RECYCLE",
    SET_COMMUNITY_DROP_OFFS_COMPOST: "SET_COMMUNITY_DROP_OFFS_COMPOST",
    SET_COMMUNITY_DROP_OFFS_RECYCLE: "SET_COMMUNITY_DROP_OFFS_RECYCLE",
    SET_COMMUNITY_SPONSORS_RECYCLE: "SET_COMMUNITY_SPONSORS_RECYCLE",
    SET_COMMUNITY_SPONSORS_COMPOST: "SET_COMMUNITY_SPONSORS_COMPOST",
    SET_COMMUNITY_SPONSORS_SELECTED: "SET_COMMUNITY_SPONSORS_SELECTED",

    SUBMIT_SEARCH: "SUBMIT_SEARCH",
    SET_SEARCH_VALUE: "SET_SEARCH_VALUE",
    SET_SEARCH_ERROR: "SET_SEARCH_ERROR",
    SET_PRODUCTS: "SET_PRODUCTS",
    SET_PHOTO_PROMPT_DATA: "SET_PHOTO_PROMPT_DATA",
    SET_SELECTED_PRODUCT: "SET_SELECTED_PRODUCT",
    SET_USER_COMMUNITY: "SET_USER_COMMUNITY",
    SET_USER_LANGUAGE: "SET_USER_LANGUAGE",
    SET_TRANSLATING_COMMUNITIES: "SET_TRANSLATING_COMMUNITIES",
    SET_COMMUNITY_INFO: "SET_COMMUNITY_INFO",
    SET_COMMUNITIES: "SET_COMMUNITIES",
    SET_COMMUNITY_TIPS: "SET_COMMUNITY_TIPS",
    SET_COMMUNITY_NEWS: "SET_COMMUNITY_NEWS",
    SET_COMMUNITY_DROP_OFFS: "SET_COMMUNITY_DROP_OFFS",
    SET_FETCHING_COMMUNITY: "SET_FETCHING_COMMUNITY",
    LAUNCH_COMMUNITY_BROWSER: "LAUNCH_COMMUNITY_BROWSER",

    SET_REWARDS_CATALOG: "SET_REWARDS_CATALOG",
    SET_SELECTED_REWARD: "SET_SELECTED_REWARD",
    SET_REWARDS_ALERT: "SET_REWARDS_ALERT",
    SET_USER_REWARDS_POINTS: "SET_USER_REWARDS_POINTS",
    SET_USER_MONTHLY_POINTS: "SET_USER_MONTHLY_POINTS",
    SET_USER_REWARD_HISTORY: "SET_USER_REWARD_HISTORY",
    SET_REDEMPTION_STATUS: "SET_REDEMPTION_STATUS",

    SET_CATEGROIES: "SET_CATEGROIES",
    SET_CATEGORY_ITEMS: "SET_CATEGORY_ITEMS",

    SET_WASTE_TRACKER_DATA: "SET_WASTE_TRACKER_DATA",
    SET_USER_WASTE_DATA: "SET_USER_WASTE_DATA",

    SET_EMAIL: "SET_EMAIL",

    LAUNCH_USER_CREATE_ACCOUNT: "LAUNCH_USER_CREATE_ACCOUNT",
    CREATE_USER_ACCOUNT: "CREATE_USER_ACCOUNT",
    USER_LOGIN: "USER_LOGIN",
    USER_SIGNIN_STATUS: "USER_SIGNIN_STATUS",
    USER_VERIFICATION_STATUS: "USER_VERIFICATION_STATUS",
    SET_USER_ACCOUNT_INFO: "SET_USER_ACCOUNT_INFO",
    SET_FEEDBACK_MESSAGE: "SET_FEEDBACK_MESSAGE",
    USER_REQUEST_STATUS: "USER_REQUEST_STATUS",
    USER_INSTALL_PROMPT: "USER_INSTALL_PROMPT",

    SET_MISSING_PRODUCTS: "SET_MISSING_PRODUCTS",
    SET_PRODUCT_REQUEST_STATUS: "SET_PRODUCT_REQUEST_STATUS",
    LOAD_MATERIAL_TYPES: "LOAD_MATERIAL_TYPES"
}

export default actionTypes;