import C from '../actions/actionTypes';
import K from '../utils/keys';
import { combineReducers } from 'redux';

export const products = (state = {}, action) => {
    switch (action.type) {
        case C.SET_PRODUCTS:
            return Object.assign({}, state, {
                list: action.payload
            })
        case C.SET_CATEGORY_ITEMS:
            return Object.assign({}, state, {
                list: action.payload
            })
        case C.SET_SELECTED_PRODUCT:
            return Object.assign({}, state, {
                selected: action.payload
            })
        case C.SET_MISSING_PRODUCTS:
            return Object.assign({}, state, {
                missing: {
                    list: action.payload.items,
                    airtableId: action.payload.airtableId,
                    status: action.status
                }
            })
        case C.SET_PRODUCT_REQUEST_STATUS:
            return Object.assign({}, state, {
                missing: {
                    productRequest: action.status
                }
            })
        case C.SET_PHOTO_PROMPT_DATA:
            return Object.assign({}, state, {
                photoPromptProduct: action.payload
            })

        default:
            return state;
    }
}

export const rewards = (state = {}, action) => {
    switch (action.type) {
        case C.SET_REWARDS_CATALOG:
            return Object.assign({}, state, {
                catalog: action.payload,
            });
        case C.SET_SELECTED_REWARD:
            return Object.assign({}, state, {
                selected: action.payload,
            });
        case C.SET_REWARDS_ALERT:
            return Object.assign({}, state, {
                showAlert: action.payload,
            });
        case C.SET_USER_REWARDS_POINTS:
            return Object.assign({}, state, {
                points: action.payload,
            });
        case C.SET_USER_MONTHLY_POINTS:
            return Object.assign({}, state, {
                monthlyPoints: action.payload,
            });
        case C.SET_USER_REWARD_HISTORY:
            return Object.assign({}, state, {
                redemptionHistory: action.payload,
            });
        case C.SET_REDEMPTION_STATUS:
            return Object.assign({}, state, {
                redemptionStatus: action.payload,
            });
        default: return state;
    }
}

export const user = (state = {}, action) => {
    switch (action.type) {
        case C.CREATE_USER_ACCOUNT:
            return Object.assign({}, state, {
                feedback: action.payload,
            });
        case C.SET_FEEDBACK_MESSAGE:
            return Object.assign({}, state, {
                feedback: action.payload,
            });
        case C.SET_USER_ACCOUNT_INFO:
            return Object.assign({}, state, {
                info: action.payload,
            });
        case C.USER_SIGNIN_STATUS:
            return Object.assign({}, state, {
                signedIn: action.payload,
            });
        case C.USER_VERIFICATION_STATUS:
            return Object.assign({}, state, {
                emailVerified: action.payload,
            });         
        case C.USER_REQUEST_STATUS:
            return Object.assign({}, state, {
                requestStatus: action.payload,
            }); 
        case C.SET_USER_WASTE_DATA:
            return Object.assign({}, state, {
                personalWasteTracker: action.payload,
            });
        case C.USER_INSTALL_PROMPT:
            return Object.assign({}, state, {
                installPromptState: action.payload,
            }); 
        case C.FETCHING_USER_SCAN_HISTORY:
            return Object.assign({}, state, {
                fetchingUserScanHistory: action.payload,
            });
        case C.SET_USER_SCAN_HISTORY:
            return Object.assign({}, state, {
                scanHistory: action.payload,
            });
        case C.SET_PUBLIC_THREAD_REGISTRATION_CODE:
            return Object.assign({}, state, {
                publicThread: Object.assign({}, state.publicThread, {
                    ...state.publicThread,
                    registrationCode: action.payload
                })
            });
        case C.SET_PUBLIC_THREAD_STATUS:
            return Object.assign({}, state, {
                publicThread: Object.assign({}, state.publicThread, {
                    ...state.publicThread,
                    status: action.payload
                })
            });
        default: return state;
    }
}

export const inputs = (state = {}, action) => {
    switch (action.type) {
        case C.SET_SEARCH_VALUE:
            return Object.assign({}, state, {
                search: action.payload
            });
        case C.SUBMIT_SEARCH:
            return Object.assign({}, state, {
                searching: action.payload
            });
        case C.SET_SEARCH_ERROR:
            return Object.assign({}, state, {
                searchError: action.payload
            });
        case C.SET_FETCHING_COMMUNITY:
            return Object.assign({}, state, {
                fetchingCommunity: action.payload
            });
        case C.SET_TRANSLATING_COMMUNITIES:
            return Object.assign({}, state, {
                translatingCommunities: action.payload
            });
        case C.SET_EMAIL:
            return Object.assign({}, state, {
                email: action.payload
            });
        case C.LOAD_MATERIAL_TYPES:
            return Object.assign({}, state, {
                materialTypes: action.payload
            });
        case C.SET_USER_LANGUAGE:
            return Object.assign({}, state, {
                language: action.payload
            });
        default: return state;
    }
}

export const app = (state = {}, action) => {
    switch (action.type) {
        case C.SET_QR_IDS:
            return Object.assign({}, state, {
                qrIds: action.payload
            });
        case C.SET_WASTE_TYPE:
            return Object.assign({}, state, {
                selectedWasteType: action.payload
            });
        case C.SET_STORED_COMMUNITY_IDS:
            return Object.assign({}, state, {
                storedCommunityIds: action.payload
            });
        case C.SET_STORED_PUBLIC_THREAD_CODE:
            return Object.assign({}, state, {
                storedPublicThreadCode: action.payload
            });
        case C.SET_SHOW_CHANGE_COMMUNITY_TIP:
            return Object.assign({}, state, {
                    showChangeCommunityTip: action.payload
                });
        case C.SET_CAMERA_DEVICE_ID:
            return Object.assign({}, state, {
                cameraDeviceId: action.payload
            });
        default: return state;
    }
}

export const communities = (state = {}, action) => {

    switch (action.type) {
        case C.SET_LOADING_COMMUNITY_BY_QR:
            return Object.assign({}, state, {
                loadCommunityByQr: action.payload,
            });
        case C.SET_SELECTED_COMMUNITIES:
            return Object.assign({}, state, {
                selectedCommunities: action.payload,
            });
        case C.SET_ACTIVE_INDEX:
            return Object.assign({}, state, {
                activeIndex: action.payload,
            });
        case C.SET_USER_COMMUNITY:
            return Object.assign({}, state, {
                selected: action.payload.selected,
                alternate: action.payload.alternate
            });
        case C.SET_COMMUNITY_LOAD_FEEDBACK:
            return Object.assign({}, state, {
                loadFeedback: action.payload,
            });
        case C.SET_RECYCLE_COMMUNITY:
            return Object.assign({}, state, {
                recycle: action.payload,
            });
        case C.SET_COMPOST_COMMUNITY:
            return Object.assign({}, state, {
                compost: action.payload,
            });
        case C.SET_COMMUNITIES_BY_LOCATION:
            return Object.assign({}, state, {
                communitiesByLocation: action.payload,
            });
        case C.SET_COMMUNITY_CONTACT_EMAIL:
            return Object.assign({}, state, {
                selectedCommunities: state.selectedCommunities.map(community => (community.id === action.payload.id) ? 
                { ...community, contact_email: action.payload.email } : community)
            });
        case C.SET_COMMUNITY_NEWS:
            return Object.assign({}, state, {
                selectedCommunities: state.selectedCommunities.map(community => (community.id === action.payload.id) ? 
                { ...community, local_information: action.payload.news } : community)
            });
        case C.SET_COMMUNITY_TIPS:
            return Object.assign({}, state, {
                selectedCommunities: state.selectedCommunities.map(community => (community.id === action.payload.id) ? 
                { ...community, tips: action.payload.tips } : community)
            });
        case C.SET_COMMUNITY_ALTERNATE:
            return Object.assign({}, state, {
                selectedCommunities: state.selectedCommunities.map(community => (community.id === action.payload.id) ? 
                { ...community, alternate_home_tab: action.payload.alternate } : community)
            });
        case C.SET_COMMUNITY_DROPOFFS:
            return Object.assign({}, state, {
                selectedCommunities: state.selectedCommunities.map(community => (community.id === action.payload.id) ? 
                { ...community, drop_off_locations: action.payload.dropOffs } : community)
            });
        case C.SET_COMMUNITY_SPONSORS:
            return Object.assign({}, state, {
                selectedCommunities: state.selectedCommunities.map(community => (community.id === action.payload.id) ? 
                { ...community, sponsors: action.payload.sponsors } : community)
            });
        case C.SET_COMMUNITY_ICON:
            return Object.assign({}, state, {
                selectedCommunities: state.selectedCommunities.map(community => (community.id === action.payload.id) ? 
                { ...community, icon: action.payload.icon } : community)
            });
        case C.SET_COMMUNITY_ALTERNATE_HEADER_LOGO:
            return Object.assign({}, state, {
                selectedCommunities: state.selectedCommunities.map(community => (community.id === action.payload.id) ? 
                { ...community, alternate_header_logo: action.payload.logo } : community)
            });
        case C.LAUNCH_COMMUNITY_BROWSER:
            return Object.assign({}, state, {
                launchCommunityBrowser: action.payload,
            });
        case C.SET_COMMUNITIES:
            return Object.assign({}, state, {
                list: action.payload
            });
        case C.SET_WASTE_TRACKER_DATA:
            return Object.assign({}, state, {
                wasteTracker: action.payload
            });

        default:
            return state;
            // if (!state.selected) {
            //     return Object.assign({}, state,
            //         {
            //             launchCommunityBrowser: true
            //         }
            //     );
            // } else return state;
    }
}

export const chat = (state = {}, action) => {
    switch (action.type) {
        case C.SET_CHAT_UNREAD_COUNT:
            return Object.assign({}, state, {
                unreadCount: action.payload,
            });
        case C.SET_CHAT_CONVERSATIONS:
            return Object.assign({}, state, {
                conversations: action.payload,
            });
        case C.SELECT_CONVERSATION:
            return Object.assign({}, state, {
                selectedConversation: action.payload,
            });
        case C.SET_CHAT_LANGUAGE:
            return Object.assign({}, state, {
                language: action.payload,
            });
        case C.SET_TRANSLATING_CHAT:
            return Object.assign({}, state, {
                translating: action.payload,
            });
        case C.SET_CHAT_INITIALIZED:
            return Object.assign({}, state, {
                initialized: action.payload,
            });
        default: return state;
    }
}

export const faqs = (state = {}, action) => {
    switch (action.type) {
        case C.SET_RECYCLE_FAQS:
            return Object.assign({}, state, {
                recycle: action.payload
            });
        case C.SET_COMPOST_FAQS:
            return Object.assign({}, state, {
                compost: action.payload
            });
        default: return state;
    }
}

export const categories = (state = {}, action) => {
    switch (action.type) {
        case C.SET_CATEGROIES:
            return Object.assign({}, state,
                {
                    list: action.payload
                }
            );
        case C.SET_CATEGORY_ITEMS:
            return Object.assign({}, state,
                {
                    items: action.payload
                }
            );

        default: return state;
    }
}

export const version = (state, action) => {
    return K.VERSION
}

export default combineReducers({
    products,
    inputs,
    communities,
    categories,
    version,
    user,
    rewards,
    app,
    faqs,
    chat,
})