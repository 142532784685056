import { connect } from 'react-redux';
import Component from './component';
import { GetUserWasteData, AddUserWasteData, ResetUserWasteDay } from '../../actions/personalWasteTrackerActions';
import { IsUserSignedIn } from '../../actions/userActions';

const mapStateToProps = state => {
    return {
        community: state.communities.selectedCommunities[state.communities.activeIndex] ? state.communities.selectedCommunities[state.communities.activeIndex]['display_name'] : null,
        userWasteData: state.user.personalWasteTracker ? state.user.personalWasteTracker : { rows: [], totalEmissionsReduced: 0, milesReduced: 0, homeEnergyReduced: 0},
        compostBucketWeight: state.communities.selectedCommunities[state.communities.activeIndex] ? state.communities.selectedCommunities[state.communities.activeIndex].compost_bucket_weight : 0,
        signedIn: state.user.signedIn,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getUserWasteData: (bucketWeight) => {
            dispatch(GetUserWasteData(bucketWeight));
        },
        addUserWasteData: (amount, bucketWeight) => {
            dispatch(AddUserWasteData(amount, bucketWeight));
        },
        resetUserWasteDay: (bucketWeight) => {
            dispatch(ResetUserWasteDay(bucketWeight));
        },
        userSignedIn: () => {
            dispatch(IsUserSignedIn());
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Component);