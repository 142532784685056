import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    container: {
        top: '10vh',
        position: 'absolute',
        bottom: 56,
        overflow: "hidden",
        overflowY: "scroll",
    },
    card: {
        margin: 10,
        borderRadius: 15,
        backgroundColor: 'white',
        padding: 10,
    },
    content: {
        display: 'flex',
        fontFamily: 'helvetica-neue-lt-std-65-medium',
        fontSize: 16,
    },
    requestPrompt: {
        display: 'inline-block',
        fontFamily: 'helvetica-neue-lt-std-65-medium',
        fontSize: 16,
    },
    photoPreview: {
        width: '100%',
        marginTop: 10,
        justifyContent: 'center',
        display: 'flex',
    },
    photo: {
        maxHeight: 200,
        maxWidth: '55%',
    },
    photoButton: {
        width: '100%',
        borderRadius: 50,
        backgroundColor: 'rgb(50, 226, 159)',
        color: '#35353d',
        marginTop: 10,
        textTransform: 'none',
    },
    submitButton: {
        width: '100%',
        borderRadius: 50,
        backgroundColor: 'rgb(50, 226, 159)',
        color: '#35353d',
        marginBottom: 30,
        marginTop: 10,
        textTransform: 'none',
    },
    requestField: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        padding: '3% 0',
        borderRadius: 50,
        backgroundColor: 'rgb(244, 251, 247)',
        margin: '0 0 5% 0',
        borderStyle: 'solid',
        borderColor: 'black',
        borderWidth: 1,
    },
    requestFieldText: {
        flex: 4,
        fontFamily: 'helvetica-neue-lt-std-65-medium',
        fontSize: 16,
        backgroundColor: 'Transparent',
        border: '0',
        '&:focus': {
            outline: "none",
        },
        padding: '0 .3em 0 .7em',
        WebkitAppearance: 'none',
        borderRadius: '.3em 0 0 .3em',
        margin: 0,
        minWidth: 0,
    },
    fieldLabel: {
        display: 'flex',
        justifyContent: 'left',
        marginTop: 15,
        marginLeft: 10
    },
}));

export default useStyles;
