import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    container: {
        top: '10vh',
        position: 'absolute',
        overflowY: 'scroll',
        bottom: 56,
    },
    card: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        padding: 10,
        backgroundColor: 'white',
    },
    mediaWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start'
    },
    media: {
        objectFit: 'contain',
        flexGrow: 1,
        width: '100%',
        height: 200,
    },
    header: {
        display: 'inline-block',
        color: 'black',
        fontFamily: 'helvetica-neue-lt-std-65-medium',
        padding: '0 6% .3% 0',
        margin: '5% 0 0 0',
        width: '100%',
        textAlign: 'center',
        fontSize: 20,
    },
    title: {
        color: 'black',
        fontFamily: 'helvetica-neue-lt-std-65-medium',
        padding: '0 6% .3% 0',
        textAlign: 'center',
        fontSize: 28,
    },
    text: {
        fontFamily: 'helvetica-neue-lt-std-65-medium',
        fontSize: 16,
    },
    tangoText: {
        marginTop: '-.9em',
    },
    submitButton: {
        fontFamily: 'helvetica-neue-lt-std-65-medium',
        fontSize: 16,
        textTransform: 'none',
        width: '100%',
        margin: '5% 0 1% 0',
        color: (props) => props.textColor,
        borderRadius: 50,
        backgroundColor: (props) => props.backgroundColor,
    },
    footer: {
        display: 'flex',
        width: '100%',
        justifyContent: 'flex-end',
        padding: '.5em 1em'
    },
    choosePrice: {
        display: 'flex',
        textAlign: 'center',
        margin: '5% 0 0 0',
    },
    priceContainer: {
        margin: 'auto',
        display: 'flex',
        flexDirection: 'column',
    },
    priceButton: {
        backgroundColor: (props) => props.backgroundColor,
    },
    priceText: {
        fontSize: '.7rem',
        marginTop: '8%',
    },
    pointsDisplay: {
        height: '5%',
        zIndex: 7,
    },
    accordion: {
        width: '100%',
    },
    redeemButton: {
        fontFamily: 'helvetica-neue-lt-std-65-medium',
        fontSize: 16,
        textTransform: 'none',
        borderRadius: 50,
        width: '40%',
        color: (props) => props.textColor,
        height: '20%',
        backgroundColor: (props) => props.backgroundColor,
    },
    backButton: {
        fontFamily: 'helvetica-neue-lt-std-65-medium',
        fontSize: 16,
        textTransform: 'none',
        borderRadius: 50,
        width: '40%',
        color: (props) => props.textColor,
        height: '20%',
    }
}));

export default useStyles;