import { connect } from 'react-redux';
import Component from './component';
import { SubmitPhotoPrompt } from '../../actions/productActions';
import { SubmitForPoints } from '../../actions/rewardsActions';

const mapStateToProps = state => {
    return {
        community: state.communities.selectedCommunities[state.communities.activeIndex],
        productInfo: state.products.photoPromptProduct ? state.products.photoPromptProduct : {name: "", upc: ""},
        searchList: state.products.list,
        searchValue: state.inputs.search ? state.inputs.search : "",
        wasteType: state.communities.selectedCommunities[state.communities.activeIndex] ? state.communities.selectedCommunities[state.communities.activeIndex].waste_type : 'recycle'
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onSubmitPhotoPrompt: (product, photo, searchValue, wasteType) => {
            dispatch(SubmitPhotoPrompt(product, photo, searchValue, wasteType));
            if (product.upc && wasteType === 'recycle') {
                dispatch(SubmitForPoints(product.upc, 'photo-prompt'));
            }
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Component)