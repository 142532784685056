import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Typography, Accordion, AccordionSummary, AccordionDetails, Avatar, Button, CircularProgress } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { withStyles } from '@material-ui/core/styles';

import ProgressiveImage from 'react-progressive-image';

import useStyles from './styles';

import betterbinLogo from '../../images/BetterBinWordLogo_WHITEandBLUE.png';

import CommunityLoadFeedback from './CommunityLoadFeedback/CommunityLoadFeedback';

const RecycleButton = withStyles({
    root: {
        boxShadow: 'none',
        textTransform: 'none',
        fontSize: 16,
        padding: '8px 12px',
        borderRadius: 50,
        lineHeight: 1.5,
        backgroundColor: '#f4fbf7',
        borderColor: 'rgb(50, 226, 159)',
        borderWidth: 3,
        border: 'solid',
        color: 'rgb(50, 226, 159)',
        width: '100%',
        marginRight: 5,
        justifyContent: 'center',
        '&:hover': {
            backgroundColor: 'rgb(50, 226, 159)',
            borderColor: 'rgb(50, 226, 159)',
            boxShadow: 'none',
            color: 'white',
        },
        '&:active': {
            boxShadow: 'none',
            backgroundColor: 'rgb(50, 226, 159)',
            borderColor: 'rgb(50, 226, 159)',
            color: 'white',
        },
        '&:focus': {
            boxShadow: 'none',
            backgroundColor: 'rgb(50, 226, 159)',
            borderColor: 'rgb(50, 226, 159)',
            color: 'white',
        },
    },
})(Button);

const CompostButton = withStyles({
    root: {
        boxShadow: 'none',
        textTransform: 'none',
        fontSize: 16,
        padding: '6px 12px',
        borderRadius: 50,
        lineHeight: 1.5,
        backgroundColor: '#f4fbf7',
        borderColor: '#4D6376',
        borderWidth: 3,
        border: 'solid',
        width: '100%',
        color: '#4D6376',
        marginLeft: 5,
        justifyContent: 'center',
        '&:hover': {
            backgroundColor: '#4D6376',
            borderColor: '#4D6376',
            boxShadow: 'none',
            color: 'white',
        },
        '&:active': {
            boxShadow: 'none',
            backgroundColor: '#4D6376',
            borderColor: '#4D6376',
            color: 'white',
        },
        '&:focus': {
            boxShadow: 'none',
            backgroundColor: '#4D6376',
            borderColor: '#4D6376',
            color: 'white',
        },
    },
})(Button);


const Component = ({ isUserSignedIn, setShowCommunityChangeTip, batchSelectCommunities, recycleCommunity, compostCommunity, open, storedCommunityIds, communityLoadFeedback, clearCommunitiesList, communitiesByLocation, communities, fetching, selectCommunity, loadCommunities, language }) => {
    const classes = useStyles();
    const history = useHistory();
    const {t} = useTranslation('common');

    const [wasteType, setWasteType] = useState('none');

    useEffect(() => {
        loadCommunities();
    }, []);

    const onPressRecycle = () => {
        setWasteType('recycle');
    }

    const onPressCompost = () => {
        setWasteType('compost');
    }

    const handleCommunityClick = (ids) => {
        if (wasteType === 'recycle' && compostCommunity) {
            setShowCommunityChangeTip(true);
        }

        if (wasteType === 'compost' && recycleCommunity) {
            setShowCommunityChangeTip(true);
        }

        selectCommunity(ids, language);
    }

    const showLoadingCircle = () => {
        if (!communities) return true;
        if (communities.length === 0) return true;
        if (fetching) return true;
        return false;
    }

    const handleNextWasteType = (wasteType) => {
        setWasteType('none');
        // loadCommunities(wasteType);
    }

    useEffect(() => {
        clearCommunitiesList(wasteType);
        setWasteType('none');
        console.log('storedIds:', storedCommunityIds);
        if (storedCommunityIds) {
            // selectCommunity(storedCommunityId);
            batchSelectCommunities(storedCommunityIds)
        }
    }, [open, isUserSignedIn]);

    return (
        <>
            {open &&
                <div className={classes.backDrop}>
                    <CommunityLoadFeedback wasteType={wasteType} open={communityLoadFeedback} selectAnother={handleNextWasteType} />
                    <div className={classes.colorOverlay}></div>
                    <img className={classes.betterbinLogo} src={betterbinLogo} alt="betterbin" />
                    <div className={classes.card}>
                        <div className={classes.header}>
                            <RecycleButton onClick={onPressRecycle}>
                                {t('communityBrowser.recycle')}
                            </RecycleButton>
                            <CompostButton onClick={onPressCompost}>
                                {t('communityBrowser.compost')}
                            </CompostButton>
                        </div>

                        <div className={classes.info}>
                            { wasteType === 'none' &&
                                <Typography className={classes.infoText}>
                                    {t('communityBrowser.chooseWasteType')}
                                </Typography>
                            }
                            {( wasteType === 'recycle' || wasteType === 'compost') &&
                                <Typography className={classes.infoText}>
                                    {t('communityBrowser.dropDownExplanation')}
                                </Typography>
                            }
                        </div>

                        {/* { (fetching && wasteType !== 'none') &&
                            <div className={classes.progressContainer}>
                                <CircularProgress />
                            </div>
                        } */}

                        { wasteType !== 'none' &&
                            <div className={classes.drillDownContainer}>
                                { (fetching) &&
                                    <div className={classes.progressContainer}>
                                        <CircularProgress />
                                    </div>
                                }
                                { (!fetching && communitiesByLocation!== null) &&
                                    <div className={classes.communities}>
                                        { Object.keys(communitiesByLocation[wasteType]).map(state => {
                                            const stateData = communitiesByLocation[wasteType][state];
                                            return (
                                                <Accordion>
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMoreIcon/>}
                                                    >
                                                        <Typography className={classes.communityText}>{state}</Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails className={classes.innerContainer}>
                                                        { Object.keys(stateData).map(county => {
                                                            const countyData = stateData[county];
                                                            return (
                                                                <Accordion>
                                                                    <AccordionSummary
                                                                        expandIcon={<ExpandMoreIcon/>}
                                                                    >
                                                                        <Typography className={classes.communityText}>{county}</Typography>
                                                                    </AccordionSummary>
                                                                    <AccordionDetails className={classes.innerContainer}>
                                                                        {countyData.map((community, i) => {
                                                                            return (
                                                                                <div className={classes.community} onClick={() => handleCommunityClick(community.id)}>
                                                                                    <ProgressiveImage src={community.icon.thumbnails.large.url} placeholder={community.icon.thumbnails.small.url}>
                                                                                        {(src) => <Avatar className={classes.logo} alt='location icon' src={src}></Avatar>}
                                                                                    </ProgressiveImage>
                                                                                    <Typography className={classes.communityText} >{community.display_name}</Typography>
                                                                                </div>
                                                                            )
                                                                        })}
                                                                    </AccordionDetails>
                                                                </Accordion>
                                                            )
                                                        })}
                                                    </AccordionDetails>
                                                </Accordion>
                                            )
                                        })}
                                    </div>
                                }
                            </div>
                        }
                    </div>
                </div>
            }
        </>
    )
}

export default Component;