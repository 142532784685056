import { connect } from 'react-redux';
import { GetRewardsCatalog, GetRewardsData } from '../../actions/rewardsActions.js';
import { FetchAccountInfo, IsUserEmailVerified, SendVerificationEmail } from '../../actions/userActions.js';
import Component from './component';

const mapStateToProps = state => {
    return {
        userEmailVerified: state.user.emailVerified,
        communityColor: state.communities.selectedCommunities[state.communities.activeIndex] ? state.communities.selectedCommunities[state.communities.activeIndex].community_color : "#32E29F",
        communityTextColor: state.communities.selectedCommunities[state.communities.activeIndex] ? state.communities.selectedCommunities[state.communities.activeIndex].community_text_color : "#000000de",
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getRewardsCatalog: () => {
            dispatch(GetRewardsCatalog());
        },
        getRewardsData: () => {
            dispatch(GetRewardsData());
        },
        fetchAccountInfo: () => {
            dispatch(FetchAccountInfo());
        },
        sendVerificationEmail: () => {
            SendVerificationEmail();
        },
        isUserEmailVerified: () => {
            dispatch(IsUserEmailVerified());
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Component);