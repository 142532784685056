import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    container: {
        top: '10vh',
        position: 'absolute',
        bottom: 56,
        overflow: "hidden",
        overflowY: "scroll",
    },
    card: {
        margin: 10,
        borderRadius: 15,
        backgroundColor: 'white',
        padding: 10,
    },
    requestPrompt: {
        display: 'inline-block',
        fontFamily: 'helvetica-neue-lt-std-65-medium',
    },
    categoryContainer: {
        color: '#f4fbf7',
        fontFamily: 'helvetica-neue-lt-std-65-medium',
        width: '100%',
        marginTop: 10,
        borderRadius: 50,
    },
    categorySelect: {
        backgroundColor: '#fafafa',
        border: 'none',
        cursor: 'pointer',
        overflow: 'hidden',
        outline: 'none',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        textAlign: 'center',
        color: '#000000de',
        fontSize: 16,
        borderRadius: 50,
    },
    photoPreview: {
        width: '100%',
        marginTop: 10,
        justifyContent: 'center',
        display: 'flex',
    },
    photo: {
        maxHeight: 200,
        maxWidth: '55%',
    },
    photoButton: {
        width: '100%',
        borderRadius: 50,
        backgroundColor: 'rgb(50, 226, 159)',
        color: '#35353d',
        marginTop: 10,
    },
    messageField: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        padding: '3% 0',
        borderRadius: 15,
        backgroundColor: '#fafafa',
        // marginTop: 5,
    },
    messageFieldText: {
        flex: 4,
        fontFamily: 'helvetica-neue-lt-std-65-medium',
        backgroundColor: 'Transparent',
        border: '0',
        '&:focus': {
            outline: "none",
        },
        padding: '0 .3em 0 .7em',
        WebkitAppearance: 'none',
        borderRadius: 15,
        margin: 0,
        minWidth: 0,
    },
    fieldLabel: {
        display: 'flex',
        justifyContent: 'left',
        marginTop: 15,
        marginLeft: 10,
    },
}));

export default useStyles;
