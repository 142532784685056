import { connect } from 'react-redux';

import Component from './component';

import { LaunchCommunityBrowser } from '../../actions/communityActions';
import { FetchAccountInfo, FetchUserScanHistory } from '../../actions/userActions';
import { SetSearchValue } from '../../actions/inputActions';
import { ResetProducts } from '../../actions/productActions';
import { SetRewardsAlert } from '../../actions/rewardsActions';

const mapStateToProps = (state) => {
    return {
        selectedWasteType: state.communities.selectedCommunities[state.communities.activeIndex] ? state.communities.selectedCommunities[state.communities.activeIndex].waste_type : "",
        recycleCommunity: state.communities.recycle,
        compostCommunity: state.communities.compost,
        community: state.communities.selectedCommunities[state.communities.activeIndex],
        wasteTrackerEnabled: state.communities.selectedCommunities[state.communities.activeIndex] ? state.communities.selectedCommunities[state.communities.activeIndex].personalWasteTrackerEnabled : false,
        rewardsPoints: state.rewards.points, 
        alternateCommunity: state.communities.alternate,
        communityExists: (state.communities.selectedCommunities[state.communities.activeIndex] ? true : false),
        wasteTrackerEnabled: state.communities.selectedCommunities[state.communities.activeIndex] ? state.communities.selectedCommunities[state.communities.activeIndex].waste_tracker_enabled : false,
        personalWasteTrackerEnabled: state.communities.selectedCommunities[state.communities.activeIndex] ? state.communities.selectedCommunities[state.communities.activeIndex].personal_waste_tracker_enabled : false,
        language: state.inputs.language,
        signedIn: state.user.signedIn,
        communityColor: state.communities.selectedCommunities[state.communities.activeIndex] ? state.communities.selectedCommunities[state.communities.activeIndex].community_color : "#32E29F",
        communityTextColor: state.communities.selectedCommunities[state.communities.activeIndex] ? state.communities.selectedCommunities[state.communities.activeIndex].community_text_color : "#000000de",
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onChangeCommunityClick: () => {
            dispatch(LaunchCommunityBrowser());
        },
        fetchAccountInfo: () => {
            dispatch(FetchAccountInfo());
        },
        onBackButtonClick: () => {
            dispatch(ResetProducts())
        },
        resetInputs: () => {
            dispatch(ResetProducts);
            dispatch(SetSearchValue(''));
        },
        fetchUserProductHistory: () => {
            dispatch(FetchUserScanHistory());
        },
        showRewardsAlert: () => {
            dispatch(SetRewardsAlert('scan'));
        }
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(Component);