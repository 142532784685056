import React from 'react';
import { useTranslation } from 'react-i18next';

import { useHistory } from 'react-router-dom';

// material ui
import { makeStyles } from '@material-ui/core/styles';

import { ReactSVG } from 'react-svg';
// images
import compostIcon from '../../images/iconfinder_-_Eco_Trash-Bin-Ecology-Environment-Compost-Waste_5315696.svg';
import dropOffIcon from '../../images/noun_Location_3257376.svg';
import trashIcon from '../../images/noun_Delete_2069630.svg';
import recycleIcon from '../../images/noun_checkmark_350059.svg';
import partiallyIcon from '../../images/noun_Warning_936850.svg';

const ConfigButton = ({ text, color, children }) => {
    const history = useHistory();
    const classes = makeStyles({
        configButton: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            color: 'white',
            backgroundColor: props => props.color,
            borderRadius: 50,
        },
        text: {
            display: 'inline-block',
            font: 'helvetica-neue-lt-std-65-medium',
            fontSize: 20,
            padding: '2%',
            verticalAlign: 'baseline',
        }
    })({ color });

    return (
        <div
            className={classes.configButton}
            onClick={() => history.push('/browse')}
        >
            {children}
            <div className={classes.text}>{text}</div>
        </div>
    )
};

const Component = ({ classification }) => {
    const {t} = useTranslation('common');
    const colors = {
        recycle: '#33e29f',
        partially: '#E2BF32 ',
        trash: '#E23232 ',
        compost: '#C332E2 ',
        altDropOff: '#32A6E2',
        default: 'white'
    }

    const classes = makeStyles({
        icon: {
            fill: 'white',
            width: '30px',
            height: '30px',
        },
    })();

    switch (parseInt(classification)) {
        case 1: return (
            <ConfigButton text={t('classification.recycle')} color={colors.recycle}><ReactSVG className={classes.icon} src={recycleIcon} beforeInjection={svg => {
                svg.classList.add(classes.icon)
                svg.setAttribute('style', classes.icon)
            }} /></ConfigButton>
        )
        case 2: return (
            <ConfigButton text={t('classification.partiallyRecycle')} color={colors.partially}><ReactSVG className={classes.icon} src={partiallyIcon} beforeInjection={svg => {
                svg.classList.add(classes.icon)
                svg.setAttribute('style', classes.icon)
            }} /></ConfigButton>
        )
        case 3: return (
            <ConfigButton text={t('classification.trash')} color={colors.trash}><ReactSVG className={classes.icon} src={trashIcon} beforeInjection={svg => {
                svg.classList.add(classes.icon)
                svg.setAttribute('style', classes.icon)
            }} /></ConfigButton>
        )
        case 4: return (
            <ConfigButton text={t('classification.compost')} color={colors.compost}><ReactSVG className={classes.icon} src={compostIcon} beforeInjection={svg => {
                svg.classList.add(classes.icon)
                svg.setAttribute('style', classes.icon)
            }} /></ConfigButton>
        )
        case 5: return (
            <ConfigButton text={t('classification.special')} color={colors.altDropOff}><ReactSVG className={classes.icon} src={dropOffIcon} beforeInjection={svg => {
                svg.classList.add(classes.icon)
                svg.setAttribute('style', classes.icon)
            }} /></ConfigButton>
        )
        case 6: return (
            <ConfigButton text={t('classification.partiallyCompostable')} color={colors.partially}><ReactSVG className={classes.icon} src={partiallyIcon} beforeInjection={svg => {
                svg.classList.add(classes.icon)
                svg.setAttribute('style', classes.icon)
            }} /></ConfigButton>
        )
        default: return (
            <ConfigButton text='Back' color={colors.default}><div></div></ConfigButton>
        )
    }
}

export default Component;