import { connect } from 'react-redux';
import { SetSearchValue, SubmitSearch } from '../../actions/inputActions';
// import { SubmitForPoints } from '../../actions/rewardsActions';
import Component from './component';

const mapStateToProps = state => {
    return {
        value: state.inputs.search,
        language: state.inputs.language
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onInput: value => {
            dispatch(SetSearchValue(value))
        },
        onSubmitSearch: (value, language) => {
            dispatch(SubmitSearch(value, language, 'text'));
            // dispatch(SubmitForPoints(value, 'scan'));
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Component);


