import React, { useEffect, useState } from 'react';
import { useHistory, Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// material-ui
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';

import useStyles from './styles';
import { withStyles } from '@material-ui/core';

// import {ReactComponent as PublicThreadLogo} from '../../../icons/PublicThread/PublicThread_Logo.svg';
import PublicThreadLogo from '../../../icons/PublicThread/PublicThread_Logo.png';
import PublicThread from './PublicThread/PublicThread';

const StyledSelect = withStyles({
    icon: {
        color: '#f5fbf9',
    }
})(Select);

const Component = ({ publicThreadStatus, communities, translateCommunities, currentLanguage, translating, communityColor, communityTextColor, userSignedIn, accountInfo, feedback, updateAccountInfo, fetchAccountInfo, signOut, signedIn, clearFeedbackMessage }) => {

    const classes = useStyles({
        backgroundColor: communityColor ? communityColor : '#32E29F',
        textColor: communityTextColor ? communityTextColor : '#000000de',
    });
    const {t, i18n} = useTranslation('common');
    let history = useHistory();


    const GreenCheckbox = withStyles({
        root: {
            color: communityColor,
            '&$checked': {
            color: communityColor,
            },
        },
        checked: {},
    })((props) => <Checkbox color="default" {...props} />);

    const [accountInputs, setAccountInputs] = useState({
        name: "",
        phoneNumber: "",
        enableEmails: false,
        enableTexts: false,
        address: "",
        city: "",
        state: '',
        zip: "",
    });

    useEffect(() => {
        clearFeedbackMessage()
        setAccountInputs(accountInfo);
    }, [accountInfo, clearFeedbackMessage, setAccountInputs]);

    useEffect(() => {
        fetchAccountInfo();
    }, [fetchAccountInfo]);

    useEffect(() => {
        userSignedIn();
        if (!signedIn) {
            history.push('/');
        }
    }, [signedIn, userSignedIn, history]);

    const handleTextChange = event => {
        setAccountInputs({
            ...accountInputs,
            [event.target.name]: event.target.value || ''
        });
    };

    const states = [
        { label: 'Alabama', value: 'AL' },
        { label: 'Alaska', value: 'AK' },
        { label: 'American Samoa', value: 'AS' },
        { label: 'Arizona', value: 'AZ' },
        { label: 'Arkansas', value: 'AR' },
        { label: 'California', value: 'CA' },
        { label: 'Colorado', value: 'CO' },
        { label: 'Connecticut', value: 'CT' },
        { label: 'Delaware', value: 'DE' },
        { label: 'District of Columbia', value: 'DC' },
        { label: 'Florida', value: 'FL' },
        { label: 'Georgia', value: 'GA' },
        { label: 'Guam', value: 'GU' },
        { label: 'Hawaii', value: 'HI' },
        { label: 'Idaho', value: 'ID' },
        { label: 'Illinois', value: 'IL' },
        { label: 'Indiana', value: 'IN' },
        { label: 'Iowa', value: 'IA' },
        { label: 'Kansas', value: 'KS' },
        { label: 'Kentucky', value: 'KY' },
        { label: 'Louisiana', value: 'LA' },
        { label: 'Maine', value: 'ME' },
        { label: 'Maryland', value: 'MD' },
        { label: 'Massachusetts', value: 'MA' },
        { label: 'Michigan', value: 'MI' },
        { label: 'Minnesota', value: 'MN' },
        { label: 'Mississippi', value: 'MS' },
        { label: 'Missouri', value: 'MO' },
        { label: 'Montana', value: 'MT' },
        { label: 'Nebraska', value: 'NE' },
        { label: 'Nevada', value: 'NV' },
        { label: 'New Hampshire', value: 'NH' },
        { label: 'New Jersey', value: 'NJ' },
        { label: 'New Mexico', value: 'NM' },
        { label: 'New York', value: 'NY' },
        { label: 'North Carolina', value: 'NC' },
        { label: 'North Dakota', value: 'ND' },
        { label: 'Ohio', value: 'OH' },
        { label: 'Oklahoma', value: 'OK' },
        { label: 'Oregon', value: 'OR' },
        { label: 'Pennsylvania', value: 'PA' },
        { label: 'Puerto Rico', value: 'PR' },
        { label: 'Rhode Island', value: 'RI' },
        { label: 'South Carolina', value: 'SC' },
        { label: 'South Dakota', value: 'SD' },
        { label: 'Tennessee', value: 'TN' },
        { label: 'Texas', value: 'TX' },
        { label: 'Utah', value: 'UT' },
        { label: 'Vermont', value: 'VT' },
        { label: 'Virgin Islands', value: 'VI' },
        { label: 'Virginia', value: 'VA' },
        { label: 'Washington', value: 'WA' },
        { label: 'West Virginia', value: 'WV' },
        { label: 'Wisconsin', value: 'WI' },
        { label: 'Wyoming', value: 'WY' },
    ];

    const handleStateChange = event => {
        console.log(event.target.value);
        setAccountInputs({
            ...accountInputs,
            state: event.target.value
        });
        console.log('accountInputs:', accountInputs);
    }

    const handleCheckboxChange = event => {
        setAccountInputs({
            ...accountInputs,
            [event.target.name]: event.target.checked
        });
    };

    const handleUpdateClick = () => {
        updateAccountInfo(accountInputs);
    };

    const handleSignOutClick = () => {
        signOut();
    };

    const handleTranslate = (event) => {
        // const currentCommunities = communities;
        console.log(event.target.value);
        i18n.changeLanguage(event.target.value);
        translateCommunities(event.target.value);
    };

    const [showPublicThread, setShowPublicThread] = useState(false);
    const registerPublicThread = () => {
        setShowPublicThread(true);
    }

    return (
        <>
            { !signedIn && 
                <Redirect to='/' />
            }
            <PublicThread open={showPublicThread} setOpen={setShowPublicThread} />
            <div className={classes.container}>
                { !communities.selected.use_alternate_home_tab &&
                    <div className={classes.publicThreadContainer}>
                        <img className={classes.publicThreadLogo} src={PublicThreadLogo}/>
                        { publicThreadStatus === 'success' ?
                            <Typography className={classes.publicThreadSuccess}>
                                Registration complete!
                            </Typography>
                        :
                            <Button 
                                className={classes.publicThreadButton} 
                                variant="contained" 
                                disableElevation
                                onClick={registerPublicThread}
                            >
                                {t('users.registerPublicThread')}
                            </Button>
                        }
                    </div> 
                }


                <Typography className={classes.inputLabel} >
                    {t('users.nameLabel')}
                </Typography>

                <form className={classes.input}>
                    <input className={classes.inputText} 
                        type="text"
                        label="name"
                        value={accountInputs.name}
                        name="name"
                        onChange={handleTextChange}
                        variant='outlined'
                    />
                </form>

                <Typography className={classes.inputLabel}>
                    {t('users.phoneLabel')}
                </Typography>

                <form className={classes.input}>
                    <input className={classes.inputText} type="text"
                        label="phoneNumber"
                        value={accountInputs.phoneNumber}
                        name="phoneNumber"
                        onChange={handleTextChange}
                    />
                </form>

                { communities.selected.use_alternate_home_tab &&
                <>
                    <Typography className={classes.inputLabel}>
                        {t('users.addressLabel')}
                    </Typography>
                    <form className={classes.input}>
                        <input className={classes.inputText} type="text"
                            label="address"
                            value={accountInputs.address}
                            name="address"
                            onChange={handleTextChange}
                        />
                    </form>

                    <Typography className={classes.inputLabel}>
                        {t('users.cityLabel')}
                    </Typography>
                    <form className={classes.input}>
                        <input className={classes.inputText} type="text"
                            label="city"
                            value={accountInputs.city}
                            name="city"
                            onChange={handleTextChange}
                        />
                    </form>

                    <Typography className={classes.inputLabel}>
                        {t('users.stateLabel')}
                    </Typography>
                    <div className={classes.content}>
                        <StyledSelect
                            className={classes.languageSelect}
                            labelId="state-select"
                            id="state-select"
                            value={accountInputs.state}
                            onChange={handleStateChange}
                            variant='outlined'
                        >
                            {states.map(item => {
                                return <MenuItem value={item.value}>{item.label}</MenuItem>
                            })}
                        </StyledSelect>
                    </div>

                    <Typography className={classes.inputLabel}>
                        {t('users.zipLabel')}
                    </Typography>
                    <form className={classes.input}>
                        <input className={classes.inputText} type="text"
                            label="zip"
                            value={accountInputs.zip}
                            name="zip"
                            onChange={handleTextChange}
                        />
                    </form>
                </>
                }

                <Typography className={classes.inputLabel} >{t('users.selectLanguage')}</Typography>
                <div className={classes.content}>
                    <StyledSelect
                        className={classes.languageSelect}
                        labelId="language-select"
                        id="language-select"
                        value={currentLanguage}
                        onChange={handleTranslate}
                        variant='outlined'
                    >
                        <MenuItem value={'en'}>{t('settings.english')}</MenuItem>
                        <MenuItem value={'es'}>{t('settings.spanish')}</MenuItem>
                        <MenuItem value={'ja'}>{t('settings.japanese')}</MenuItem>
                        <MenuItem value={'zh'}>{t('settings.chinese')}</MenuItem>
                        <MenuItem value={'ko'}>{t('settings.korean')}</MenuItem>
                        <MenuItem value={'vi'}>{t('settings.vietnamese')}</MenuItem>
                        <MenuItem value={'tl'}>{t('settings.tagalog')}</MenuItem>
                        <MenuItem value={'ar'}>{t('settings.arabic')}</MenuItem>
                        <MenuItem value={'fr'}>{t('settings.french')}</MenuItem>
                        <MenuItem value={'ro'}>{t('settings.romanian')}</MenuItem>
                    </StyledSelect>

                    { translating && 
                        <Dialog aria-labelledby="simple-dialog-title" open={true}>
                            <DialogTitle id="simple-dialog-title">{t('settings.loading')}</DialogTitle>
                        </Dialog>
                    }
                </div>

                <div className={classes.checkboxes}>
                    <FormControlLabel
                        className={classes.checkbox}
                        control={
                        <GreenCheckbox
                            checked={accountInputs.enableEmails}
                            onChange={handleCheckboxChange}
                            name="enableEmails"
                            color="primary"
                        />
                        }
                        label={<Typography variant="body2">{communities.selected.use_alternate_home_tab ? t('users.LrsReceiveEmails') : t('users.receiveEmail')}</Typography>}
                    />
                    <FormControlLabel
                        className={classes.checkbox}
                        control={
                        <GreenCheckbox
                            checked={accountInputs.enableTexts}
                            onChange={handleCheckboxChange}
                            name="enableTexts"
                            color="primary"
                        />
                        }
                        label={<Typography variant="body2">{communities.selected.use_alternate_home_tab ? t('users.LrsReceiveTexts') : t('users.receiveTexts')}</Typography>}
                    />
                </div>

                <Button 
                    className={classes.updateButton} 
                    variant="contained" 
                    disableElevation
                    onClick={handleUpdateClick}
                >
                    {t('users.updateProfile')}
                </Button>

                <Button 
                    className={classes.signOutbutton} 
                    variant="contained" 
                    disableElevation
                    onClick={handleSignOutClick}
                >
                    {t('users.signOut')}
                </Button>

                <div className={classes.feedback}>
                    {feedback}
                </div>
            </div>
        </>
    )
}

export default Component;