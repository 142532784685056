import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    container: {
        height: '100%',
        paddingRight: 10,
        paddingLeft: 10,
        display: 'flex',
        flexDirection: 'column',
        fontSize: 16,
        fontFamily: 'helvetica-neue-lt-std-65-medium',
        marginBottom: '10vh',
    },
    publicThreadContainer: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: 10,
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    publicThreadLogo: {
        height: 100,
        width: 100,
    },
    publicThreadButton: {
        fontFamily: 'helvetica-neue-lt-std-65-medium',
        fontSize: 16,
        textTransform: 'none',
        display: 'flex',
        width: '70%',
        height: 50,
        padding: '3% 0',
        borderRadius: 50,
        color: (props) => props.textColor,
        backgroundColor: (props) => props.backgroundColor,
    },
    publicThreadSuccess: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        marginTop: 10,
        marginLeft: 10,
    },
    stretch: {
        height: '300px'
    },
    inputLabel: {
        display: 'flex',
        justifyContent: 'left',
        marginTop: 10,
        marginLeft: 10,
    },
    input: {
        width: '100%',
        justifyContent: 'space-between',
        padding: '3% 0',
        backgroundColor: 'rgb(244, 251, 247)',
        zIndex: 5,
        borderRadius: 50,
        borderStyle: 'solid',
        borderColor: 'black',
        borderWidth: '1px',
    },
    inputText: {
        flex: 4,
        //fontFamily: 'helvetica-neue-lt-std-65-medium',
        fontSize: 14,
        backgroundColor: 'Transparent',
        border: '0',
        '&:focus': {
            outline: "none",
        },
        padding: '0 .3em 0 .7em',
        WebkitAppearance: 'none',
        borderRadius: '.3em 0 0 .3em',
        margin: 0,
        minWidth: 0,
        zIndex: 4,
        width: '100%',
    },
    checkboxes: {
        margin: '2% 0 2% 0',
    },
    checkbox: {
        margin: '2% 0 2% 0',
    },
    updateButton: {
        fontFamily: 'helvetica-neue-lt-std-65-medium',
        fontSize: 16,
        textTransform: 'none',
        display: 'flex',
        width: '100%',
        padding: '3% 0',
        borderRadius: 50,
        backgroundColor: (props) => props.backgroundColor,
        color: (props) => props.textColor,
        marginBottom: 10,
    },
    signOutbutton: {
        fontFamily: 'helvetica-neue-lt-std-65-medium',
        fontSize: 16,
        textTransform: 'none',
        display: 'flex',
        width: '100%',
        padding: '3% 0',
        borderRadius: 50,
        color: (props) => props.textColor,
        // margin: '0 0 5% 0',
        backgroundColor: (props) => props.backgroundColor,
    },
    title: {
        display: 'inline-block',
        // color: '#f5fbf9',
        fontFamily: 'helvetica-neue-lt-std-65-medium',
        padding: '0 6% .3% 0',
        margin: '3% 0',
        fontSize: 20,
    },
    languageSelect: {
        display: 'flex',
        border: 'none',
        cursor: 'pointer',
        overflow: 'hidden',
        outline: 'none',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        paddingLeft: 10,
        borderRadius: 50,
        borderStyle: 'solid',
        borderColor: 'black',
        borderWidth: '1px',
        height: 45,
        fontSize: 16,
    },
    field: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        padding: '3% 0',
        borderRadius: '.3em',
        backgroundColor: 'rgb(244, 251, 247)',
        margin: '0 0 5% 0',
        zIndex: 5,
    },
    feedback: {
        textAlign: 'center',
    }
}));

export default useStyles;