import React, { useEffect, useState } from 'react';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Carousel from 'react-material-ui-carousel';

import { Typography, Tab, Tabs, AppBar, Box, Button, Grid, Dialog } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { isMobile, isInStandaloneMode, isAndroid } from '../../utils/helperFunctions';

import betterbinAppIcon from '../../images/betterbinLogo.png';

import useStyles from './styles';

import SponsorItem from '../../components/SponsorItem';
import TipsPage from './TipsPage';
import NewsPage from './NewsPage';
import Faqs from './Faqs/Faqs';
import AlternateHomeTab from './AlternateHomeTab';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
            style={{display: 'flex', overflowY: 'scroll'}}
        >
            {value === index && (
                <Box
                    flexDirection='column'
                    display='flex'
                    style={{ flex: 1, overflow: "hidden", overflowY: "scroll", borderRadius: 15}}
                >
                    {children}
                </Box>
            )}
        </div>
    );
}

const StyledTabs = withStyles({
    root: {
        borderBottom: '1px solid #e8e8e8',
        elevation: 0,
    },
    indicator: {
        backgroundColor: '#32e29f',
    },
})(Tabs);
  
const StyledTab = withStyles((theme) => ({
    root: {
        elevation: 0,
        textTransform: 'none',
        '&:hover': {
            color: '#32e29f',
            opacity: 1,
        },
        '&$selected': {
            color: '#32e29f',
            fontWeight: theme.typography.fontWeightMedium,
        },
        '&:focus': {
            color: '#32e29f',
        },
    },
    selected: {},
}))((props) => <Tab disableRipple {...props} />);

const Component = ({ selectedCommunities, fetchPublicThread, showCommunityChangeTip, setShowCommunityChangeTip, fetchChat, fetchRewardsData, fetchSponsors, fetchFaqs, installPromptState, clearPhotoPromptProduct, incrementInstallPrompt, signedIn, 
                     fetchCommunityIcon, fetchAlternateHeaderLogo, selectedLanguage, setCommunity, clearSelectedProduct, selectingCommunity, community, userSignedIn, communityColor, communityTextColor }) => {
    
    let { communityId } = useParams();
    let location = useLocation();
    let history = useHistory();
    const {t, i18n} = useTranslation('common');
    const classes = useStyles({
        backgroundColor: communityColor ? communityColor : '#32E29F',
        textColor: communityTextColor ? communityTextColor : '#000000de',
    });
    const [open, setOpen] = useState(false);
    const [tabValue, setTabValue] = useState(0);

    useEffect(() => {
        if (communityId && communityId.includes("rec")) { 
            setCommunity(communityId, selectedLanguage);
            history.push('/home');
        }

        clearSelectedProduct();
        clearPhotoPromptProduct();
        userSignedIn();

        if (!signedIn) {
            history.push('/login');
        }

        if (!isInStandaloneMode() && isMobile() && !selectingCommunity) {
            setOpen(true);
        }

        // add 1 to the install prompt state counter (show every ten home page visits)
        incrementInstallPrompt(installPromptState);
    }, []);

    useEffect(() => {
        if (!isInStandaloneMode() && isMobile() && !selectingCommunity) {
            setOpen(true);
        }
    }, [selectingCommunity])

    // verify that the user hasn't been signed out (can happen when resetting password)
    useEffect(() => {
        userSignedIn();
        if (!signedIn) {
            history.push('/login');
        }
    }, [signedIn, userSignedIn, history]);

    useEffect(() => {
        i18n.changeLanguage(selectedLanguage);
    }, [location.pathname, i18n, selectedLanguage]);

    // Prevent install prompt from being shown again
    const handleClose = () => {
        setOpen(false);
    };

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    }

    useEffect(() => {
        fetchFaqs();
        fetchSponsors();
        fetchRewardsData();
        fetchChat();
        fetchPublicThread();
        fetchAlternateHeaderLogo();
        fetchCommunityIcon();
    }, [fetchFaqs, fetchSponsors, fetchRewardsData, userSignedIn, fetchChat, fetchPublicThread]);

    const closeCommunityChangeTip = () => {
        setShowCommunityChangeTip(false);
    }

    const generateNativeLink = () => {
        console.log('generating native link');
        let baseUrl = 'https://openbetterbin.page.link/'

        let communityIds = '?communityIds%3D';
        // let communityIds = '?communityIds=';
        selectedCommunities.forEach(community => {
            console.log('community id:', community.id);
            if (selectedCommunities.length === 1) {
                // console.log('inside one case:', )
                communityIds += community.id + ','// + community.id + ',';
                // console.log('communityIds:', communityIds);
            } else {
                communityIds += community.id + ',';
            }
        });

        let urlIds = communityIds;
        // if (selectedCommunities.length !== 1) {
            const lastCommaIndex = urlIds.lastIndexOf(',');
            urlIds = urlIds.substring(0, lastCommaIndex) + communityIds.substring(lastCommaIndex + 1);
        // }

        const deepLink = '?link=https://about.betterbin.app/' + urlIds;
        const nativeParams= '&apn=com.betterbin.nativeclient&isi=1582893830&ibi=com.betterbin.nativeclient';
        
        const dynamicLink = baseUrl + deepLink + nativeParams;

        console.log(dynamicLink);
        setTimeout(() => {
            window.open(dynamicLink, '_blank');//.focus();
        });
    }

    return (
        <>
            <div className={classes.container}>
                { showCommunityChangeTip &&
                    <div className={classes.communityChangeTip}>
                        <Typography className={classes.communityChangeText}>
                            {t('home.communityChangeTip')}
                        </Typography>
                        <Button
                            onClick={closeCommunityChangeTip}
                            className={classes.communityChangeButton}
                        >
                            {t('home.gotIt')}
                        </Button>
                    </div>
                }

                { community.sponsors.length !== 0 &&
                    <div style={{display: 'flex'}}>
                        <Carousel animation='slide' indicators={community.sponsors.length > 1}>
                            { community.sponsors.map((item, i) => (
                                <div key={i} className={classes.sponsor}>
                                    <SponsorItem sponsor={item} page="home" />
                                </div>
                            ))}
                        </Carousel>
                    </div>
                }

                <AppBar className={classes.tabs} position='static'>
                { !community.use_alternate_home_tab 
                  ?
                    <StyledTabs 
                        value={tabValue}
                        onChange={handleTabChange}
                        variant='fullWidth'
                    >
                        <StyledTab label={t('home.news')}/>
                        <StyledTab label={t('home.tips')}/>
                        <StyledTab label={t('home.faqs')}/>
                    </StyledTabs>
                  :
                    <StyledTabs 
                        value={tabValue}
                        onChange={handleTabChange}
                        variant='fullWidth'
                    >
                        <StyledTab label={t('home.learnMore')}/>
                        <StyledTab label={t('home.schedule')}/>
                        <StyledTab label={t('home.contactUs')}/>
                    </StyledTabs>
                }

                    <TabPanel value={tabValue} index={0}>
                        <NewsPage />
                    </TabPanel>
                    <TabPanel value={tabValue} index={1}>
                        <TipsPage/>
                    </TabPanel>
                    <TabPanel value={tabValue} index={2}>
                        { !community.use_alternate_home_tab
                          ?
                            <Faqs />
                          :
                            <AlternateHomeTab />
                        }
                    </TabPanel>
                </AppBar>


                <Dialog
                    classes={{
                        paper: classes.modifiedDialog
                    }}
                    onClose={handleClose}
                    aria-labelledby="simple-dialog-title"
                    open={open}
                >
                    <Grid container direction='row' justify='space-evenly' className={classes.dialogGrid}>
                        <Grid xs={2} container direction='column' justify='center'>
                            <img className={classes.nativeAppIcon} src={betterbinAppIcon} alt='Betterbin'/> 
                        </Grid>
                        <Grid xs={7} container direction='column' justify='center'>
                            <Typography className={classes.nativeAppText}>
                                {t('installPrompt.getTheApp')}
                            </Typography>
                        </Grid>
                        <Grid xs={2} container direction='column' justify='center'>
                            <Button 
                                className={classes.getAppButton}
                                variant='outlined'
                                /*href='https://openbetterbin.page.link/appStores'*/
                                onClick={() => generateNativeLink()}
                            > 
                                {t('installPrompt.get')}
                            </Button>
                        </Grid>
                    </Grid>
                </Dialog>

            </div>
            {/* <div id='fixed-footer' className={classes.sponsor}><SponsorItem page="home" /></div> */}
        </>
    )
}

export default Component;