import C from './actionTypes';
import K from '../utils/keys';
import { analytics, database, auth } from '../utils/admin'; 

// WARM Model conversion factors:

// emissions reduced = (weight * LANDFILL_EMISSIONS_FACTOR) + (weight * COMPOST_EMISSIONS_FACTOR)
var LANDFILL_EMISSIONS_FACTOR = 0.1622;
var COMPOST_EMISSIONS_FACTOR = 0.2132;

// multiply emissions conversion by these values to get the conversion
var MILES_CONVERSION_FACTOR = (1/0.000403);
var HOME_ENERGY_CONVERSION_FACTOR = (1/5.906);

// save these for community based waste tracker
// var VEHICLE_CONVERSION_FACTOR = 4.71;
// var GASOLINE_CONVERSION_FACTOR = 0.008887;
// var PROPANE_CONVERSION_FACTOR = 0.024;

export const GetUserWasteData = (bucketWeight) => (dispatch) => {
    let date = new Date();
    let today  = getDateString(date);

    // shift month back by 1
    date.setMonth(date.getMonth() - 1);
    let monthAgo = getDateString(date);
    var user = auth.currentUser;
    if (user) {
        // get user's total waste value
        database.ref('/users/' + user.uid + '/wasteTracker/total').once('value').then((snapshot) => {
            let totalWeight = snapshot.val();

            // get last 30 days of user input
            database.ref('/users/' + user.uid + '/wasteTracker/entries').orderByKey().startAt(monthAgo).endAt(today).on('value', function(snapshot) {
                let userWasteData = snapshot.val();

                // calculate total emissions reduced
                let totalEmissionsReduced = ((totalWeight/2000) * LANDFILL_EMISSIONS_FACTOR) + ((totalWeight/2000) * COMPOST_EMISSIONS_FACTOR);
                let wastePayload = {
                    entries: [],
                    totalEmissionsReduced: totalEmissionsReduced,
                    milesReduced: totalEmissionsReduced * MILES_CONVERSION_FACTOR,
                    homeEnergyReduced: totalEmissionsReduced * HOME_ENERGY_CONVERSION_FACTOR,
                };

                // calculate emissions reduced for each day
                for (var key in userWasteData){
                    let emissionsConversion = ((userWasteData[key]['lbs']/2000) * 0.1622) + ((userWasteData[key]['lbs']/2000) * 0.2132)
                    wastePayload.entries.push({
                        date: convertDate(key),
                        weight: userWasteData[key]['lbs'],
                        emissions: emissionsConversion
                    });
                }

                dispatch({
                    type: C.SET_USER_WASTE_DATA,
                    payload: wastePayload
                });
            });
        }).catch(error => {
            console.log('Error retrieving waste tracker data:', error);
        });

    } else {
        console.log('No user logged in');
    }
}

const convertDate = (dateString) => {
    var dateComponents = dateString.split('-');
    var day = dateComponents[2].replace(/^0+/, '');
    var month = dateComponents[1].replace(/^0+/, '');
    var year = dateComponents[0].replace(/^0+/, '');

    return month + '/' + day + '/' + year;
}

const getDateString = (date) => {
    let year = date.getFullYear();
    let month = (date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1);
    let day = (date.getDate()) < 10 ? '0' + date.getDate() : date.getDate();

    console.log(year + '-' + month + '-' + day);
  
    return year + '-' + month + '-' + day;
}

export const AddUserWasteData = (amount, bucketWeight) => (dispatch, getState) => {
    let date = new Date();
    let today = getDateString(date);

    let newAmount = amount * bucketWeight;
    
    var user = auth.currentUser;
    if (user) {
        // get user's total waste value
        database.ref('/users/' + user.uid + '/wasteTracker/total').once('value').then((snapshot) => {
            let totalWaste = 0;
            if (snapshot.val()) {
                totalWaste = snapshot.val();
            }
            database.ref('/users/' + user.uid + '/wasteTracker/entries/' + today).once('value').then((snapshot) => {
                analytics.logEvent('add_waste', { 
                    lbs: newAmount,
                    community: getState().communities.selectedCommunities[getState().communities.activeIndex][K.COMMUNITY.DISPLAY_NAME],
                    email: user.email,
                    total_lbs: totalWaste
                });
                // either create a record for the day or add more weight
                if (snapshot.exists()) {
                    let currentWeight = snapshot.val()['lbs'];
                    database.ref('users/' + user.uid + '/wasteTracker/entries/' + today).update({ lbs: newAmount + currentWeight }).then(() => {
                        console.log('Added more weight!');
                        dispatch(GetUserWasteData(bucketWeight));
                    });
                    database.ref('users/' + user.uid + '/wasteTracker/total').set(totalWaste + newAmount).then(() => {
                        console.log('Added to the total!');
                        dispatch(GetUserWasteData(bucketWeight));
                    });
                } else {
                    database.ref('users/' + user.uid + '/wasteTracker/entries/' + today).set({ lbs: newAmount }).then(() => {
                        console.log('Created new waste entry!');
                        dispatch(GetUserWasteData(bucketWeight));
                    });
                    database.ref('users/' + user.uid + '/wasteTracker/total').set(totalWaste + newAmount).then(() => {
                        console.log('Added to the total!');
                        dispatch(GetUserWasteData(bucketWeight));
                    });
                }
            });
        });

    } else {
        console.log('No user logged in');
    }
}

export const ResetUserWasteDay = (bucketWeight) => (dispatch) => {
    let date = new Date();
    let today  = getDateString(date);

    var user = auth.currentUser;
    if (user) {
        database.ref('/users/' + user.uid + '/wasteTracker/entries/' + today).once('value').then((snapshot) => {
            let currentDayWaste = 0;
            if (snapshot.val()) {
                currentDayWaste = snapshot.val()['lbs'];
            }
            database.ref('/users/' + user.uid + '/wasteTracker/total').once('value').then((snapshot) => {
                let totalWaste = 0;
                if (snapshot.val()) {
                    totalWaste = snapshot.val();
                }
                database.ref('/users/' + user.uid + '/wasteTracker/entries/' + today).remove().then(() => {
                    console.log("Reset user waste for today!");
                    dispatch(GetUserWasteData(bucketWeight));
                });
                database.ref('users/' + user.uid + '/wasteTracker/total').set(totalWaste - currentDayWaste).then(() => {
                    console.log('Added to the total!');
                    dispatch(GetUserWasteData(bucketWeight));
                });
            });
        });

    } else {
        console.log('No user logged in');
    }
}