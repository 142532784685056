import { connect } from 'react-redux';
import Component from './component';

import { GetRewardsData } from '../../actions/rewardsActions';

const mapStateToProps = state => {
    return {
        rewardCatalog: state.rewards.catalog,
        rewardsPoints: state.rewards.points,
        monthlyPoints: state.rewards.monthlyPoints,
        rewardsHistory: state.rewards.redemptionHistory ? state.rewards.redemptionHistory : [],
        searching: state.inputs.searching,
        searchError: state.inputs.searchError,
        wasteType: state.communities.selectedCommunities[state.communities.activeIndex] ? state.communities.selectedCommunities[state.communities.activeIndex]['waste_type'] : null
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getRewardsData: () => {
            dispatch(GetRewardsData());
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Component);