import React, { useEffect } from 'react';
import ProductBrowser from '../../components/ProductBrowser';
import useStyles from './styles';

const Component = () => {
    const classes = useStyles();

    // remove the padding that allows for scrolling past the footer ad
    useEffect(() => {
        // var scroll = document.getElementById('scroll-content');
        // scroll.style.paddingBottom = 0;
        //window.scrollTo(0, 0)
    }, []);

    return (
        <div className={classes.container}>
            <ProductBrowser />
        </div>
    )
}

export default Component;