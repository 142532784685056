import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { SetSelectedReward } from '../../../actions/rewardsActions';

// material UI
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';

import useStyles from './styles';

const Component = ({ data, fit }) => {
    let props = { fit };
    const classes = useStyles(props);

    return (
        <Card elevation={0} className={classes.card}>
            <div className={classes.redemptionHeader}>
                <Typography className={classes.leftText}>{data.name}</Typography>
                <Typography className={classes.rightText}>{data.date.split('T')[0]}</Typography>
            </div>
            <div className={classes.redemptionFooter}>
                <Typography className={classes.leftText}>${data.amount.total}</Typography>
                <Typography className={classes.rightText}>{data.referenceOrderId}</Typography>
            </div>
        </Card>
    );
}

const mapStateToProps = state => {
    return {
        userPoints: state.rewards.points,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onRewardClick: rewardData => {
            dispatch(SetSelectedReward(rewardData))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Component);