import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import useStyles from './styles';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';

import betterbinLogo from '../../images/BetterBinWordLogo_WHITEandBLUE.png';

const Component = ({ language, feedback, sendPasswordResetLink, signedIn, clearFeedbackMessage }) => {
    const classes = useStyles();
    const history = useHistory();
    const {t, i18n} = useTranslation('common');

    const [accountInputs, setAccountInputs] = useState({
        email: "",
    });

    const handleChange = event => {
        setAccountInputs({
            ...accountInputs,
            [event.target.name]: event.target.value
        });
    };

    const closeCreateAccount = () => {
        history.push('/');
    };

    const handlePasswordReset = () => {
        sendPasswordResetLink(accountInputs.email, language);
    };

    useEffect(() => {
        clearFeedbackMessage();
    }, [clearFeedbackMessage]);

    useEffect(() => {
        i18n.changeLanguage(language);
    }, [language, i18n])

    return (
        <>
            <div className={classes.backDrop}>
                <div className={classes.colorOverlay}></div>
                <div className={classes.container}>
                    <div>
                        <img 
                            className={classes.betterbinLogo}
                            src={betterbinLogo} alt="betterbin"
                        />
                    </div>

                    <div>
                        <form className={classes.input}>
                            <input className={classes.inputText} type="text"
                                label="email"
                                placeholder={t('users.email')}
                                value={accountInputs.email}
                                name="email"
                                onChange={handleChange}
                            />
                        </form>

                        <Button 
                            className={classes.button} 
                            variant="contained" 
                            color="primary" 
                            disableElevation
                            onClick={handlePasswordReset}
                        >
                            {t('users.sendPasswordReset')}
                        </Button>

                        <Button 
                            className={classes.button} 
                            variant="contained" 
                            color="primary" 
                            disableElevation
                            onClick={() => {history.push('/')}}
                        >
                            {t('users.backToLogin')}
                        </Button>

                        <div className={classes.feedback}>
                            {feedback}
                        </div>
                    </div>


                </div>

                <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={closeCreateAccount}>
                </IconButton>
            </div>
        </>

    )
}

export default Component;