import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, Redirect } from 'react-router-dom';

// material UI
import { useTheme } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CircularProgress from '@material-ui/core/CircularProgress';

import Product from './Product';
import useStyles from './styles';

const Component = ({ productList, communityName, email, searching, wasteType, searchError, onScanSuccess, selectedProduct }) => {
    const history = useHistory()
    const {t} = useTranslation('common');
    const theme = useTheme();
    const xs = useMediaQuery(theme.breakpoints.only('xs'));
    const sm = useMediaQuery(theme.breakpoints.only('sm'));
    const md = useMediaQuery(theme.breakpoints.only('md'));
    const lg = useMediaQuery(theme.breakpoints.only('lg'));
    const xl = useMediaQuery(theme.breakpoints.only('xl'));

    const classes = useStyles();

    const getGridListCols = () => {
        if (xs) {
            return 1;
        }
        if (sm) {
            return 2;
        }
        if (md) {
            return 2;
        }
        if (lg) {
            return 1;
        }
        if (xl) {
            return 2;
        }
        return 1;
    }

    const getPlaceHolder = () => {
        if (searching) return <CircularProgress />
        else if (productList.length === 0 && !searchError && !searching) {
            history.push('/photo')
        } else if (productList.length === 1 && history.location.pathname !== '/instructions') {
            console.log('scan success!');
            onScanSuccess(productList[0]);
            return <Redirect to={'/instructions'}/>
        } else if (searchError) {
            return <Typography>{t('errors.searchError')}</Typography>
        }
        else return
    }

    return (
        <div className={classes.container}>
            <div className={classes.gridContainer}>
                <GridList cellHeight={110} cols={getGridListCols()}>
                    { productList.map((product, i) => (
                        <GridListTile key={i} rows={1} cols={1}>
                            <Product data={product} fit={wasteType === 'recycle' ? 'contain' : 'cover'} />
                        </GridListTile>
                    ))}
                </GridList>
                {getPlaceHolder()}
            </div>
        </div>
    );
}

export default Component;