import { connect } from 'react-redux';
import { SetUserCommunity, LaunchCommunityBrowser } from '../../actions/communityActions';
import { ClearSelectedProduct, ClearPhotoPromptProduct } from '../../actions/productActions';
import { IncrementInstallPrompt, IsUserSignedIn } from '../../actions/userActions';
import Component from './component';
import adapter from 'webrtc-adapter';


const mapStateToProps = state => {
    return {
        community: state.communities.selectedCommunities[state.communities.activeIndex],
        selectingCommunity: state.communities.launchCommunityBrowser,
        selectedLanguage: state.inputs.language,
        signedIn: state.user.signedIn,
        installPromptState: state.user ? state.user.installPromptState : 0,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setCommunity: (id, language) => {
            dispatch(SetUserCommunity(id, language));
            dispatch(LaunchCommunityBrowser());
        },
        clearSelectedProduct: () => {
            dispatch(ClearSelectedProduct());
        },
        clearPhotoPromptProduct: () => {
            dispatch(ClearPhotoPromptProduct());
        },
        selectCommunity: () => {
            dispatch(LaunchCommunityBrowser());
        },
        incrementInstallPrompt: (installPromptCount) => {
            dispatch(IncrementInstallPrompt(installPromptCount));
        },
        userSignedIn: () => {
            dispatch(IsUserSignedIn());
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Component);