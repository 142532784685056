import { connect } from 'react-redux';
import Component from './component';

const mapStateToProps = state => {
    return {
        community: state.communities.selectedCommunities[state.communities.activeIndex],
        selectedProduct: state.products.selected,
        wasteType: state.communities.selectedCommunities[state.communities.activeIndex] ? state.communities.selectedCommunities[state.communities.activeIndex]['waste_type'] : null, 
    }
}

// const mapDispatchToProps = dispatch => {
//     return {
//         // onSubmitPredictionValidation: (product) => {
//         //     dispatch(SubmitPredictedProduct(product));
//         // },
//     }
// }

export default connect(mapStateToProps)/*, mapDispatchToProps)*/(Component);