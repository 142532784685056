import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    container: {
        position: 'absolute',
        top: '10vh',
        display: 'flex',
        flexDirection: 'column',
        marginLeft: 10,
        marginRight: 10,
        backgroundColor: '#F9F9F9', // main background color
        overflowY: 'hidden',
        bottom: 66,
    },
    communityChangeTip: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#353535',
        borderRadius: 15,
        marginTop: 10,
        justifyContent: 'center',
        padding: 10,
    },
    communityChangeText: {
        fontFamily: 'helvetica-neue-lt-std-65-medium',
        fontSize: 16,
        color: 'white',
    },
    communityChangeButton: {
        fontFamily: 'helvetica-neue-lt-std-65-medium',
        fontSize: 16,
        textTransform: 'none',
        display: 'flex',
        marginTop: 10,
        padding: '3% 0',
        borderRadius: 50,
        color: (props) => props.textColor,
        backgroundColor: (props) => props.backgroundColor,
    },
    modifiedDialog: {
        position: 'absolute',
        borderRadius: '30px',
        bottom: -55,
        width: '100%',
        height: '30%',
        margin: 0,
        paddingTop: '30px',
        paddingLeft: '1%',
        paddingRight: '1%',
    },
    nativeAppText: {
        fontSize: 14,
    },
    nativeAppIcon: {
        height: '60px',
        width: '60px',
    },
    sponsor: {
        zIndex: 1,
        height: '90',
        width: '100%',
        textAlign: 'center',
        marginTop: 10,
        [theme.breakpoints.up('xs')]: {
            height: '105px',
        },
        [theme.breakpoints.up('sm')]: {
            height: '150px',
        },
        [theme.breakpoints.up('md')]: {
            height: '160px',
        },
        [theme.breakpoints.up('lg')]: {
            height: '110px',
        },
    },
    tabs: {
        marginTop: 10,
        backgroundColor: 'white',
        elevation: 0,
        color: 'rgb(53,53,61)',
        zIndex: 0,
        borderRadius: 15,
        height: '100%',
        // height: 100,
    },
}));

export default useStyles;