import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({

    rewardsAlert: {
        textAlign: 'center',
    },
    pointsBadge: {
        height: '50%',
        width: '50%',
        margin: '0 auto 0 auto',
        // opacity: '100%',
    },
    pointsAlertText: {
        marginTop: '10%',
        fontSize: '1.5rem',
        // opacity: '100%',
    },
    pointsAlertBottomText: {
        // marginTop: '10%',
        fontSize: '1.5rem',
        // opacity: '100%',
        marginBottom: '10%',
    },
}));

export default useStyles;