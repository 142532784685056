import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import BottomNavigation from 'material-ui/BottomNavigation';
import { Badge, BottomNavigationAction } from '@material-ui/core';

import SearchIcon from '@material-ui/icons/Search';
import ProfileIcon from '@material-ui/icons/Person';
import { ReactComponent as HomeIcon } from '../../icons/BottomTabs/Home.svg';
import { ReactComponent as DropOffIcon } from '../../icons/BottomTabs/DropOff.svg';
import { ReactComponent as CommunicationIcon } from '../../icons/BottomTabs/Communication.svg';


const actionStyles = makeStyles({
    root: {
        color: "green",
        "&$selected": {
          color: "red"
        }
      },
      selected: {}
});

const StyledBottomNavigationAction = withStyles({
    root: {
      color: 'orange',
    },
    selected: {
      color: 'purple',
    },
  })(BottomNavigationAction);

const StyledBadge = withStyles((theme) => ({
    badge: {
        color: 'white',
        backgroundColor: '#FA3E3E',
    },
}))(Badge);


const useStyles = makeStyles({
    container: {
        width: '100%',
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 5,
        flex: 0,
    },
});

const BottomTabs = ({ selectedWasteType, unreadChatCount }) => {
    const classes = useStyles();
    const [navigationValue, setNavigationValue] = useState(0);
    const {t} = useTranslation('common');

    const actionClasses = actionStyles();

    const pathName = window.location.pathName;

    return (
        <div className={classes.container}>
            <BottomNavigation
                value={navigationValue}
                onChange={(event, newValue) => {
                    setNavigationValue(newValue);
                }}
            >
                <BottomNavigationAction showLabel label={t('bottomTabs.home')} icon={<HomeIcon />} component={Link} to='/' />
                <BottomNavigationAction showLabel label={t('bottomTabs.dropOff')} icon={<DropOffIcon />} component={Link} to='dropOffLocations' />
                <BottomNavigationAction showLabel label={selectedWasteType === 'recycle' ? t('bottomTabs.scan') : t('bottomTabs.search')} icon={<SearchIcon />} component={Link} to='browse' />
                <BottomNavigationAction showLabel label={t('bottomTabs.communication')} icon={<StyledBadge badgeContent={unreadChatCount}><CommunicationIcon /></StyledBadge>} component={Link} to='communication' />
                <BottomNavigationAction showLabel label={t('bottomTabs.profile')} icon={<ProfileIcon />} component={Link} to='profile' />
            </BottomNavigation>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        selectedWasteType: state.communities.selectedCommunities[state.communities.activeIndex].waste_type,
        unreadChatCount: state.chat.unreadCount,
    }
}

export default connect(mapStateToProps)(BottomTabs);