import { connect } from 'react-redux';
import { SetUserCommunity, LaunchCommunityBrowser, FetchFaqs, FetchCommunitySponsors, FetchCommunityIcon, FetchAlternateHeaderLogo } from '../../actions/communityActions';
import { ClearSelectedProduct, ClearPhotoPromptProduct } from '../../actions/productActions';
import { FetchPublicThread, IncrementInstallPrompt, IsUserSignedIn } from '../../actions/userActions';
import { GetRewardsData } from '../../actions/rewardsActions';
import { FetchChat } from '../../actions/chatActions';
import { SetShowChangeCommunityTip } from '../../actions/appActions';

import Component from './component';

const mapStateToProps = state => {
    return {
        selectedCommunities: state.communities.selectedCommunities,
        community: state.communities.selectedCommunities[state.communities.activeIndex],
        showCommunityChangeTip: state.app.showChangeCommunityTip,
        selectedWasteType: state.communities.selectedCommunities[state.communities.activeIndex].waste_type,
        selectingCommunity: state.communities.launchCommunityBrowser,
        selectedLanguage: state.inputs.language,
        signedIn: state.user.signedIn,
        installPromptState: state.user ? state.user.installPromptState : 0,
        communityColor: state.communities.selectedCommunities[state.communities.activeIndex] ? state.communities.selectedCommunities[state.communities.activeIndex].community_color : "#32E29F",
        communityTextColor: state.communities.selectedCommunities[state.communities.activeIndex] ? state.communities.selectedCommunities[state.communities.activeIndex].community_text_color : "#000000de",
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setCommunity: (id, language) => {
            dispatch(SetUserCommunity(id, language));
            dispatch(LaunchCommunityBrowser());
        },
        clearSelectedProduct: () => {
            dispatch(ClearSelectedProduct());
        },
        clearPhotoPromptProduct: () => {
            dispatch(ClearPhotoPromptProduct());
        },
        selectCommunity: () => {
            dispatch(LaunchCommunityBrowser());
        },
        incrementInstallPrompt: (installPromptCount) => {
            dispatch(IncrementInstallPrompt(installPromptCount));
        },
        userSignedIn: () => {
            dispatch(IsUserSignedIn());
        },
        fetchFaqs: () => {
            dispatch(FetchFaqs());
        },
        fetchSponsors: () => {
            dispatch(FetchCommunitySponsors());
        },
        fetchRewardsData: () => {
            dispatch(GetRewardsData());
        },
        fetchChat: () => {
            dispatch(FetchChat());
        },
        setShowCommunityChangeTip: (value) => {
            dispatch(SetShowChangeCommunityTip(value));
        },
        fetchPublicThread: () => {
            dispatch(FetchPublicThread());
        },
        fetchCommunityIcon: () => {
            dispatch(FetchCommunityIcon());
        },
        fetchAlternateHeaderLogo: () => {
            dispatch(FetchAlternateHeaderLogo());
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Component);