import React, { useState, useEffect } from 'react';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';
import K from '../../utils/keys';

// material UI
import { useTheme } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CircularProgress from '@material-ui/core/CircularProgress';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import MyPointsBadge from '../../images/my-points-grey-badge.svg';
import Reward from './Reward';
import Redemption from './Redemption';

const Component = ({ rewardCatalog, searching, wasteType, searchError, rewardsPoints, rewardsHistory, monthlyPoints, getRewardsData}) => {
    const {t} = useTranslation('common');
    const theme = useTheme();
    const xs = useMediaQuery(theme.breakpoints.only('xs'));
    const sm = useMediaQuery(theme.breakpoints.only('sm'));
    const md = useMediaQuery(theme.breakpoints.only('md'));
    const lg = useMediaQuery(theme.breakpoints.only('lg'));
    const xl = useMediaQuery(theme.breakpoints.only('xl'));

    const classes = useStyles();

    const getGridListCols = () => {
        if (xs) {
            return 1;
        }
        if (sm) {
            return 2;
        }
        if (md) {
            return 2;
        }
        if (lg) {
            return 1;
        }
        if (xl) {
            return 2;
        }
        return 1;
    }

    useEffect(() => {
        getRewardsData();
    }, [getRewardsData]);

    const [pageNumber, setPageNumber] = useState(1);
    const pageSize = getGridListCols() * 10;

    const getPlaceHolder = () => {
        if (searching && !rewardCatalog.length) return <CircularProgress />
        else if (searchError) {
            console.log(searchError);
            return <Typography>{t('errors.searchError')}</Typography>
        }
        else return
    }

    const paginate = (array, page_size, page_number) => {
        // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
        return array.slice((page_number - 1) * page_size, page_number * page_size);
    }

    const checkPageAvailability = (array, pageSize, pageNumber) => {
        // negative?
        if (pageNumber <= 0) return false;
        // too big?
        if ((pageNumber - 1) * pageSize >= array.length) return false;
        return true;
    }

    const handleNewPage = (newPageNumber) => {
        if (!checkPageAvailability(rewardCatalog, pageSize, newPageNumber)) return;
        setPageNumber(newPageNumber);
    }

    const [displayType, setDisplayType] = useState('catalog');

    const changeDisplayType = () => {
        if (displayType === 'catalog') {
            setDisplayType('history');
        } else {
            setDisplayType('catalog');
        }
    }

    return (
        <div className={classes.container}>
            { monthlyPoints >= K.REWARDS.MONTHLY_LIMIT &&
                <div className={classes.rewardsMessageBox}>
                    <Typography className={classes.rewardsMessageText}>{t('rewards.overMonthlyLimit')}</Typography>
                </div>
            }
            <div className={classes.pointsBox}>
                <img src={MyPointsBadge} className={classes.pointsBadge}/>
                    <Typography className={classes.pointsText}>{rewardsPoints}</Typography>
                <div 
                    className={classes.redemptionHistory} 
                    onClick={changeDisplayType}
                >
                    {displayType === 'catalog' ?
                        <>{t('rewards.rewardsHistory')}</>
                    :
                        <>{t('rewards.rewardsCatalog')}</>
                    }
                </div>
            </div>
            { displayType === 'catalog' &&
                <>
                    <div className={classes.gridContainer}>
                        <GridList cellHeight={110} cols={getGridListCols()}>
                            {paginate(rewardCatalog, pageSize, pageNumber).map((reward, i) => (
                                <GridListTile key={i} rows={1} cols={1}>
                                    <Reward data={reward} fit={wasteType === 'recycle' ? 'contain' : 'cover'} />
                                </GridListTile>
                            ))}
                        </GridList>
                        {getPlaceHolder()}
                    </div>
                    { rewardCatalog.length > 0 && 
                        <div className={`${classes.pageButtonsContainer}`}>
                            {<ArrowForwardIcon className={classes.backButton} onClick={() => handleNewPage(pageNumber - 1)} />}
                            <div className={classes.pageNumbers}>{`${pageNumber} of ${Math.ceil(rewardCatalog.length / pageSize)}`}</div>
                            {<ArrowForwardIcon className={classes.nextButton} onClick={() => handleNewPage(pageNumber + 1)} />}
                        </div>
                    }
                </>
            }

            { displayType === 'history' &&
                <>
                    <div className={classes.historyGridContainer}>
                        <GridList cellHeight={110} cols={getGridListCols()} spacing={10}>
                            {paginate(rewardsHistory, pageSize, pageNumber).map((redemption, i) => (
                                <GridListTile key={i} rows={1} cols={1}>
                                    <Redemption data={redemption} fit={wasteType === 'recycle' ? 'contain' : 'cover'} />
                                </GridListTile>
                            ))}
                        </GridList>
                        {getPlaceHolder()}
                    </div>
                    {rewardsHistory.length > 0 && 
                        <div className={`${classes.pageButtonsContainer}`}>
                            {<ArrowForwardIcon className={classes.backButton} onClick={() => handleNewPage(pageNumber - 1)} />}
                            <div className={classes.pageNumbers}>{`${pageNumber} of ${Math.ceil(rewardsHistory.length / pageSize)}`}</div>
                            {<ArrowForwardIcon className={classes.nextButton} onClick={() => handleNewPage(pageNumber + 1)} />}
                        </div>
                    }
                </>
            }

        </div>
    );
}

export default Component;