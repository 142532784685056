import C from './actionTypes';
import K from '../utils/keys';
import { nativeApi, api, analytics, firebase, auth } from '../utils/admin';

export const ResetProducts = () => ({
    type: C.SET_PRODUCTS,
    payload: [],
})

export const ResetMissingProducts = () => ({
    type: C.SET_MISSING_PRODUCTS,
    payload: [],
    status: "waiting",
})

export const ClearSelectedProduct = () => ({
    type: C.SET_SELECTED_PRODUCT,
    payload: null
})

export const ClearPhotoPromptProduct = () => ({
    type: C.SET_PHOTO_PROMPT_DATA,
    payload: {
        name: "",
        upc: ""
    }
})

export const SetSelectedProduct = (product) => (dispatch, getState) => {
    let currentState = getState();
    let community = currentState.communities.selectedCommunities[currentState.communities.activeIndex];
    let selectedWasteType = community.waste_type;

    var user = auth.currentUser;
    let userEmail = '';
    let userId = '';

    if (user !== null) {
        userEmail = user.email;
        userId = user.uid;
    }

    analytics.logEvent('selected_product', { 
        product_name: product[K.PRODUCT.DISPLAY_NAME], 
        community: community[K.COMMUNITY.DISPLAY_NAME],
        waste_type: community[K.COMMUNITY.WASTE_TYPE],
        id: userId,
        email: userEmail,
        gtin: product.gtin ? product.gtin.toString() : "",
    });

    const productSource = product.source ? product.source : '';
    // only fetch instructions if they aren't present already (model generated will already be in the list of products)
    if (productSource === 'prediction_model') {
        dispatch({
            type: C.SET_SELECTED_PRODUCT,
            payload: product
        });
    } else {
        const url = `/instructions?community=${community.id}&productId=${product[K.PRODUCT.UPC]}&language=${currentState.inputs.language}&wasteType=${selectedWasteType}`;
        nativeApi.get(url).then(response => {
            product[K.PRODUCT.INSTRUCTIONS] = response.data.instructions;
            product[K.PRODUCT.CLASSIFICATION] = response.data.classification;
            dispatch({
                type: C.SET_SELECTED_PRODUCT,
                payload: {
                    gtin: product.gtin,
                    product_name: product.product_name,
                    image_url: product.image_url,
                    instructions: product.instructions,
                    classification: product.classification,
                    promotional_url: product.promotional_url ? product.promotional_url : '',
                }
            });
        }).catch(error => {
            console.log('Error retrieving product instructions:', error);
        });
    }
}

export const SetCategories = (language) => (dispatch) => {
    nativeApi.get(`/compost-categories?language=${language}`).then(response => {
        dispatch({
            type: C.SET_CATEGROIES,
            payload: response.data
        })
    }).catch(error => {
        console.log(error);
    })
}

export const SetCategoryItems = (category, language) => (dispatch, getState) => {
    const currentState = getState();
    const communityName = currentState.communities.selectedCommunities[currentState.communities.activeIndex].display_name;

    dispatch(ResetProducts());
    dispatch({
        type: C.SUBMIT_SEARCH,
        payload: true
    });
    dispatch({
        type: C.SET_SEARCH_ERROR,
        payload: false
    });

    nativeApi({
        method: 'post',
        url: '/compost-category-search',
        data: {
            category: category,
            community: communityName,
            language: language,
        }
    }).then(response => {
        let items = response.data.sort((a, b) => {
            let aText = a[K.PRODUCT.DISPLAY_NAME].toLowerCase();
            let bText = b[K.PRODUCT.DISPLAY_NAME].toLowerCase();
            return aText < bText ? -1 : (aText > bText ? 1 : 0);
        });
        dispatch({
            type: C.SET_CATEGORY_ITEMS,
            payload: items
        })
        dispatch({
            type: C.SUBMIT_SEARCH,
            payload: false
        })
    }).catch(error => {
        dispatch({
            type: C.SET_SEARCH_ERROR,
            payload: true
        });
        dispatch({
            type: C.SUBMIT_SEARCH,
            payload: false
        });
    });
}

const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
});

function resizeImage(base64Str, maxWidth, maxHeight) {
    return new Promise((resolve, reject) => {
        let img = new Image()
        img.src = base64Str
        img.onload = () => {
          let canvas = document.createElement('canvas')
          const MAX_WIDTH = maxWidth
          const MAX_HEIGHT = maxHeight
          let width = img.width
          let height = img.height
    
          if (width > height) {
            if (width > MAX_WIDTH) {
              height *= MAX_WIDTH / width
              width = MAX_WIDTH
            }
          } else {
            if (height > MAX_HEIGHT) {
              width *= MAX_HEIGHT / height
              height = MAX_HEIGHT
            }
          }
          canvas.width = width
          canvas.height = height
          let ctx = canvas.getContext('2d')
          ctx.drawImage(img, 0, 0, width, height)
          resolve(canvas.toDataURL('image/jpeg', 0.85))
        }
    });
}

export const SubmitPhotoPrompt = (product, photo, searchValue, wasteType) => (dispatch, getState) => {
    var user = firebase.auth().currentUser;
    let userEmail = '';
    let userId = '';
    if (user != null) {
        userEmail = user.email;
        userId = user.uid;
    }

    analytics.logEvent('user_submit_missing_product', { 
        product_name: product.name,
        gtin: product.upc ? product.upc.padStart(14, 0) : "", 
        community: getState().communities.selectedCommunities[getState().communities.activeIndex][K.COMMUNITY.DISPLAY_NAME],
        email: userEmail,
        id: userId,
        search_value: searchValue,
        waste_type: wasteType,
    });

    if (photo) {
        toBase64(photo).then(base64 => {
            resizeImage(base64, 1000, 800).then(resizedPhoto => { 
                console.log(resizedPhoto);      
                nativeApi({
                    method: 'post',
                    url: 'submit-photo-prompt',
                    data: {
                        productName: product.name,
                        gtin: product.upc,
                        photo: resizedPhoto.split(',')[1],
                        email: userEmail,
                        community: getState().communities.selectedCommunities[getState().communities.activeIndex].display_name,
                        searchValue: searchValue,
                        wasteType: wasteType,
                    },
                }).then(response => {
                    return response;
                }).catch(error => {
                    console.log(error);
                });
            })
        });
    }
}

export const SubmitProductQuestion = (product, category, photo, message, wasteType, searchValue) => (dispatch, getState) => {
    let currentState = getState()
    let community = currentState.communities.selectedCommunities[currentState.communities.activeIndex];
    
    var user = firebase.auth().currentUser;
    let userEmail = '';
    let userId = '';
    
    if (user != null) {
        userEmail = user.email;
        userId = user.uid;
    }

    if (photo) {
        toBase64(photo).then(base64 => {
            resizeImage(base64, 1000, 800).then(resizedPhoto => { 
                console.log(resizedPhoto);      
                nativeApi({
                    method: 'post',
                    url: 'submit-product-question',
                    data: {
                        productName: product.product_name,
                        gtin: product.gtin,
                        category: category,
                        message: message,
                        photo: resizedPhoto.split(',')[1],
                        email: userEmail,
                        community: community.display_name,
                        wasteType: wasteType,
                        searchValue: searchValue,
                    },
                }).then(response => {
                    return response;
                }).catch(error => {
                    console.log(error);
                });
            })
        });
    }
}