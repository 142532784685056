import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';

import useStyles from './styles';
import betterbinLogo from '../../images/BetterBinWordLogo_WHITEandBLUE.png';

const Component = ({ signedIn, createEmailPasswordAccount, clearFeedbackMessage, feedback }) => {
    const classes = useStyles();
    const {t} = useTranslation('common');

    const [accountInputs, setAccountInputs] = useState({
        email: "",
        password: "",
        name: "",
        phoneNumber: "",
        enableEmails: true,
        enableTexts: true,
    });

    const handleTextChange = event => {
        event.preventDefault();
        setAccountInputs({
            ...accountInputs,
            [event.target.name]: event.target.value
        });
    };

    const handleCheckboxChange = event => {
        event.preventDefault();
        setAccountInputs({
            ...accountInputs,
            [event.target.name]: event.target.checked
        });
    };

    const handleEmailPasswordSubmit = (event) => {
        event.preventDefault();
        console.log(accountInputs);
        createEmailPasswordAccount(accountInputs);
    };

    useEffect(() => {
        clearFeedbackMessage()
    }, [clearFeedbackMessage]);

    const checkKeyDown = (e) => {
        if (e.key === 'Enter') e.preventDefault();
    };

    return (
        <>
            { signedIn && 
                <Redirect to='/' />
            }

            <div className={classes.backDrop}>
                <div className={classes.colorOverlay}></div>
                <div className={classes.container}>
                    <div>
                        <img 
                            className={classes.betterbinLogo}
                            src={betterbinLogo}
                            alt="betterbin"
                        />
                    </div>

                    <div>
                        <form className={classes.input} onKeyDown={(e) => checkKeyDown(e)}>
                            <input className={classes.inputText} type="text"
                                label="email"
                                placeholder={t('users.email')}
                                value={accountInputs.email}
                                name="email"
                                onChange={handleTextChange}
                            />
                        </form>

                        <form className={classes.input} onKeyDown={(e) => checkKeyDown(e)}>
                            <input className={classes.inputText} type="password"
                                label="password"
                                placeholder={t('users.password')}
                                value={accountInputs.password}
                                name="password"
                                onChange={handleTextChange}
                            />
                        </form>

                        <form className={classes.input} onKeyDown={(e) => checkKeyDown(e)}>
                            <input className={classes.inputText} type="text"
                                label="name"
                                placeholder={t('users.name')}
                                value={accountInputs.name}
                                name="name"
                                onChange={handleTextChange}
                            />
                        </form>

                        <form className={classes.input} onKeyDown={(e) => checkKeyDown(e)}>
                            <input className={classes.inputText} type="text"
                                label="phoneNumber"
                                placeholder={t('users.phoneNumber')}
                                value={accountInputs.phoneNumber}
                                name="phoneNumber"
                                onChange={handleTextChange}
                            />
                        </form>

                        <div className={classes.checkboxes}>
                            <FormControlLabel
                                className={classes.checkbox}
                                variant='body1'
                                control={
                                <Checkbox
                                    checked={accountInputs.enableEmails}
                                    onChange={handleCheckboxChange}
                                    name="enableEmails"
                                    color="primary"
                                />
                                }
                                label={<Typography variant="body2">{t('users.receiveEmail')}</Typography>}
                            />
                            <FormControlLabel
                                className={classes.checkbox}
                                control={
                                <Checkbox
                                    checked={accountInputs.enableTexts}
                                    onChange={handleCheckboxChange}
                                    name="enableTexts"
                                    color="primary"
                                />
                                }
                                label={<Typography variant="body2">{t('users.receiveTexts')}</Typography>}
                            />
                        </div>

                        <Button 
                            className={classes.button} 
                            variant="contained" 
                            color="primary" 
                            disableElevation
                            onClick={handleEmailPasswordSubmit}
                        >
                            {t('users.createAccount')}
                        </Button>
                    </div>

                    <div className={classes.feedback}>
                        {feedback}
                    </div>

                    <div className={classes.termsAndConditions}>
                    {t('users.termsAndConditions1')} <a href='https://about.betterbin.app/wp-content/uploads/2019/05/ERbin-Terms-and-Conditions-of-Use-May-2019.pdf'>{t('users.termsOfService')}</a> and <a href='https://about.betterbin.app/erbin-privacy-policy/'>{t('users.privacyPolicy')}.</a>
                    </div>
                </div>
            </div>
        </>

    )
}

export default Component;