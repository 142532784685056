import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// material UI
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import AddAPhoto from '@material-ui/icons/AddAPhoto';

// styles
import useStyles from './styles';

const Component = ({ productInfo, onSubmitPhotoPrompt, searchList, searchValue, wasteType }) => {
    const classes = useStyles();
    const history = useHistory();
    const {t} = useTranslation('common');
    
    const [photo, setPhoto] = useState({
        photo: null,
        tempURL: ""
    });

    const [productRequest, setProductRequest] = useState({
        name: productInfo.name,
        upc: !isNaN(productInfo.upc) ? productInfo.upc : "" ,
    })

    // Keep request state up to date with content in the form fields
    const handleChange = event => {
        console.log(productRequest);
        if (event.target.name === 'upc') {
            setProductRequest({
                ...productRequest,
                [event.target.name]: event.target.value.replace(/\D+/g, '')
            });
        } else {
            setProductRequest({
                ...productRequest,
                [event.target.name]: event.target.value
            });
        }
    }

    const handleUserPhoto = (event) => {
        setPhoto({
            photo: event.target.files[0],
            tempURL: URL.createObjectURL(event.target.files[0])
        });
    }

    const handleSubmission = () => {
        onSubmitPhotoPrompt(productRequest, photo.photo, searchValue, wasteType);
        history.push('/browse');
    }

    return (
        <>
        <div className={classes.container}>
            <div className={classes.card}>
                <div className={classes.requestPrompt}>
                     {searchList.length === 0 ?  t('photoPrompt.conditionalOopsDescription') : '' } {wasteType === 'recycle' ? t('photoPrompt.mainRecyclingDescription') : t('photoPrompt.mainCompostingDescription')}
                </div>        

                <Typography className={classes.fieldLabel}>
                    {t('photoPrompt.productName')}{t('photoPrompt.optional')}
                </Typography>

                <form className={classes.requestField}>
                    <input className={classes.requestFieldText} type="text"
                        label="productName"
                        placeholder={t('photoPrompt.productName')}
                        value={productRequest.name}
                        name="name"
                        onChange={handleChange}
                    />
                </form>

                {wasteType === 'recycle' &&
                <>
                    <Typography className={classes.fieldLabel}>
                        {t('photoPrompt.barcode')}{t('photoPrompt.optional')}
                    </Typography>
                    <form className={classes.requestField}>
                        <input className={classes.requestFieldText} type="text"
                            label="upc"
                            placeholder={t('photoPrompt.barcode')}
                            value={productRequest.upc}
                            name="upc"
                            onChange={handleChange}
                        />
                    </form>
                </>
                }

                <div className={classes.photoPreview}>
                    <img className={classes.photo} src={photo.tempURL} alt=''/>
                </div>

                <Button
                    component='label'
                    variant="contained"
                    className={classes.photoButton}
                    startIcon={<AddAPhoto/>}
                >
                    {t('photoPrompt.takePhoto')}
                    <input 
                        onChange={handleUserPhoto}
                        type="file"
                        accept="image/*"
                        capture="environment"
                        hidden
                    />
                </Button>
                
                {photo.photo !== null &&
                    <Button 
                        variant="contained"
                        className={classes.submitButton}
                        onClick={handleSubmission}
                    >
                        {t('photoPrompt.submit')}
                    </Button>
                }
            </div>
        </div>
        </>
    );
}

export default Component;
