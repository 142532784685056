import React, { useState, useEffect } from 'react';

// Redux
import storeFactory from './store';
import { Provider } from 'react-redux';

import Routes from './components/Routes/Routes';

// Material UI
import CssBaseline from '@material-ui/core/CssBaseline';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';


const theme = createTheme({
    typography: {
        fontFamily: [
            '"helvetica-neue-lt-std-65-medium"',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
    },
    palette: {
        primary: {
            light: '#3C414F',
            main: '#313849',
            dark: '#1d1c25',
            contrastText: '#fff',
        },
        secondary: {
            light: '#d7f967',
            main: '#67FF5D',
            dark: '#8fad2d',
            contrastText: '#000',
        },
    },
    status: {
        danger: 'orange',
    },
});

const saveState = () => localStorage['redux-store'] = JSON.stringify(store.getState());
const store = storeFactory();
store.subscribe(saveState);


const App = () => {

  return (
    <Provider store={store} >
        <MuiThemeProvider>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <Routes />
            </ThemeProvider >
        </MuiThemeProvider>
    </Provider>
  );
}

export default App;
