import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import Card from '@material-ui/core/Card';
import { Typography } from '@material-ui/core';

import noPhotoImage from '../../../images/NoPhotoIcon.jpg';

const useStyles = makeStyles({
    container: {
        top: '10vh',
        width: '100%',
        position: 'absolute',
        left: 0,
        right: 0,
        zIndex: 5,
        bottom: '10vh',
        paddingLeft: 10,
        paddingRight: 10,
        marginTop: 10,
        overflowY: 'scroll',
    },
    gridContainer: {
        display: 'flex',
        justifyContent: 'center',
    },
    pageButtonsContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    conversationTopic: {
        fontSize: 18,
        // fontWeight: 'bold',
        marginBottom: 10,
    },
    messagesList: {
        display: 'flex',
    },
    messageTile: {
        display: 'flex',
    },
    messageCard: {
        width: '100%',
        display: 'flex',
        textAlign: 'left',
        justifyContent: 'flex-start',
        padding: 10,
        borderRadius: 15,
        flexDirection: 'column',
        display: 'flex',
    },
    messageText: {
        fontFamily: 'helvetica-neue-lt-std-65-medium',
        fontSize: 16,
    },
    image: {
        objectFit: 'contain',
        flexGrow: 1,
        width: '100%',
        height: 150,
        marginBottom: 10,
    },
});

const MessageCard = ({ message }) => {

    const classes = useStyles();

    return (
        <Card elevation={0} className={classes.messageCard}>
            { message.imageUrl &&
                <img
                    className={classes.image}
                    src={message.imageUrl}
                    onError={event => {
                        event.target.src = noPhotoImage;
                        event.onerror = null;
                    }}
                />
            }
            <Typography className={classes.messageText}>{message.content}</Typography>
        </Card>
    );
}


const ConversationPage = ({ selectedConversation }) => {

    const classes = useStyles();

    return (
        <div className={classes.container}>
            <Typography className={classes.conversationTopic}>{selectedConversation.topic}</Typography>
            <div className={classes.gridContainer}>
                <>
                    <GridList cellHeight={500} className={classes.messagesList} cols={1}>
                        { Object.keys(selectedConversation).map((messageId, i) => {
                            if (messageId !== 'topic') {
                                return (
                                    <GridListTile className={classes.messageTile} key={i} rows={1} cols={1}>
                                        <MessageCard message={selectedConversation[messageId]} />
                                    </GridListTile>
                                )
                            }
                        })}
                    </GridList>
                </>
            </div>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        selectedWasteType: state.communities.selectedCommunities[state.communities.activeIndex].waste_type,
        unreadCount: state.chat.unreadCount ? state.chat.unreadCount : 0,
        conversations: state.chat.conversations,
        chatLanguage: state.chat.language,
        translatingChat: state.chat.translating,
        selectedConversation: state.chat.selectedConversation,
    }
}

const mapDispatchToProps = dispatch => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ConversationPage);