import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        marginRight: 10,
        marginLeft: 10,
        backgroundColor: '#F9F9F9', // main background color
        height: '100%',
        minHeight: 200,
        marginBottom: '10vh',
    },
    setupContainer: {
        height: '100%',
        margin: '.5em 0',
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 200,
    },
    messageContainer: {
        margin: '.5em 0',
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    messageImage: {
        objectFit: 'contain',
        width: '100%',
    },
    generalDisclaimer: {
        textAlign: 'center'
    },
    emailVerificationButton: {
        width: '100%',
        margin: '5% 0 1% 0',
        color: (props) => props.textColor,
        height: '20%',
        backgroundColor: (props) => props.backgroundColor,
        // make sure button doesn't change colors after tapping
        "&:hover": {
            color: (props) => props.textColor,
            backgroundColor: (props) => props.backgroundColor,
            "@media (hover: none)": {
                backgroundColor: (props) => props.backgroundColor,
                "&:active": {
                    backgroundColor: (props) => props.backgroundColor,
                }
            }
        },
    },
    emailIcon: {
        marginRight: '2%',
    },
    doneButton: {
        color: (props) => props.textColor,
        height: '20%',
        backgroundColor: (props) => props.backgroundColor,
    },
});

export default useStyles;