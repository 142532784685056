import { connect } from 'react-redux';
import Component from './component';

import { SetSelectedProduct } from '../../actions/productActions';
// import { SetRewardsAlert } from '../../actions/rewardsActions';

const mapStateToProps = state => {
    return {
        productList: state.products.list,
        email: state.inputs.email ? state.inputs.email : '',
        communityName: state.communities.selectedCommunities[state.communities.activeIndex] ? state.communities.selectedCommunities[state.communities.activeIndex]['display_name'] : '',
        searching: state.inputs.searching,
        searchError: state.inputs.searchError,
        selectedProduct: state.products.selected,
        wasteType: state.communities.selectedCommunities[state.communities.activeIndex] ? state.communities.selectedCommunities[state.communities.activeIndex]['waste_type'] : null
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onScanSuccess: productData => {
            dispatch(SetSelectedProduct(productData));
            // dispatch(SetRewardsAlert(true));
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Component);