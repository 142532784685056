
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import RewardsBrowser from '../../components/RewardsBrowser';
import useStyles from './styles';

import EmailIcon from '@material-ui/icons/Email';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

const Component = ({ getRewardsCatalog, getRewardsData, userEmailVerified, isUserEmailVerified, communityColor, communityTextColor, sendVerificationEmail }) => {
    let history = useHistory();
    const {t} = useTranslation('common');
    const classes = useStyles({
        backgroundColor: communityColor ? communityColor : '#32E29F',
        textColor: communityTextColor ? communityTextColor : '#000000de',
    });

    useEffect(() => {
        isUserEmailVerified();
        getRewardsCatalog();
        getRewardsData();
    }, [getRewardsData, getRewardsCatalog, userEmailVerified]);

    const [showInstructions, setShowInstructions] = useState(false);
    const handleEmailVerificationClick = () => {
        sendVerificationEmail();
        setShowInstructions(true);
    }

    const closeInstructions = () => {
        setShowInstructions(false);
    }

    return (
        <div className={classes.container}>
            { userEmailVerified ? 
            <>
                <RewardsBrowser />
                <div className={classes.generalDisclaimer}>
                    {t('rewards.generalTangoDisclaimer')}
                </div>
            </>
            :
            <>
            <div className={classes.setupContainer}>
                <div>{t('rewards.verificationDescription')}</div>
                <Button 
                    disableRipple
                    disableTouchRipple
                    className={classes.emailVerificationButton}
                    onClick={handleEmailVerificationClick}
                >
                    <EmailIcon className={classes.emailIcon}/>
                    {t('rewards.sendVerificationEmail')}
                </Button>
            </div>

                <Dialog 
                    open={showInstructions} 
                    disableBackdropClick 
                    disableEscapeKeyDown
                    onClose={closeInstructions}
                >
                    <DialogContent>
                        <DialogContentText>{t('rewards.emailVerificationPopup')}</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button 
                            className={classes.doneButton}
                            onClick={closeInstructions}
                            color="primary"
                        >
                            {t('rewards.done')}
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
            }
        </div>
    )
}

export default Component;