import React from 'react';
// import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import * as serviceWorker from './CRA-service-worker';

// translations
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import common_en from './translations/en.json';
import common_es from './translations/es.json';
import common_ja from './translations/ja.json';
import common_zh from './translations/zh-CN.json';
import common_ko from './translations/ko.json';
import common_vi from './translations/vi.json';
import common_tl from './translations/tl.json';
import common_ar from './translations/ar.json';
import common_fr from './translations/fr.json';
import common_ro from './translations/ro.json';

i18next.init({
    interpolation: {escapeValue: false },
    lng: 'en',
    resources: {
        en: {
            common: common_en
        },
        es: {
            common: common_es
        },
        ja: {
            common: common_ja
        },
        zh: {
            common: common_zh
        },
        ko: {
            common: common_ko
        },
        vi: {
            common: common_vi
        },
        tl: {
            common: common_tl
        },
        ar : {
            common: common_ar
        },
        fr : {
            common: common_fr
        },
        ro : {
            common: common_ro
        }
    },
});

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
    <I18nextProvider i18n={i18next}>
        <App />
    </I18nextProvider>, 
)
serviceWorker.unregister();
