import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';

// material UI
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';

import useStyles from './styles';
import K from '../../../utils/keys';

import { SelectConversation } from '../../../actions/chatActions';


const ConversationCard = ({ conversation, conversationId, onConversationClick, communityColor, communityTextColor }) => {
    const history = useHistory();
    const classes = useStyles();

    const [previewText, setPreviewText] = useState('');
    console.log('conversation:', conversation);
    useEffect(() => {
        // get most recent message
        let timestamps = Object.keys(conversation);
        timestamps = timestamps.filter(x => x !== 'topic');
        const mostRecent = Math.max(...timestamps);
        setPreviewText(conversation[mostRecent].content);
    }, [conversation]);

    const handleConversationClick = () => {
        onConversationClick(conversation, conversationId);
        history.push('/conversation');
    }

    const conversationHasUnreadMessages = () => {
        for (const message in conversation) {
            if (conversation[message].unread && message !== 'topic') {
                return true;
            }
        }
        return false;
    }

    return (
        <Card style={{backgroundColor: conversationHasUnreadMessages() ? communityColor + '33' : 'white'}} elevation={0} className={classes.card} onClick={handleConversationClick} >
            <Typography className={classes.topicText}>{conversation.topic}</Typography>
            <Typography noWrap className={classes.previewText}>{previewText}</Typography>
        </Card>
    );
}

const mapDispatchToProps = dispatch => {
    return {
        onConversationClick: (conversation, id) => {
            dispatch(SelectConversation(conversation, id));
        }
    }
}

const mapStateToProps = state => {
    return {
        communityColor: state.communities.selectedCommunities[state.communities.activeIndex] ? state.communities.selectedCommunities[state.communities.activeIndex].community_color : "#32E29F",
        communityTextColor: state.communities.selectedCommunities[state.communities.activeIndex] ? state.communities.selectedCommunities[state.communities.activeIndex].community_text_color : "#000000de",
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ConversationCard);