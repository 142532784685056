import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core/styles';
import { Button, GridList, GridListTile } from '@material-ui/core';

import { FetchChat } from '../../actions/chatActions';

import ConversationCard from './ConversationCard/ConversationCard';
import { SetRewardsAlert } from '../../actions/rewardsActions';
import { FetchCommunityContactEmail } from '../../actions/communityActions';

const useStyles = makeStyles({
    container: {
        position: 'absolute',
        top: '10vh',
        bottom: 56,
        marginTop: 10,
        paddingLeft: 10,
        paddingRight: 10,
        flex: 1,
        overflowY: 'scroll',
        width: '100%',
    },
    gridContainer: {
        display: 'flex',
        justifyContent: 'center',
    },
    pageButtonsContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    contactUsContainer : {
        position: 'absolute',
        bottom: 56,
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#fafafa',
    },
    contactUsButton: {
        fontFamily: 'helvetica-neue-lt-std-65-medium',
        fontSize: 16,
        textTransform: 'none',
        display: 'flex',
        width: '90%',
        padding: '3% 0',
        borderRadius: 50,
        color: (props) => props.textColor,
        backgroundColor: (props) => props.backgroundColor,
        margin: 10,
    },
});

const CommunicationPage = ({ fetchCommunityContactEmail, alternateHomeTab, fetchChat, conversations, communityContactEmail, communityColor, communityTextColor }) => {
    const history = useHistory()
    const {t} = useTranslation('common');

    const classes = useStyles({
        backgroundColor: communityColor ? communityColor : '#32E29F',
        textColor: communityTextColor ? communityTextColor : '#000000de',
    });

    useEffect(() => {
        fetchChat();
        fetchCommunityContactEmail();
        console.log(conversations);
    }, [fetchChat, fetchCommunityContactEmail]);

    const onPressContactUs = () => {
        // showRewardsAlert();
        window.location.href = `mailto:${communityContactEmail}`;
    }

    return (
        <>
        <div className={classes.container}>
            <div className={classes.gridContainer}>
                { conversations !== undefined &&
                <>
                    <GridList cellHeight={110} cols={1}>
                        { Object.keys(conversations).sort().reverse().map((conversationId, i) => (
                            <GridListTile key={i} rows={1} cols={1}>
                                <ConversationCard conversation={conversations[conversationId]} conversationId={conversationId} />
                            </GridListTile>
                        ))}
                    </GridList>
                </>
                }
            </div>
        </div>
        { !alternateHomeTab &&
            <div className={classes.contactUsContainer}>
                <Button
                    className={classes.contactUsButton}
                    onClick={onPressContactUs}
                >
                    {t('communication.contactUs')}
                </Button>
            </div>
        }
        </>
    );
}

const mapStateToProps = state => {
    return {
        alternateHomeTab: state.communities.selectedCommunities[state.communities.activeIndex].use_alternate_home_tab,
        communityContactEmail: state.communities.selectedCommunities[state.communities.activeIndex].contact_email,
        selectedWasteType: state.communities.selectedCommunities[state.communities.activeIndex].waste_type,
        unreadCount: state.chat.unreadCount ? state.chat.unreadCount : 0,
        conversations: state.chat.conversations,
        chatLanguage: state.chat.language,
        translatingChat: state.chat.translating,
        selectedConversation: state.chat.selectedConversation,
        communityColor: state.communities.selectedCommunities[state.communities.activeIndex] ? state.communities.selectedCommunities[state.communities.activeIndex].community_color : "#32E29F",
        communityTextColor: state.communities.selectedCommunities[state.communities.activeIndex] ? state.communities.selectedCommunities[state.communities.activeIndex].community_text_color : "#000000de",
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchChat: () => {
            dispatch(FetchChat());
        },
        fetchCommunityContactEmail: () => {
            dispatch(FetchCommunityContactEmail());
        }
        // showRewardsAlert: () => {
        //     dispatch(SetRewardsAlert('scan'));
        // }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CommunicationPage);