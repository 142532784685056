import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    container: {
        top: '10vh',
        position: 'relative',
        marginTop: 10,
        marginBottom: '10vh',
    },
    gridContainer: {
        display: 'flex',
        justifyContent: 'center',
    },
    historyGridContainer: {
        width: '100%',
        justifyContent: 'center',
    },
    pageButtonsContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    backButton: {
        [theme.breakpoints.up('xs')]: {
            height: '25px',
            width: '25px',
        },
        [theme.breakpoints.up('sm')]: {
            height: '40px',
            width: '40px',
        },
        [theme.breakpoints.up('md')]: {
            height: '60px',
            width: '60x',
        },
        [theme.breakpoints.up('lg')]: {
            height: '25px',
            width: '25px',
        },
        fill: '#33e29f',
        transform: 'rotate(180deg)',
    },
    pageNumbers: {
        fontSize: 20,
        margin: '0 4%'
    },
    nextButton: {
        height: '25px',
        width: '25px',
        fill: '#33e29f',
    },
    pointsBox: {
        border: '7px solid rgb(50, 226, 159)',
        borderRadius: 15,
        marginBottom: '3%',
        display: 'flex',
        textAlign: 'center',
        alignItems: 'center',
        height: '100%',
    },
    pointsBadge: {
        color: '#35353d',
        margin: '0 0 0 .5em',
        objectFit: 'contain',
        height: '75px',
        width: '75px',
    },
    pointsText: {
        marginLeft: 'auto',
        marginRight: 'auto',
        fontSize: '1.8rem',
    },
    redemptionHistory: {
        backgroundColor: 'rgb(50, 226, 159)',
        borderRadius: '0px 10px 10px 0px',
        justifyContent: 'center',
        alignContent: 'center',
        height: '85px',
        width: '93px',
        display: 'flex',
        flexDirection: 'column',
    },
    rewardsMessageBox: {
        border: '7px solid rgb(50, 226, 159)',
        borderRadius: 15,
        marginBottom: 10,
        display: 'flex',
        textAlign: 'center',
        alignItems: 'center',
        height: '100%',
    },
    rewardsMessageText: {
        margin: '5%',
        fontSize: '1em',
        textAlign: 'left',
    },
}));

export default useStyles;