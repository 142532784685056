import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { SetSelectedProduct } from '../../../actions/productActions';

// material UI
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';

import Skeleton from '@material-ui/lab/Skeleton';

import useStyles from './styles';
import K from '../../../utils/keys';

const Component = ({ data, fit, onProductClick }) => {
    let props = { fit };
    const history = useHistory();
    const classes = useStyles(props);
    const [loading, setLoading] = useState(true);

    const handleProductClick = () => {
        onProductClick(data);
        history.push('/instructions');
    }

    return (
        <Card elevation={0} className={classes.card} onClick={handleProductClick} >
            {loading && <Skeleton variant="rect" className={classes.media} />}
            <img src={data[K.PRODUCT.IMAGE_URL]} alt={data[K.PRODUCT.DISPLAY_NAME]} className={classes.media} onLoad={() => setLoading(false)} />
            <Typography className={classes.text}>{data[K.PRODUCT.DISPLAY_NAME]}</Typography>
        </Card>
    );
}

const mapDispatchToProps = dispatch => {
    return {
        onProductClick: productData => {
            dispatch(SetSelectedProduct(productData))
        }
    }
}

export default connect(null, mapDispatchToProps)(Component);