import C from './actionTypes';
import K from '../utils/keys';

export const SetWasteType = (wasteType) => (dispatch, getState) => {
    dispatch({
        type: C.SET_WASTE_TYPE,
        payload: wasteType,
    });

    if (wasteType === 'recycle') {
        dispatch({
            type: C.SET_USER_COMMUNITY,
            payload: {
                selected: getState().communities.recycle,
                alternate: null,
            }
        });
    } else if (wasteType === 'compost') {
        dispatch({
            type: C.SET_USER_COMMUNITY,
            payload: {
                selected: getState().communities.compost,
                alternate: null,
            }
        });
    }
}

export const StoreCommunityId = (communityId) => (dispatch) => {
    dispatch({
        type: C.SET_STORED_COMMUNITY_IDS,
        payload: communityId,
    });
}

export const SetShowChangeCommunityTip = (value) => (dispatch) => {
    dispatch({
        type: C.SET_SHOW_CHANGE_COMMUNITY_TIP,
        payload: value
    });
}

export const SetCameraDeviceId = (id) => (dispatch) => {
    dispatch({
        type: C.SET_CAMERA_DEVICE_ID,
        payload: id,
    });
}