import { connect } from 'react-redux';
import Component from './component';
import { SetSearchValue, SubmitSearch } from '../../../actions/inputActions';
import { SubmitForPoints } from '../../../actions/rewardsActions';
import { SetCameraDeviceId } from '../../../actions/appActions';

const mapStateToProps = state => {
    return {
        language: state.inputs.language,
        cameraDeviceId: state.app.cameraDeviceId,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onScan: (results, language) => {
            dispatch(SetSearchValue(results))
            dispatch(SubmitForPoints(results, 'scan'));
            dispatch(SubmitSearch(results, language, 'scan'));
        },
        setCameraDeviceId: (id) => {
            dispatch(SetCameraDeviceId(id));
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Component)