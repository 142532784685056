import { connect } from 'react-redux';

import { ClearFeedbackMessage, FetchAccountInfo, SignOut, UpdateAccountInfo, IsUserSignedIn } from '../../../actions/userActions';
import { TranslateCommunities } from '../../../actions/communityActions';

import Component from './component';

const mapStateToProps = state => {
    return {
        communities: {
            selected: state.communities.selectedCommunities[state.communities.activeIndex],
            alternate: state.communities.alternate
        },
        currentLanguage: state.inputs.language,
        translating: state.inputs.translatingCommunities,
        accountInfo: state.user.info,
        signedIn: state.user.signedIn,
        feedback: state.user.feedback,
        publicThreadStatus: state.user.publicThread.status,
        selectedWasteType: state.communities.selectedCommunities[state.communities.activeIndex].waste_type,
        communityColor: state.communities.selectedCommunities[state.communities.activeIndex] ? state.communities.selectedCommunities[state.communities.activeIndex].community_color : "#32E29F",
        communityTextColor: state.communities.selectedCommunities[state.communities.activeIndex] ? state.communities.selectedCommunities[state.communities.activeIndex].community_text_color : "#000000de",
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchAccountInfo: () => {
            dispatch(FetchAccountInfo());
        },
        signOut: () => {
            dispatch(SignOut());
        },
        clearFeedbackMessage: () => {
            dispatch(ClearFeedbackMessage());
        },
        updateAccountInfo: (updatedAccountInfo) => {
            dispatch(UpdateAccountInfo(updatedAccountInfo));
        },
        userSignedIn: () => {
            dispatch(IsUserSignedIn());
        },
        translateCommunities: (selectedLanguage) => {
            dispatch(TranslateCommunities(selectedLanguage));
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Component);