import { connect } from 'react-redux';
import { SetCategories, SetCategoryItems } from '../../actions/productActions';
import Component from './component';


const mapStateToProps = state => {
    return ({
        categories: state.categories.list ? state.categories.list : [],
        language: state.inputs.language,
    })
}

const mapDispatchToProps = dispatch => {
    return ({
        setCategories: (language) => {
            dispatch(SetCategories(language));
        },
        setCategoryItems: (category, language) => {
            dispatch(SetCategoryItems(category, language))
        }
    })
}

export default connect(mapStateToProps, mapDispatchToProps)(Component);