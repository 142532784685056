import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    container: {
        position: 'absolute',
        top: '10vh',
        bottom: '10vh',
        overflowY: 'hidden',
        width: '100%',
        paddingLeft: 10,
        paddingRight: 10,
        display: 'flex',
        backgroundColor: '#F9F9F9', // main background color
        flexDirection: 'column',
    },
    stretch: {
        height: '300px'
    },
    tabs: {
        marginTop: 10,
        backgroundColor: 'white',
        elevation: 0,
        color: 'rgb(53,53,61)',
        zIndex: 0,
        borderRadius: 15,
        height: '100%',
    },
    content: {
        color: '#f5fbf9',
        fontFamily: 'helvetica-neue-lt-std-65-medium',
        fontSize: 16,
    },
    inputLabel: {
        display: 'flex',
        justifyContent: 'left',
        color: "#f5fbf9",
        marginTop: '3%',
    },
    input: {
        width: '100%',
        justifyContent: 'space-between',
        padding: '3% 0',
        borderRadius: '.3em',
        backgroundColor: 'rgb(244, 251, 247)',
        zIndex: 5,
    },
    inputText: {
        flex: 4,
        //fontFamily: 'helvetica-neue-lt-std-65-medium',
        [theme.breakpoints.up('xs')]: {
            fontSize: '14px',
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: '20px',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '24px',
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: '16 px',
        },
        backgroundColor: 'Transparent',
        border: '0',
        '&:focus': {
            outline: "none",
        },
        padding: '0 .3em 0 .7em',
        WebkitAppearance: 'none',
        borderRadius: '.3em 0 0 .3em',
        margin: 0,
        minWidth: 0,
        zIndex: 4,
        width: '100%',
    },
    checkboxes: {
        margin: '2% 0 2% 0',
    },
    checkbox: {
        margin: '2% 0 2% 0',
    },
    updateButton: {
        display: 'flex',
        width: '100%',
        padding: '3% 0',
        borderRadius: '.3em',
        // backgroundColor: 'rgb(50, 226, 159)',
        backgroundColor: (props) => props.backgroundColor,
        // color: '#35353d',
        color: (props) => props.textColor,
        margin: '0 0 5% 0',
    },
    signOutbutton: {
        display: 'flex',
        width: '100%',
        padding: '3% 0',
        borderRadius: '.3em',
        // color: '#35353d',
        color: (props) => props.textColor,
        margin: '0 0 5% 0',
        // backgroundColor: 'rgb(50, 226, 159)',
        backgroundColor: (props) => props.backgroundColor,
    },
    title: {
        display: 'inline-block',
        color: '#f5fbf9',
        fontFamily: 'helvetica-neue-lt-std-65-medium',
        padding: '0 6% .3% 0',
        margin: '3% 0',
        [theme.breakpoints.up('xs')]: {
            fontSize: '20px',
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: '26x',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '30px',
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: '36 px',
        },
    },
    languageSelect: {
        display: 'flex',
        backgroundColor: '#212129',
        border: 'none',
        cursor: 'pointer',
        overflow: 'hidden',
        outline: 'none',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        padding: '3% 5%',
        margin: '2% 0 0 0',
        color: '#f5fbf9',
    },
    field: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        padding: '3% 0',
        borderRadius: '.3em',
        backgroundColor: 'rgb(244, 251, 247)',
        margin: '0 0 5% 0',
        zIndex: 5,
    },
    fieldText: {
        flex: 4,
        //fontFamily: 'helvetica-neue-lt-std-65-medium',
        [theme.breakpoints.up('xs')]: {
            fontSize: '14px',
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: '20px',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '24px',
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: '16 px',
        },
        backgroundColor: 'Transparent',
        border: '0',
        '&:focus': {
            outline: "none",
        },
        padding: '0 .3em 0 .7em',
        WebkitAppearance: 'none',
        borderRadius: '.3em 0 0 .3em',
        margin: 0,
        minWidth: 0,
        zIndex: 4
    },
    feedback: {
        textAlign: 'center',
    }
}));

export default useStyles;