import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import K from '../../utils/keys';
import ProgressiveImage from 'react-progressive-image';
import useStyles from './styles';
import { useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Skeleton from '@material-ui/lab/Skeleton';

import { withStyles } from '@material-ui/core/styles';

const Component = ({ language, categories, setCategories, setCategoryItems }) => {
    const history = useHistory();
    const grid = useRef(null);
    const theme = useTheme();
    const xs = useMediaQuery(theme.breakpoints.only('xs'));
    const sm = useMediaQuery(theme.breakpoints.only('sm'));
    const md = useMediaQuery(theme.breakpoints.only('md'));
    const lg = useMediaQuery(theme.breakpoints.only('lg'));
    const xl = useMediaQuery(theme.breakpoints.only('xl'));

    const {t} = useTranslation('common');
    const classes = useStyles(getResponsiveStyles(grid, categories.length, xs, sm, md, lg, xl));

    // fontSize was overridden by MuiTypography-body for some reason, need to figure out why
    // useStyle above was overriding it properly before...
    const StyledTypography = withStyles({
        body1: {
            fontSize: getResponsiveStyles(grid, categories.length, xs, sm, md, lg, xl).fontSize,
        },
    })(Typography);

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setCategories(language);
    }, [setCategories, language]);

    const handleCategoryClick = (category) => {
        setCategoryItems(category, language);
        history.push('/search');
    }

    return (
        <>
        {categories.length > 0 &&
            <div className={classes.gridContainer} ref={grid}>
                {categories.map(category => (
                    <div
                        className={classes.gridItem}
                        onClick={() => handleCategoryClick(category[K.CATEGORIES.ID])}
                        key={category[K.CATEGORIES.ID]}
                    >
                        <div className={classes.overlay}>
                            <StyledTypography className={classes.overlayText}>{category[K.CATEGORIES.DISPLAY_NAME].toUpperCase()}</StyledTypography>
                        </div>

                        {loading && <Skeleton variant="rect" className={classes.media} />}
                        {<ProgressiveImage src={category[K.CATEGORIES.IMAGE].thumbnails.large.url} placeholder={category[K.CATEGORIES.IMAGE].thumbnails.small.url}>
                            {(src) => <img onLoad={() => setLoading(false)} className={classes.media} alt={category[K.CATEGORIES.DISPLAY_NAME]} src={src} />}
                        </ProgressiveImage>}
                    </div>
                ))}
            </div>
        }
        </>
    )
}

const getResponsiveStyles = (grid, length, xs, sm, md, lg, xl) => {
    const cols = () => {
        if (xs) {
            return 3;
        }
        if (sm) {
            return 4;
        }
        if (md) {
            return 5;
        }
        if (lg) {
            return 4;
        }
        if (xl) {
            return 4;
        }
        return 1;
    }
    const gap = .26;
    const row = 1.1;
    const radius = .04;
    const font = 0.13;
    let width = grid.current ? grid.current.offsetWidth : 0;
    let colSize = width / (((cols() - 1) * gap) + cols());
    return {
        cols: cols(),
        columnSize: `${colSize}px`,
        rows: Math.ceil(length / cols()),
        rowSize: `${row * colSize}px`,
        gapSize: `${gap * colSize}px`,
        radius: `${radius * colSize}px`,
        fontSize: `${font * colSize}px`,
    }
}

export default Component;