import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

// material UI
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Skeleton from '@material-ui/lab/Skeleton';
import Fab from '@material-ui/core/Fab';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import CircularProgress from '@material-ui/core/CircularProgress';

// styles
import useStyles from './styles';

import { getPriceChoices } from '../../utils/helperFunctions';

const StyledFab = withStyles((props) => ({
    root: {
        backgroundColor: '#32E29F',
        margin: '2%',
        "&:hover": {
            backgroundColor: '#26b57f',
        }
    },
}))(Fab);

const Component = ({ selectedBrand, userPoints, communityColor, communityTextColor, redeemReward, redemptionStatus, resetRedemptionStatus }) => {
    let history = useHistory();
    const {t} = useTranslation('common');
    const classes = useStyles({
        backgroundColor: communityColor ? communityColor : '#32E29F',
        textColor: communityTextColor ? communityTextColor : '#000000de',
    });

    const [openConfirmation, setOpenConfirmation] = useState(false);
    const [prices, setPrices] = useState([]);
    const [selectedPrice, setSelectedPrice] = useState(0);

    useEffect(() => {
        let choices = getPriceChoices(selectedBrand);
        setPrices(choices);
    }, [selectedBrand]);

    const handleSubmitClick = () => {
        setOpenConfirmation(true);
    }

    const handleCloseConfirmation = () => {
        setOpenConfirmation(false);
    }

    const handleDoneClick = () => {
        resetRedemptionStatus();
        history.push('/rewards');
    }

    const handleRedeemClick = () => {
        if (selectedBrand.items.length === 1) {
            redeemReward(selectedBrand.items[0], selectedPrice);
        } else {
            const fixedReward = selectedBrand.items.find(({faceValue}) => faceValue === selectedPrice);
            redeemReward(fixedReward, selectedPrice);
        }
    }

    const handleSelectPrice = (price) => {
        setSelectedPrice(price);
    }

    return ( 
        <div className={classes.container}>
            <div className={classes.card}>
                {selectedBrand ?
                    <>
                    <Typography className={classes.title} variant="h6">{selectedBrand.brandName}</Typography>
                        <div className={classes.mediaWrapper}>
                            <img className={classes.media}
                                alt={selectedBrand.brandName}
                                src={selectedBrand.imageUrls['1200w-326ppi']} />
                        </div>
                        <div>
                            <div className={classes.choosePrice}>
                                {prices.map((price, index) => {
                                    return (
                                        <div className={classes.priceContainer}>
                                            <StyledFab disabled={price > (userPoints/1000)} size='medium' onClick={() => {handleSelectPrice(price);}}>${price}</StyledFab>
                                            <Typography variant='caption' className={classes.priceText}>{price * 1000} pts</Typography>
                                        </div>
                                    )
                                })}
                            </div>
                            <Button disabled={selectedPrice === 0} className={classes.submitButton} onClick={handleSubmitClick}>{t('rewards.claim')}</Button>

                            <div dangerouslySetInnerHTML={{ __html: selectedBrand.description}} />

                            <div>
                                <Typography className={classes.header}>{t('rewards.disclaimer')}</Typography>
                            </div>
                            <div className={classes.tangoText} dangerouslySetInnerHTML={{ __html: selectedBrand.disclaimer}} />
                            <div>
                                <Typography className={classes.header}>{t('rewards.terms')}</Typography>
                            </div>
                            <div className={classes.tangoText} dangerouslySetInnerHTML={{ __html: selectedBrand.terms}} />
                        </div>
                    </>
                    :
                    <div>
                        <Skeleton variant="rect" className={classes.media} />
                        <Skeleton />
                        <Skeleton animation={false} />
                        <Skeleton animation="wave" />
                    </div>
                }
                <Dialog
                    open={openConfirmation}
                    //TransitionComponent={Transition}
                    disableBackdropClick
                    disableEscapeKeyDown
                    keepMounted
                    onClose={handleCloseConfirmation}
                    aria-labelledby="confirmation-dialog-label"
                    aria-describedby="confirmation-dialog-decription"
                >
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            { redemptionStatus === 'none' ?
                            <>
                                {t('rewards.redemptionSelectionA')}${selectedPrice} {selectedBrand.brandName}{t('rewards.redemptionSelectionB')} 
                            </>
                            :
                            <>  
                                { redemptionStatus === 'loading' &&
                                    <CircularProgress/>
                                }
                                { redemptionStatus === 'success' &&
                                    <>
                                        {t('rewards.redemptionSuccess')}
                                    </>
                                }
                                { redemptionStatus === 'failure' &&
                                    <>
                                        {t('rewards.redemptionError')}
                                    </>
                                }
                            </>
                            } 
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        { redemptionStatus === 'none' &&
                        <>
                            <Button className={classes.backButton} onClick={handleCloseConfirmation} color="primary" variant='outlined'>
                                {t('rewards.back')}
                            </Button>
                            <div style={{flex: '1 0 0'}} />
                        
                            <Button className={classes.redeemButton} onClick={handleRedeemClick} color="primary">
                                {t('rewards.redeem')}
                            </Button>
                            </>
                        }
                        { (redemptionStatus === 'success' || redemptionStatus === 'failure') &&
                            <Button className={classes.redeemButton} onClick={handleDoneClick} color="primary">
                                Done
                            </Button>
                        }
                    </DialogActions>
                </Dialog>
            </div>
        </div>
    )
}

export default Component;