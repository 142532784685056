import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { SetSelectedReward } from '../../../actions/rewardsActions';

// material UI
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import Skeleton from '@material-ui/lab/Skeleton';

import useStyles from './styles';
import RewardActiveIcon from '../../../images/rewards-badge-color.svg';
import RewardInactiveIcon from '../../../images/rewards-badge-grey.svg';

import { getPriceChoices } from '../../../utils/helperFunctions';

const Component = ({ data, fit, onRewardClick, userPoints }) => {
    let props = { fit };
    const history = useHistory();
    const classes = useStyles(props);
    const [loading, setLoading] = useState(true);

    const handleRewardClick = () => {
        onRewardClick(data);
        history.push('/rewardDetails');
    }

    const isAffordable = () => {
        let choices = getPriceChoices(data);
        return choices[0] <= (userPoints/1000);
    }

    return (
        <Card elevation={0} className={classes.card} onClick={handleRewardClick}>
            {loading && <Skeleton variant="rect" className={classes.media} />}
            
            <img 
                src={data.imageUrls['130w-326ppi']}
                alt={data.brandName}
                className={classes.media}
                onLoad={() => setLoading(false)}
            />
            
            <Typography className={classes.text}>{data.brandName}</Typography>
            
            { isAffordable() ? 
                <img src={RewardActiveIcon} className={classes.claimReward}></img>
            :
                <img src={RewardInactiveIcon} className={classes.claimReward}></img>
            }
            
        </Card>
    );
}

const mapStateToProps = state => {
    return {
        userPoints: state.rewards.points,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onRewardClick: rewardData => {
            dispatch(SetSelectedReward(rewardData))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Component);