import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    container: {
        top: '10vh',
        position: 'absolute',
        marginLeft: 10,
        marginRight: 10,
        // marginBottom: '10vh',
        bottom: '10vh',
        overflow: 'scroll',
    },
    card: {
        margin: '1em 0',
    },
    cardContent: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
    },
    headerText: {
        fontFamily: 'helvetica-neue-lt-std-75-bold',
        color: '#35353d',
        textShadow: '1px 1px #33e29f',
        [theme.breakpoints.up('xs')]: {
            fontSize: '25px',
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: '30px',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '40px',
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: '27px',
        },
    },
    table: {
        color: '#35353d',
    },
    tableHeaderCell: {
        color: '#35353d',
        justifyContent: 'left',
        verticalAlign: 'bottom',
        lineHeight: '1.1',
        fontSize: '.9rem',
        padding: '0px',
    },
    tableCell: {
        color: '#35353d',
        justifyContent: 'left',
        verticalAlign: 'bottom',
        lineHeight: '1.1',
        padding: '16px 0',
    },
    readMore: {
        display: 'flex',
        backgroundColor: '#212129',
        border: 'none',
        cursor: 'pointer',
        overflow: 'hidden',
        outline: 'none',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        padding: '3% 5%',
        marginTop: 10,
        borderRadius: 50,
    },
    readMoreText: {
        color: 'white',
        fontFamily: 'helvetica-neue-lt-std-65-medium',
        [theme.breakpoints.up('xs')]: {
            fontSize: '14px',
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: '20px',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '24px',
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: '16 px',
        },
    },
    readMoreArrow: {
        [theme.breakpoints.up('xs')]: {
            height: '25px',
            width: '25px',
        },
        [theme.breakpoints.up('sm')]: {
            height: '40px',
            width: '40px',
        },
        [theme.breakpoints.up('md')]: {
            height: '60px',
            width: '60x',
        },
        [theme.breakpoints.up('lg')]: {
            height: '25px',
            width: '25px',
        },
        fill: 'white',
        transform: 'rotate(-90deg)',
    },
    avatar: {
        width: theme.spacing(10),
        height: theme.spacing(10),
        marginRight: 10,
    },
    subHeader: {
        marginTop: 15,
        width: '100%',
        display: 'inline-block',
        color: '#35353d',
        fontFamily: 'helvetica-neue-lt-std-65-medium',
        borderBottom: 'solid 2px #f5fbf9',
        [theme.breakpoints.up('xs')]: {
            fontSize: '18px',
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: '20x',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '20px',
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: '20 px',
        },
    },
    content: {
        display: 'flex',
        color: '#35353d',
        fontFamily: 'helvetica-neue-lt-std-65-medium',
        [theme.breakpoints.up('xs')]: {
            fontSize: '14px',
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: '20px',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '24px',
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: '16 px',
        },
    },
    wasteSelect: {
        display: 'flex',
        backgroundColor: '#35353d',
        border: 'none',
        cursor: 'pointer',
        overflow: 'hidden',
        outline: 'none',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        color: '#f5fbf9',
        marginTop: 15,
        height: 45,
        borderRadius: 50,
    },
    addButton: {
        width: '48%',
        borderRadius: 50,
        backgroundColor: 'rgb(50, 226, 159)',
        color: '#35353d',
        marginTop: 10,
    },
    clearButton: {
        width: '48%',
        borderRadius: 50,
        color: '#35353d',
        marginTop: 10,
    },
    buttonContainer: {
        textAlign: 'center',
        justifyContent: 'center',
        display: 'flex',
        paddingBottom: '5%',
        justifyContent: 'space-between',
    },
    equivalencyBox: {
        border: '7px solid rgb(50, 226, 159)',
        borderRadius: '20px',
        marginBottom: 10,
        padding: '1%',
        display: 'flex',
        textAlign: 'center',
        alignItems: 'center',
    },
    carIcon: {
        width: '35%',
        height: '35%',
        marginRight: '5%',
    },
    homeIcon: {
        width: '25%',
        height: '25%',
        marginRight: '10%',
        marginLeft: '2%',
    },
    equivalencyValue: {
        fontSize: '1.8rem',
        paddingRight: '5%',
    },
    equivalencyDescription: {
        color: '#35353d',
        fontFamily: 'helvetica-neue-lt-std-65-medium',
        textAlign: 'center',
        lineHeight: '1.3',
    }
}));

export default useStyles;