import { connect } from 'react-redux';
import { ClearFeedbackMessage, CreateEmailPasswordAccount } from '../../actions/userActions';
import Component from './component';

const mapStateToProps = state => {
    return {
        feedback: state.user.feedback,
        signedIn: state.user.signedIn,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        createEmailPasswordAccount: (accountInfo) => {
            dispatch(CreateEmailPasswordAccount(accountInfo));
        },
        clearFeedbackMessage: () => {
            dispatch(ClearFeedbackMessage());
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Component);