import C from './actionTypes';
import K from '../utils/keys';
import { nativeApi, database, firebase } from '../utils/admin';

export const ClearFeedbackMessage = () => (dispatch) => {
    dispatch({
        type: C.SET_FEEDBACK_MESSAGE,
        payload: "",
    });
}

export const ResetRequestStatus = () => (dispatch) => {
    dispatch({
        type: C.USER_REQUEST_STATUS,
        payload: "idle",
    });
}

export const IncrementInstallPrompt = (installPromptCount) => (dispatch) => {
    var newCount = installPromptCount >= 8 ? 0 : installPromptCount + 1;
    dispatch({
        type: C.USER_INSTALL_PROMPT,
        payload: newCount,
    })
}

export const SetLanguage = (language) => (dispatch) => {
    dispatch({
        type: C.SET_USER_LANGUAGE,
        payload: language,
    });
}

export const IsUserSignedIn = () => (dispatch) => {
    firebase.auth().onAuthStateChanged(function(user) {
        if (user) {
            dispatch({
                type: C.USER_SIGNIN_STATUS,
                payload: true
            });
        } else {
            dispatch({
                type: C.USER_SIGNIN_STATUS,
                payload: false
            });
        }
    });
}

export const IsUserEmailVerified = () => (dispatch) => {
    console.log('isuseremailverified');
    var user = firebase.auth().currentUser;
    if (user) {
        user.reload().then(()=> {
            dispatch({
                type: C.USER_VERIFICATION_STATUS,
                payload: user.emailVerified
            });
        });
    }
}

export const CreateEmailPasswordAccount = (accountInfo) => (dispatch) => {
    let email = accountInfo.email.trim();
    let password = accountInfo.password;
    let name = accountInfo.name;
    let phone = accountInfo.phoneNumber;
    let enableEmails = accountInfo.enableEmails;
    let enableTexts = accountInfo.enableTexts;

    dispatch({
        type: C.CREATE_USER_ACCOUNT,
        payload: 'loading',
    })

    firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL).then(() => {
        firebase.auth().createUserWithEmailAndPassword(email, password).then((userCredentials) => {
            if (userCredentials.user) {
                userCredentials.user.updateProfile({
                    displayName: name,
                }).then((s) => {
                    database.ref('users/' + userCredentials.user.uid + '/accountInformation').set({
                        name: name,
                        email: email,
                        phoneNumber: phone,
                        enableEmails: enableEmails,
                        enableTexts: enableTexts,
                    }).then(() => {
                        dispatch({
                            type: C.SET_FEEDBACK_MESSAGE,
                            payload: "Successfully created account.",
                        });
                        dispatch({
                            type: C.LAUNCH_COMMUNITY_BROWSER,
                            payload: true,
                        });
                        dispatch({
                            type: C.USER_SIGNIN_STATUS,
                            payload: true
                        });
                    }).catch((error) => {
                        console.log('Error');
                        dispatch({
                            type: C.SET_FEEDBACK_MESSAGE,
                            payload: error.message,
                        });
                        dispatch({
                            type: C.USER_SIGNIN_STATUS,
                            payload: false
                        });
                    });
                });
            }

        }).catch((error) => {
            var errorMessage = error.message;
            console.log(errorMessage);
            dispatch({
                type: C.SET_FEEDBACK_MESSAGE,
                payload: error.message,
            });
        });
    });
}

export const EmailPasswordLogin = (email, password) => (dispatch) => {
    firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL).then(() => {
        firebase.auth().signInWithEmailAndPassword(email.trim(), password).then((userCredentials) => {
            console.log('Successful sign-in!')
            dispatch({
                type: C.USER_SIGNIN_STATUS,
                payload: true
            });
            let accountInfo = {
                name: userCredentials.user.displayName,
                phoneNumber: '',
                enableEmails: false,
                enableTexts: false
            }
            // console.log(userCredentials.user.uid);
            dispatch({
                type: C.SET_USER_ACCOUNT_INFO,
                payload: accountInfo
            });
            dispatch({
                type: C.SET_FEEDBACK_MESSAGE,
                payload: "Successfully logged in.",
            });
            dispatch({
                type: C.LAUNCH_COMMUNITY_BROWSER,
                payload: true,
            });
        }).catch((error) => {
            var errorMessage = error.message;
            console.log(errorMessage);
            dispatch({
                type: C.SET_FEEDBACK_MESSAGE,
                payload: errorMessage,
            })
        });
    });
}

export const SendPasswordResetLink = (email, language) => (dispatch) => {
    if (language !== 'en') {
        firebase.auth().languageCode = language;
    }

    firebase.auth().sendPasswordResetEmail(email.trim()).then(function() {
        let successMessage = 'Check your email for a link to reset your password.';
        if (language === 'es') {
            successMessage = 'Revise su correo electrónico para encontrar un enlace para restablecer su contraseña.'
        }

        console.log('Success')
        dispatch({
            type: C.SET_FEEDBACK_MESSAGE,
            payload: successMessage,
        });
        // Email sent.
      }).catch((error) => {
        // var errorCode = error.code;
        var errorMessage = error.message;
        console.log(errorMessage);
        dispatch({
            type: C.SET_FEEDBACK_MESSAGE,
            payload: errorMessage,
        })
      });
}

export const SignOut = () => (dispatch) => {
    dispatch({
        type: C.USER_SIGNIN_STATUS,
        payload: false
    });
    console.log('signout');
    firebase.auth().signOut().then(() => {
        console.log("Signed out.");
    }).catch(error => {
        console.log("Sign out error", error);
    })
}

export const ResetPublicThreadData = () => (dispatch) => {
    dispatch({
        type: C.SET_PUBLIC_THREAD_STATUS,
        payload: '',
    });
}

export const UpdatePublicThreadRegistrationCode = (code) => (dispatch) => {
    const updatedRegistration = {
        registrationCode: code,
    };
    dispatch({
        type: C.SET_PUBLIC_THREAD_STATUS,
        payload: 'loading',
    });

    // verify that tote registration code is valid
    nativeApi({
        method: 'post',
        url: '/validate-public-thread-code',
        data: {
            code: code
        }
    }).then(response => {
        if (response.data.valid) {
            var user = firebase.auth().currentUser;
            if (user) {
                 firebase.database().ref('/users/' + user.uid + '/publicThread').update(updatedRegistration).then(() => {
                    dispatch({
                        type: C.SET_PUBLIC_THREAD_STATUS,
                        payload: 'success',
                    });
                 }).catch(error => {
                     console.log(error);
                     dispatch({
                        type: C.SET_PUBLIC_THREAD_STATUS,
                        payload: 'error',
                    });
                 });
            }
        } else {
            // code found to be invalid
            dispatch({
                type: C.SET_PUBLIC_THREAD_STATUS,
                payload: 'not-found',
            });
        }
    }).catch(error => {
        console.log(error);
        dispatch({
            type: C.SET_PUBLIC_THREAD_STATUS,
            payload: 'error',
        });
    });
}

export const FetchPublicThread = () => (dispatch) => {
    console.log('FetchPublicThread()');
    var user = firebase.auth().currentUser;
    if (user) {
        firebase.database().ref('/users/' + user.uid + '/publicThread').once('value').then((snapshot) => {
            if (snapshot.val()) {
                dispatch({
                    type: C.SET_PUBLIC_THREAD_STATUS,
                    payload: 'success',
                });
            }
        }).catch(error => {
            console.log(error);
        })
    }
}

export const FetchAccountInfo = () => (dispatch) => {
    console.log('FetchAccountInfo');
    dispatch({
        type: C.USER_REQUEST_STATUS,
        payload: 'processing'
    });
    var user = firebase.auth().currentUser;
    if (user) {
        firebase.database().ref('/users/' + user.uid + '/accountInformation').once('value').then((snapshot) => {
            let accountInfo = {
                name: snapshot.val().name,
                phoneNumber: snapshot.val().phoneNumber,
                enableEmails: snapshot.val().enableEmails,
                enableTexts: snapshot.val().enableTexts,
                address: snapshot.val().address ? snapshot.val().address : "",
                city: snapshot.val().city ? snapshot.val().city : "",
                state: snapshot.val().state ? snapshot.val().state : "",
                zip: snapshot.val().zip ? snapshot.val().zip : "",
                // emailVerified: user.emailVerified
            }
            dispatch({
                type: C.SET_USER_ACCOUNT_INFO,
                payload: accountInfo
            });
            dispatch({
                type: C.USER_REQUEST_STATUS,
                payload: 'complete'
            });
        });

    } else {
        console.log('No user logged in');
    }
}

export const UpdateAccountInfo = (updatedAccountInfo) => (dispatch) => {
    console.log('UpdateAccountInfo')
    var user = firebase.auth().currentUser;
    if (user) {
        firebase.database().ref('/users/' + user.uid + '/accountInformation').update(updatedAccountInfo).then(() => {
            console.log('account updated');
            dispatch({
                type: C.SET_FEEDBACK_MESSAGE,
                payload: "Account successfully updated.",
            })
        }).catch(error => {
            console.log('error updating account');
            console.log(error.message);
            dispatch({
                type: C.SET_FEEDBACK_MESSAGE,
                payload: error.message,
            })
        });
    } else {
        console.log('No user logged in');
    }
}

export const SendVerificationEmail = () => {
    var user = firebase.auth().currentUser;
    console.log(user);
    if (user) {
        user.sendEmailVerification().then(() => {
            console.log('verification email sent');
        }).catch(error => {
            console.log(error);
        });
    }
}

export const FetchUserScanHistory = () => (dispatch, getState) => {
    console.log('FetchUserScanHistory');
    let currentState = getState();
    let community = currentState.communities.selectedCommunities[currentState.communities.activeIndex];
    
    // clear current scan history
    dispatch({
        type: C.SET_USER_SCAN_HISTORY,
        payload: [],
    });

    // set loading flag
    dispatch({
        type: C.FETCHING_USER_SCAN_HISTORY,
        payload: true,
    });

    var user = firebase.auth().currentUser;
    console.log('user', user);
    if (user) {
        console.log('in user');
        database.ref('/users/' + user.uid + '/rewards/scanHistoryWithTimestamps').once('value').then((snapshot) => {
            console.log('scanHistory:', snapshot);
            if (snapshot.val()) {
                let scanHistory = snapshot.val();
                let scanHistoryGtins = Object.keys(scanHistory);
                console.log('scanHistory:', scanHistory);
    
                let gtinsWithTimestamps = {};
                for (const gtin in scanHistory) {
                    gtinsWithTimestamps[gtin] = scanHistory[gtin].lastUpdateTimestamp
                }
    
                nativeApi({
                    method: 'post',
                    url: 'user-recycling-history',
                    data: {
                        gtins: scanHistoryGtins,
                        language: 'en',
                        email: user.email,
                        community: community[K.COMMUNITY.DISPLAY_NAME],
                    },
                }).then(response => {
                    let products = response.data;
                    products.forEach(product => {
                        product['lastUpdateTimestamp'] = gtinsWithTimestamps[product.gtin];
                    });
    
                    // show most recent scans first
                    products.sort(function(a, b) {
                        return b.lastUpdateTimestamp - a.lastUpdateTimestamp;
                    });
                    console.log('PRODUCTS IN SCAN HISTORY:', products);
    
                    dispatch({
                        type: C.SET_USER_SCAN_HISTORY,
                        payload: products,
                    });
                    dispatch({
                        type: C.FETCHING_USER_SCAN_HISTORY,
                        payload: false,
                    });
                }).catch(error => {
                    dispatch({
                        type: C.FETCHING_USER_SCAN_HISTORY,
                        payload: false,
                    });
                    console.log('Error while fetching scan history:', error);
                });
            } else {
                dispatch({
                    type: C.SET_USER_SCAN_HISTORY,
                    payload: [],
                });
                dispatch({
                    type: C.FETCHING_USER_SCAN_HISTORY,
                    payload: false,
                });
            }
        }).catch(error => {
            console.log('Error reading product history:', error);
        });
    }
}