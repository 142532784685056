import React from 'react';
import K from '../../utils/keys';
import { analytics, auth } from '../../utils/admin';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import ProgressiveImage from 'react-progressive-image';

export const Component = ({ sponsor, page, community, wasteType }) => {
    const classes = makeStyles(theme => ({
        homeSponsor: {
            [theme.breakpoints.down('lg')]: {
                height: '100%',
                width: '100%',
            },
            [theme.breakpoints.up('lg')]: {
                padding: '0% 36%'
            },
            borderRadius: 15,
        },
        productSponsor: {
            height: '100%',
            width: '100%',
        },
    }))();

    const handleClick = (page = 'home') => {
        if (sponsor.direct_link) {
            let user = auth.currentUser;
            let userEmail = '';
            if (user) {
                userEmail = user.email;
            }
            analytics.logEvent('sponsor_click', { 
                sponsor: sponsor.display_name, 
                link: sponsor.direct_link, page: page,
                community: community,
                email: userEmail,
                waste_type: wasteType,
            });
            window.open(sponsor.direct_link, "_blank")
        }
    }

    return (
        <>
            {page === 'home' ?
                <>
                    <ProgressiveImage src={sponsor.home_page_photo.thumbnails.large.url} placeholder={sponsor.home_page_photo.thumbnails.small.url}>
                        {(src) => <img className={classes.homeSponsor} alt='sponsor' src={src} onClick={() => handleClick('home')} />}
                    </ProgressiveImage>
                </>
                :
                <>
                    <ProgressiveImage src={sponsor.product_page_photo.thumbnails.large.url} placeholder={sponsor.product_page_photo.thumbnails.small.url}>
                        {(src) => <img className={classes.productSponsor} alt='sponsor' src={src} onClick={() => handleClick('product')} />}
                    </ProgressiveImage>
                </>
            }
        </>
    );
}


const mapStateToProps = state => {
    return {
        community: state.communities.selectedCommunities[state.communities.activeIndex] ? state.communities.selectedCommunities[state.communities.activeIndex][K.COMMUNITY.DISPLAY_NAME] : '',
        wasteType: state.communities.selectedCommunities[state.communities.activeIndex] ? state.communities.selectedCommunities[state.communities.activeIndex]['waste_type'] : null,
    }
}

export default connect(mapStateToProps)(Component);