import React,  { useEffect } from 'react';
import { connect } from 'react-redux';
import Divider from '@material-ui/core/Divider';
import AlternateHomeTabItem from './AlternateHomeTabItem';
import { makeStyles } from '@material-ui/core';
import { FetchAlternateTabContent } from '../../../actions/communityActions';

const useStyles = makeStyles({
    alternateHomeTabContainer: {
        backgroundColor: 'white',
        paddingBottom: '12vh',
    },
})

export const Component = ({ language, alternateTabItems, fetchAlternateTabContent }) => {
    const classes = useStyles();

    useEffect(() => {
        fetchAlternateTabContent(language);
    }, [fetchAlternateTabContent, language]);

    return (
        <div className={classes.alternateHomeTabContainer} >
            {alternateTabItems.map((item, i) => (
                <div key={i}>
                    {i > 0 && <Divider light />}
                    <AlternateHomeTabItem title={item.title} image={item.image} message={item.content} link={item.link}/>
                </div>
            ))}
        </div>
    )
}

const mapStateToProps = state => {
    return {
        alternateTabItems: state.communities.selectedCommunities[state.communities.activeIndex] && state.communities.selectedCommunities[state.communities.activeIndex].alternate_home_tab ?
            state.communities.selectedCommunities[state.communities.activeIndex].alternate_home_tab
            : [],
        language: state.inputs.language ? state.inputs.language : 'en',
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchAlternateTabContent: (language) => {
            dispatch(FetchAlternateTabContent(language));
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Component);