import React, {useState} from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core/styles';
import { Dialog, Typography, Button, CircularProgress } from '@material-ui/core';

import PublicThreadTote from '../../../../icons/PublicThread/PublicThreadToteGraphic.png';
import { UpdatePublicThreadRegistrationCode, ResetPublicThreadData } from '../../../../actions/userActions';

const useStyles = makeStyles({
    root: {
        borderRadius: 15,
    },
    dialog: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderRadius: 15,
    },
    imageContainer: {
        marginTop: 10,
    },
    publicThreadTote: {
        height: 200,
        width: 200,
    },
    title: {
        fontSize: 20,
        marginBottom: 10,
    },
    message: {
        fontFamily: 'helvetica-neue-lt-std-65-medium',
        fontSize: 16,
        marginLeft: 10,
        marginRight: 10,
        marginTop: 10,
    },
    textContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        padding: 10,
    },
    inputLabel: {
        display: 'flex',
        justifyContent: 'left',
        marginTop: 10,
        marginLeft: 10,
    },
    input: {
        width: '100%',
        justifyContent: 'space-between',
        padding: '3% 0',
        backgroundColor: 'rgb(244, 251, 247)',
        zIndex: 5,
        borderRadius: 50,
        borderStyle: 'solid',
        borderColor: 'black',
        borderWidth: '1px',
    },
    inputText: {
        flex: 4,
        //fontFamily: 'helvetica-neue-lt-std-65-medium',
        fontSize: 14,
        backgroundColor: 'Transparent',
        border: '0',
        '&:focus': {
            outline: "none",
        },
        padding: '0 .3em 0 .7em',
        WebkitAppearance: 'none',
        borderRadius: '.3em 0 0 .3em',
        margin: 0,
        minWidth: 0,
        zIndex: 4,
        width: '100%',
    },
    buttonsContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        marginBottom: 15,
    },
    registerButton: {
        fontFamily: 'helvetica-neue-lt-std-65-medium',
        fontSize: 16,
        width: '45%',
        borderRadius: 50,
        color: 'white',
        backgroundColor: '#12a483',
        textTransform: 'none',
    },
    cancelButton: {
        fontFamily: 'helvetica-neue-lt-std-65-medium',
        fontSize: 16,
        width: '45%',
        borderRadius: 50,
        backgroundColor: '#353535',
        color: 'white',
        textTransform: 'none',
    },
    loadingContainer: {
        height: '30vh',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    }
});

const PublicThread = ({ open, setOpen, status, registerTote, resetPublicThreadData, qrRegistrationCode }) => {
    const history = useHistory()
    const {t} = useTranslation('common');

    const classes = useStyles();

    const [code, setCode] = useState('');

    const onRegisterTote = () => {
        console.log('register tote');
        registerTote(code);
    }

    const onCancel = () => {
        setOpen(false);
    }

    const handleCodeChange = (event) => {
        setCode(event.target.value);
    }

    const onCloseFailedAttempt = () => {
        setOpen(false);
        resetPublicThreadData();
    }

    return (
        <Dialog
            open={open}
            maxWidth='xl'
            fullWidth={true}
            classes={{paper: classes.root}}
        >
            <div className={classes.dialog}>
                { status === '' &&
                <>
                    <div className={classes.imageContainer}>
                        <img src={PublicThreadTote} className={classes.publicThreadTote}/>
                    </div>

                    <Typography className={classes.title}>
                        Have a Public Thread tote bag?
                    </Typography>

                    <div className={classes.messageContainer}>
                        <Typography className={classes.message}>
                            If you've scanned the QR code that comes with the totes and see a registration code below, tap register and you're all set! If you're interested in buying a tote bag they can be purchased here.
                        </Typography>
                    </div>

                    <div className={classes.textContainer}>
                        <Typography className={classes.inputLabel} >
                            Registration code
                        </Typography>
                        <form className={classes.input}>
                            <input className={classes.inputText} 
                                type="text"
                                label="name"
                                value={code}
                                name="name"
                                onChange={handleCodeChange}
                                variant='outlined'
                            />
                        </form>
                    </div>


                    <div className={classes.buttonsContainer}>
                        <Button 
                            className={classes.cancelButton}
                            onClick={onCancel}
                        >
                            Cancel
                        </Button>
                        <Button 
                            className={classes.registerButton}
                            onClick={onRegisterTote}
                        >
                                Register
                        </Button>
                    </div>
                </>
                }

                { status === 'loading' &&
                    <div className={classes.loadingContainer}>
                        <CircularProgress />
                    </div>
                }

                { status === 'error' &&
                <>
                    <Typography style={{marginBottom: 15, marginTop: 15}} className={classes.message}>
                        Sorry, there was an error while registering your tote. Please try again later.
                    </Typography>
                    <div className={classes.buttonsContainer}>
                        <Button 
                            className={classes.cancelButton}
                            onClick={onCloseFailedAttempt}
                        >
                            Close
                        </Button>
                    </div>
                </>
                }

                { status === 'not-found' &&
                <>
                    <Typography style={{marginBottom: 15, marginTop: 15}} className={classes.message}>
                        Sorry, we couldn't find that code, double check and retry.
                    </Typography>
                    <div className={classes.buttonsContainer}>
                        <Button 
                            className={classes.cancelButton}
                            onClick={onCloseFailedAttempt}
                        >
                            Close
                        </Button>
                    </div>
                </>
                }

                { status === 'success' &&
                <>
                    <Typography style={{marginBottom: 15, marginTop: 15}} className={classes.message}>
                        Tote successfully registered!
                    </Typography>
                    <div className={classes.buttonsContainer}>
                        <Button 
                            className={classes.cancelButton}
                            onClick={onCancel}
                        >
                            Close
                        </Button>
                    </div>
                </>
                }
            </div>
        </Dialog>
    );
}

const mapStateToProps = state => {
    return {
        status: state.user.publicThread.status,
        qrRegistrationCode: state.user.publicThread.registrationCode,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        registerTote: (code) => {
            dispatch(UpdatePublicThreadRegistrationCode(code));
        },
        resetPublicThreadData: () => {
            dispatch(ResetPublicThreadData());
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PublicThread);