import { makeStyles } from '@material-ui/core/styles';
import backgroundURL from '../../images/initialpage_background_gabriel-jimenez-jin4W1HqgL4-unsplash.jpg';

const useStyles = makeStyles(theme => ({
    backDrop: {
        flexDirection: 'column',
        display: 'flex',
        backgroundImage: `url(${backgroundURL})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        position: 'fixed',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        height: '100%',
        width: '100%',
        minWidth: '100%',
        zIndex: 2, // cover scanner overlay
    },
    colorOverlay: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        // backgroundColor: 'rgba(50, 226, 159, 0.8)',
        backgroundColor: 'rgb(50, 226, 159)',
        zIndex: 2,
    },
    betterbinLogo: {
        width: '40%',
        marginTop: 15,
        marginBottom: 15,
        right: '0',
        left: '0',
        marginLeft: 'auto',
        marginRight: 'auto',
        zIndex: 4,
    },
    card: {
        zIndex: 3,
        backgroundColor: '#f4fbf7',
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    header: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        display: 'flex',
        marginTop: 10,
        marginLeft: 10,
        marginRight: 10,
    },
    info: {
        backgroundColor: '#353535',
        color: 'white',
        minHeight: '7vh',
        marginLeft: 10,
        marginRight: 10,
        borderRadius: 15,
        padding: 10,
        marginTop: 10,
        display: 'flex',
        alignItems: 'center',
    },
    infoText: {
        fontFamily: 'helvetica-neue-lt-std-65-medium',
        fontSize: 16,
    },
    drillDownContainer: {
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'scroll',
        marginTop: 10,
        marginBottom: '10vh',
    },
    communities: {
        marginLeft: 10,
        marginRight: 10,
        overflowY: 'scroll',
        zIndex: 4,
        display: 'flex',
        flexDirection: 'column',
        borderRadius: 15,
    },
    community: {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: '#ffffff',
        borderRadius: '6px',
        stroke: '1px solid #d8ddda',
        height: 50,
        marginTop: 5,
        marginBottom: 5,
        paddingLeft: 5,
        boxShadow: '0px 2px 4px -1px rgb(0 0 0 / 10%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
        borderRadius: 50,
    },
    communityText: {
        fontFamily: 'helvetica-neue-lt-std-65-medium',
        fontSize: 16,
    },
    innerContainer: {
        flexDirection: 'column',
    },
    logo: {
        margin: '0 1em 0 0',
        objectFit: 'contain',
    },
    headerItem: {
        textAlign: 'center',
        color: 'rgb(53,53,61)',
        fontSize: 16,
    },
    closeButton: {
        position: 'absolute',
        top: 3,
        right: 3,
    },
    progressContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '80vh',
    },
}));

export default useStyles;