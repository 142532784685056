import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        padding: '1em',
        backgroundColor: '#F9F9F9', // main background color
        height: '100%',
    },
    messageContainer: {
        margin: '.5em 0',
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    messageImage: {
        objectFit: 'contain',
        width: '100%',
    },
});

export default useStyles;