import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core/styles';
import { GridList, GridListTile, CircularProgress, Typography } from '@material-ui/core';

import { SetSelectedProduct } from '../../actions/productActions';

import ProductHistoryCard from './ProductHistoryCard';
import { FetchUserScanHistory } from '../../actions/userActions';

const useStyles = makeStyles({
    container: {
        width: '100%',
        bottom: 0,
        left: 0,
        right: 0,
        overflow: 'hidden',
        overflowY: 'scroll',
        marginBottom: '10vh',
        top: '10vh',
        position: 'absolute',
        paddingLeft: 10,
        paddingRight: 10,
        marginTop: 10,
    },
    gridContainer: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
    },
    pageButtonsContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    title: {
        fontFamily: 'helvetica-neue-lt-std-65-medium',
        fontSize: 16,
        marginLeft: 10,
        marginBottom: 10,
    }
});

const ConversationHistoryPage = ({ scanHistory, fetchProductHistory, fetchingHistory, selectedProduct, selectProduct }) => {
    const history = useHistory()
    const {t} = useTranslation('common');

    const classes = useStyles();

    const [pageNumber, setPageNumber] = useState(1);
    const pageSize = 10;

    const handleProductHistoryClick = () => {
        selectProduct()
        history.push('/instructions');
    }

    return (
        <div className={classes.container}>
            <div className={classes.gridContainer}>
                <Typography className={classes.title}>Recently scanned products:</Typography>
                { fetchingHistory ?
                    <CircularProgress />
                :
                <>
                    { scanHistory !== undefined &&
                    <>
                        <GridList cellHeight={110} cols={1}>
                            { scanHistory.map((product, i) => (
                                <GridListTile key={i} rows={1} cols={1}>
                                    <ProductHistoryCard product={product} onProductClick={handleProductHistoryClick}/>
                                </GridListTile>
                            ))}
                        </GridList>
                    </>
                }
                </>
                }

            </div>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        selectedWasteType: state.communities.selectedCommunities[state.communities.activeIndex].waste_type,
        scanHistory: state.user.scanHistory,
        fetchingHistory: state.user.fetchingUserScanHistory,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        selectProduct: (productData) => {
            dispatch(SetSelectedProduct(productData));
        },
        fetchProductHistory: () => {
            dispatch(FetchUserScanHistory());
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ConversationHistoryPage);