import C from './actionTypes';
import K from '../utils/keys';
import { nativeApi, api, analytics, database, auth } from '../utils/admin';

// fetch the Tango rewards catalog
export const GetRewardsCatalog = () => (dispatch, getState) => {
    dispatch({
        type: C.SUBMIT_SEARCH,
        payload: true
    });

    nativeApi({
        method: 'get',
        url: `rewards-catalog?language=${getState().inputs.language}`,
    }).then(response => {
        console.log(response.data);
        dispatch({
            type: C.SET_REWARDS_CATALOG,
            payload: response.data
        });
        dispatch({
            type: C.SUBMIT_SEARCH,
            payload: false
        });
    }).catch(error => {
        console.log(error);
        dispatch({
            type: C.SUBMIT_SEARCH,
            payload: false
        });
    });
}

// selects a reward to show the details page
export const SetSelectedReward = (rewardData) => (dispatch) => {
    console.log(rewardData);
    dispatch({
        type: C.SET_SELECTED_REWARD,
        payload: rewardData
    });
}

// show the user how many points they earned
export const SetRewardsAlert = (show) => (dispatch) => {
    dispatch({
        type: C.SET_REWARDS_ALERT,
        payload: show
    })
}

export const ResetRedemptionStatus = () => (dispatch) => {
    dispatch({
        type: C.SET_REDEMPTION_STATUS,
        payload: 'none'
    });
}

// check if scan should earn points, allocate points if so
export const SubmitForPoints = (scanResult, userAction) => (dispatch, getState) => {
    console.log('SubmitForPoints()');

    // make sure gtin is full 14 digits
    var gtin = scanResult.padStart(14, '0');

    // get community from redux state
    let currentState = getState()
    let community = currentState.communities.selectedCommunities[currentState.communities.activeIndex];

    var date = new Date();

    // monthYear is used to keep track of monthly point totals
    const monthYear = (date.getMonth() + 1) + '-' + date.getFullYear();

    // currentDate is used to track time period for individual product re-scans
    const currentDate = Date.now();
    console.log('Date now:', currentDate);

    let historyType = '';
    let otherHistoryType = '';
    let earnedPoints = 0;
    if (userAction === 'scan') {
        historyType = 'scanHistoryWithTimestamps';
        otherHistoryType = 'photoPromptHistoryWithTimestamps';
        earnedPoints = K.REWARDS.SCAN_POINTS;
    } else if (userAction === 'photo-prompt') {
        historyType = 'photoPromptHistoryWithTimestamps';
        otherHistoryType = 'scanHistoryWithTimestamps';
        earnedPoints = K.REWARDS.MISSING_PRODUCT_POINTS;
    }

    var user = auth.currentUser;
    if (user) {
        var rewardsRef = database.ref('/users/' + user.uid + '/rewards');
        console.log('rewardsRef:', rewardsRef);
        rewardsRef.once('value').then((snapshot) => {
            console.log('snapshot.val()', snapshot.val());

            const userRewardsData = snapshot.val();

            // initialize rewards struct if not present
            if (!userRewardsData) {
                database.ref('users/' + user.uid + '/rewards').set({
                    points: earnedPoints,
                    [historyType]: {
                        [gtin]: {
                            lastUpdateTimestamp: currentDate,
                            allTimestamps: [currentDate],
                        }
                    },
                    [otherHistoryType]: [],
                    period: monthYear,
                    monthlyPoints: earnedPoints,
                    redemptionHistory: []
                }).then(() => {
                    analytics.logEvent('rewards_points', {
                        id: user.uid,
                        email: user.email,
                        action_type: userAction,
                        amount: earnedPoints,
                        community: community[K.COMMUNITY.DISPLAY_NAME],
                    });
                    // display rewards alert
                    dispatch({
                        type: C.SET_REWARDS_ALERT,
                        payload: userAction
                    });
                    // set user points total
                    dispatch({
                        type: C.SET_USER_REWARDS_POINTS,
                        payload: earnedPoints
                    });
                    //detach event listener
                    rewardsRef.off('value');
                }).catch(error => {
                    console.log(error);
                    rewardsRef.off('value');
                })
            } else {
                let rewardsHistoryTypeList = userRewardsData[historyType] ? userRewardsData[historyType] : {};
                console.log('Got user rewards history list');
                // check that the user can receive more points based on monthly total
                const userEligibleForPoints = isUserUnderMonthlyPointsTotal(userRewardsData.monthlyPoints, 
                                                                            userRewardsData.period, 
                                                                            monthYear);
                // check that the action is eligible for points based on GTIN and last occurence timestamp
                const actionEligibleForPoints = isProductValidForPoints(gtin, 
                                                                        rewardsHistoryTypeList,
                                                                        currentDate);


                if (userEligibleForPoints && actionEligibleForPoints) {

                    let updatedScanHistory = rewardsHistoryTypeList;

                    var initialProductScan = true;
                    if (updatedScanHistory[gtin]) {
                        initialProductScan = false;
                        updatedScanHistory[gtin].lastUpdateTimestamp = currentDate;
                    } else {
                        // create product entry if it hasn't been scanned by this user yet
                        updatedScanHistory[gtin] = {
                            lastUpdateTimestamp: currentDate,
                            allTimestamps: [currentDate],
                        };
                    }
        
                    // update user's rewards data
                    database.ref('/users/' + user.uid + '/rewards').update({
                        points: userRewardsData.points + earnedPoints,
                        [historyType]: updatedScanHistory,
                        monthlyPoints: userRewardsData.period === monthYear ? userRewardsData.monthlyPoints + earnedPoints : earnedPoints,
                        period: monthYear,
                    }).then(() => {
                        analytics.logEvent('rewards_points', {
                            id: user.uid,
                            email: user.email,
                            action_type: userAction,
                            amount: earnedPoints,
                            community: community[K.COMMUNITY.DISPLAY_NAME],
                        });

                        // display rewards alert
                        dispatch({
                            type: C.SET_REWARDS_ALERT,
                            payload: userAction
                        });

                        // set user's rewards points total
                        dispatch({
                            type: C.SET_USER_REWARDS_POINTS,
                            payload: userRewardsData.points + earnedPoints
                        });

                        // if not the initial scan then add the timestamp to allTimestamps list
                        if (!initialProductScan) {
                            database.ref('/users/' + user.uid + '/rewards/' + historyType + '/' + gtin + '/allTimestamps').push(currentDate)
                        }

                        // detach event listener
                        rewardsRef.off('value');
                    }).catch(error => {
                        console.log(error);
                        rewardsRef.off('value');
                    });
                } else {
                    // add timestamp to overall list but don't update points or lastUpdatedTimestamp
                    database.ref('/users/' + user.uid + '/rewards/' + historyType + '/' + gtin + '/allTimestamps').push(currentDate).then(() => {
                        rewardsRef.off('value');
                    }).catch(error => {
                        console.log(error);
                        rewardsRef.off('value');
                    });
                }
            }
        });
    }
}

const isUserUnderMonthlyPointsTotal = (pointsThisMonth, lastRecordedMonth, currentMonth) => {
    console.log('isUserUnderMonthlyPointsTotal()');
    // determine if user is eligible to receive points based on monthly points limit and current month
    if (pointsThisMonth < K.REWARDS.MONTHLY_LIMIT || lastRecordedMonth !== currentMonth) {
        return true;
    } else {
        return false;
    }
}

const isProductValidForPoints = (gtin, rewardsHistory, currentDate) => {
    if (Object.keys(rewardsHistory).includes(gtin)) {
        // calculate days since last scan of this product
        const elapsed = currentDate - rewardsHistory[gtin].lastUpdateTimestamp;

        // check if enough days have elapsed to distribute points again
        if (elapsed > (K.REWARDS.RESCAN_INTERVAL * 24 * 60 * 60 * 1000)) {
            return true;
        } else {
            return false;
        }
    } else {
        return true;
    }
}

export const GetRewardsData = () => (dispatch) => {
    var user = auth.currentUser;
    if (user) {
        database.ref('/users/' + user.uid + '/rewards').once('value').then((snapshot) => {
            let currentPoints = snapshot.val() ? snapshot.val().points : 0;
            let redemptionHistory = snapshot.val() ? snapshot.val().redemptionHistory : [];
            let monthlyPoints = snapshot.val() ? snapshot.val().monthlyPoints : 0;
            dispatch({
                type: C.SET_USER_REWARDS_POINTS,
                payload: currentPoints
            });
            dispatch({
                type: C.SET_USER_REWARD_HISTORY,
                payload: redemptionHistory
            });
            dispatch({
                type: C.SET_USER_MONTHLY_POINTS,
                payload: monthlyPoints
            });
        })
    }
}

export const RedeemReward = (reward, price) => (dispatch, getState) => {
    console.log(reward);
    console.log(price);

    dispatch({
        type: C.SET_REDEMPTION_STATUS,
        payload: 'loading'
    });

    var user = auth.currentUser;
    if (user) {
        let userEmail = user.email;

        // get user's current point total and name
        database.ref('/users/' + user.uid).once('value').then((snapshot) => {
            console.log(snapshot.val());
            let name = snapshot.val().accountInformation.name;
            let points = snapshot.val().rewards.points;
            let redemptionHistory = snapshot.val().rewards.redemptionHistory ? snapshot.val().rewards.redemptionHistory : [];

            if ((price * 1000) <= points) {
                database.ref('/users/' + user.uid + '/rewards/points').set(points - (price*1000)).then(() => {
                    console.log('sending reward');
                    nativeApi({
                        method: 'post',
                        url: 'redeem-reward',
                        data: {
                            email: userEmail,
                            rewardId: reward.utid,
                            price: price,
                            name: name ? name : "Betterbin user",
                        }
                    }).then(response => {
                        console.log(response.data);

                        analytics.logEvent('rewards_redemption', {
                            id: user.uid,
                            email: user.email,
                            name: response.data.name,
                            amount: price,
                            community: getState().selectedCommunities[getState().communities.activeIndex][K.COMMUNITY.DISPLAY_NAME],
                        });

                        // store reward redemption history
                        redemptionHistory.push(response.data);
                        database.ref('/users/' + user.uid + '/rewards/redemptionHistory').set(redemptionHistory).then(() =>{
                            // show success message
                            dispatch({
                                type: C.SET_REDEMPTION_STATUS,
                                payload: 'success'
                            });
                        })
                    }).catch(error => {
                        console.log(error);

                        // redeem points if redemption process failed
                        database.ref('/users/' + user.uid + '/rewards/points').set(points).then(() => {
                            dispatch({
                                type: C.SET_REDEMPTION_STATUS,
                                payload: 'failure'
                            });
                        }).catch(error => {
                            dispatch({
                                type: C.SET_REDEMPTION_STATUS,
                                payload: 'failure'
                            });
                        })
                    });
                });
            }
        });
    }
}