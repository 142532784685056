import React, { useEffect, useState } from 'react';
import { useHistory, Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// material-ui
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Typography, Tab, Tabs, AppBar, Box, Button, Grid, Dialog } from '@material-ui/core';

import useStyles from './styles';
import { withStyles } from '@material-ui/core';

import General from './General';
import Communities from './Communities';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
            style={{display: 'flex', overflowY: 'scroll'}}
        >
            {value === index && (
                <Box
                    flexDirection='column'
                    display='flex'
                    style={{ flex: 1, overflow: "hidden", overflowY: "scroll", borderRadius: 15}}
                >
                    {children}
                </Box>
            )}
        </div>
    );
}

const StyledTabs = withStyles({
    root: {
        borderBottom: '1px solid #e8e8e8',
        elevation: 0,
    },
    indicator: {
        backgroundColor: '#32e29f',
    },
})(Tabs);
  
const StyledTab = withStyles((theme) => ({
    root: {
        elevation: 0,
        textTransform: 'none',
        minWidth: 72,
        // fontWeight: theme.typography.fontWeightRegular,
        marginRight: theme.spacing(4),
        // fontFamily: [
        //     '-apple-system',
        //     'BlinkMacSystemFont',
        //     '"Segoe UI"',
        //     'Roboto',
        //     '"Helvetica Neue"',
        //     'Arial',
        //     'sans-serif',
        //     '"Apple Color Emoji"',
        //     '"Segoe UI Emoji"',
        //     '"Segoe UI Symbol"',
        // ].join(','),
        '&:hover': {
            color: '#32e29f',
            opacity: 1,
        },
        '&$selected': {
            color: '#32e29f',
            fontWeight: theme.typography.fontWeightMedium,
        },
        '&:focus': {
            color: '#32e29f',
        },
    },
    selected: {},
}))((props) => <Tab disableRipple {...props} />);

const Component = ({ onChangeCommunityClick, communityColor, communityTextColor, userSignedIn, accountInfo, feedback, updateAccountInfo, fetchAccountInfo, signOut, signedIn, clearFeedbackMessage }) => {

    const classes = useStyles({
        backgroundColor: communityColor ? communityColor : '#32E29F',
        textColor: communityTextColor ? communityTextColor : '#000000de',
    });
    let history = useHistory();
    const {t} = useTranslation('common');

    const [tabValue, setTabValue] = useState(0);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    }

    return (
        <>
            { !signedIn && 
                <Redirect to='/' />
            }

            <div className={classes.container}>
                <AppBar className={classes.tabs} position='static'>
                    <StyledTabs 
                        value={tabValue}
                        onChange={handleTabChange}
                        variant='fullWidth'
                    >
                        <StyledTab label={t('profileRoot.general')}/>
                        <StyledTab label={t('profileRoot.myCommunities')}/>
                    </StyledTabs>
                    <TabPanel value={tabValue} index={0}>
                        <General />
                    </TabPanel>
                    <TabPanel value={tabValue} index={1}>
                        <Communities />
                    </TabPanel>
                </AppBar>

            </div>
        </>
    )
}

export default Component;