import React from 'react';
import ReactMarkdown from 'react-markdown';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles, StylesProvider } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import ProgressiveImage from 'react-progressive-image';

const useStyles = makeStyles(theme => ({
    card: {
        // margin: '1em 0',
    },
    cardContent: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
    },
    messageImage: {
        objectFit: 'contain',
        width: '100%',
        marginBottom: 15,
        borderRadius: 10,
    },
    title: {
        fontFamily: 'helvetica-neue-lt-std-65-medium',
        fontSize: 22,
        width: '100%',
        textAlign: 'center',
    },
    text: {
        fontFamily: 'helvetica-neue-lt-std-65-medium',
        fontSize: 16,
    },
    readMore: {
        display: 'flex',
        backgroundColor: 'rgb(244, 251, 247)',
        border: 'none',
        cursor: 'pointer',
        overflow: 'hidden',
        outline: 'none',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        padding: '3% 5%',
        margin: '.5% 0 0 0',
    },
    readMoreText: {
        color: '#33e29f',
        fontFamily: 'helvetica-neue-lt-std-65-medium',
        fontSize: 16,
    },
    readMoreArrow: {
        height: '25px',
        width: '25px',
        fill: '#33e29f',
        transform: 'rotate(-90deg)',
    }
}));

const Component = ({ title, image, message, link }) => {
    const classes = useStyles();

    return (
        <Card elevation={0} className={classes.card}>
            <CardContent className={classes.cardContent}>
                { image && 
                    <ProgressiveImage
                        src={image.thumbnails.large.url}
                        placeholder={image.thumbnails.small.url}
                    >
                        {
                            (src) => <img
                                        className={classes.messageImage}
                                        alt={title}
                                        src={src}
                                        onClick={() => { if (link) window.open(link, "_blank") }} 
                                    />
                        }
                    </ProgressiveImage>
                }
                {title &&
                    <Typography className={classes.title}>{title}</Typography>
                }
                <ReactMarkdown
                    className={classes.text}
                    linkTarget="_blank"
                >
                    {message}
                </ReactMarkdown>
            </CardContent>
        </Card>
    )
}

export default Component;