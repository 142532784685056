import { makeStyles } from '@material-ui/core/styles';
import backgroundURL from '../../images/initialpage_background_gabriel-jimenez-jin4W1HqgL4-unsplash.jpg';

const useStyles = makeStyles(theme => ({
    backDrop: {
        display: 'flex',
        alignItems: 'center',
        backgroundImage: `url(${backgroundURL})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        position: 'fixed',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        height: '100%',
        width: '100%',
        minWidth: '100%',
        zIndex: 2, // cover scanner overlay
    },
    container: {
        zIndex: 4,
        position: 'absolute',
        height: '100%',
        marginLeft: 15,
        marginRight: 15,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
    },
    colorOverlay: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        // backgroundColor: 'rgba(50, 226, 159, 0.8)',
        backgroundColor: 'rgb(50, 226, 159)',
        zIndex: 2,
    },
    betterbinLogo: {
        display: 'block',
        width: '80%',
        right: '0',
        left: '0',
        marginLeft: 'auto',
        marginRight: 'auto',
        zIndex: 4,
    },
    haveAccountText: {
        fontSize: 12,
        marginBottom: 5,
        marginLeft: 10
    },
    input: {
        width: '100%',
        justifyContent: 'space-between',
        backgroundColor: 'rgb(244, 251, 247)',
        zIndex: 5,
        borderRadius: 50,
        padding: 10,
        marginBottom: 10,
    },
    inputText: {
        flex: 4,
        fontSize: '14px',
        backgroundColor: 'Transparent',
        border: '0',
        '&:focus': {
            outline: "none",
        },
        padding: '0 .3em 0 .7em',
        WebkitAppearance: 'none',
        borderRadius: '.3em 0 0 .3em',
        margin: 0,
        minWidth: 0,
        zIndex: 4,
        width: '100%',
    },
    button: {
        fontSize: 16,
        display: 'flex',
        width: '100%',
        borderRadius: 50,
        backgroundColor: '#35353d',
        zIndex: 5,
        marginTop: 10,
        padding: 10,
        textTransform: 'none',
    },
    resetPassword: {
        fontSize: 12,
        width: '100%',
        marginTop: 5,
        textAlign: 'center',
    },
    divider: {
        color: 'black',
        backgroundColor: 'black',
        background: '#35353d',
        marginTop: 15,
        marginBottom: 15,
    },
    feedback: {
        textAlign: 'center',
    },
    languageContainer: {
        color: '#f5fbf9',
        fontFamily: 'helvetica-neue-lt-std-65-medium',
        fontSize: 16,
        width: '100%',
    },
    languageSelect: {
        fontFamily: 'helvetica-neue-lt-std-65-medium',
        fontSize: 16,
        backgroundColor: '#35353d',
        border: 'none',
        cursor: 'pointer',
        overflow: 'hidden',
        outline: 'none',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        textAlign: 'center',
        color: '#f5fbf9',
        borderRadius: 50,
        marginTop: 10,
        padding: 3,
    },
}));

export default useStyles;