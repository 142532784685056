import C from './actionTypes';
import K from '../utils/keys';
import { api, nativeApi, analytics, auth, database } from '../utils/admin';

export const RemoveCommunity = (community) => (dispatch, getState) => {
    console.log('RemoveCommunity()');
    const currentIndex = getState().communities.activeIndex;
    const communities = getState().communities.selectedCommunities;
    const id = community.id;

    if (communities.length > 1) {
        const newActiveIndex = currentIndex > 0 ? (currentIndex - 1) : 0;
        dispatch({
            type: C.SET_ACTIVE_INDEX,
            payload: newActiveIndex,
        });
    
        dispatch({
            type: C.SET_WASTE_TYPE,
            payload: communities[newActiveIndex].waste_type,
        });
    
        const updatedCommunities = communities.filter(function (community) {
            return community.id != id;
        });
    
        dispatch({
            type: C.SET_SELECTED_COMMUNITIES,
            payload: updatedCommunities,
        });

        var user = auth.currentUser;
        if (user != null) {
            if (community.waste_type === 'recycle') {
                console.log('removing recycle DB');
                database.ref('users/' + user.uid + '/accountInformation/recycleCommunities').child(community[K.COMMUNITY.DISPLAY_NAME]).remove();
            }
        
            if (community.waste_type === 'compost') {
                console.log('removing compost DB');
                database.ref('users/' + user.uid + '/accountInformation/compostCommunities').child(community[K.COMMUNITY.DISPLAY_NAME]).remove();
            }
        }
    }
}

export const SetActiveCommunityIndex = (index) => (dispatch, getState) => {
    dispatch({
        type: C.SET_ACTIVE_INDEX,
        payload: index,
    });
}

export const SetLoadingCommunityByQr = (payload) => (dispatch) => {
    // set this to true to skip the community load feedback screen when loading by QR
    console.log('SetLoadingCommunityByQr()');
    dispatch({
        type: C.SET_LOADING_COMMUNITY_BY_QR,
        payload: payload,
    });
}

export const BatchLoadCommunities = (allIds, loadingFromQr, language) => (dispatch, getState) => {
    console.log('BatchLoadCommunities()');

    console.log('allIds:', allIds);
    const ids = allIds.split(',');

    dispatch({
        type: C.SET_FETCHING_COMMUNITY,
        payload: true
    });

    dispatch({
        type: C.SET_STORED_COMMUNITY_IDS,
        payload: "",
    });

    dispatch({
        type: C.SET_COMMUNITY_LOAD_FEEDBACK,
        payload: "",
    });

    if (loadingFromQr) {
        dispatch(SetLoadingCommunityByQr(true));
    }

    const existingCommunities = getState().communities.selectedCommunities;

    // var loadedCommunities = [];
    var loadedString = '';

    nativeApi({
        method: 'POST',
        url: 'select-multiple-communities',
        data: {
            communityIds: ids,
            language: language,
        }
    }).then(response => {
        const communities = response.data;
        console.log('response.data communities', response.data);
        for (var i = 0; i < communities.length; i++) {
            const community = communities[i];

            loadedString += community[K.COMMUNITY.DISPLAY_NAME] + ', ';

            console.log(loadedString);

            var user = auth.currentUser;
            let userEmail = '';
            
            if (user != null) {
                userEmail = user.email;
                analytics.logEvent('set_community', {
                    id: user.uid,
                    community: community[K.COMMUNITY.DISPLAY_NAME],
                    email: userEmail,
                    waste_type: community.waste_type,
                });
                nativeApi({
                    method: 'post',
                    url: 'sendgrid-user-community',
                    data: {
                        community: community[K.COMMUNITY.DISPLAY_NAME],
                        email: userEmail,
                        wasteType: community.waste_type
                    },
                });

                if (community.waste_type === 'recycle') {
                    analytics.setUserProperties('recycle_community',  response.data[K.COMMUNITY.DISPLAY_NAME]);
                    database.ref('users/' + user.uid + '/accountInformation/recycleCommunities').child(community[K.COMMUNITY.DISPLAY_NAME]).set(true);
                } else if (community.waste_type === 'compost') {
                    analytics.setUserProperties('compost_community',  response.data[K.COMMUNITY.DISPLAY_NAME]);
                    database.ref('users/' + user.uid + '/accountInformation/compostCommunities').child(community[K.COMMUNITY.DISPLAY_NAME]).set(true);
                }
            }
        }

        loadedString = loadedString.slice(loadedString.length - 2)

        console.log('pre-filter:', communities.length)
        for (var i = communities.length - 1; i >= 0; i--) {
            const community = communities[i];
            for (var j = 0; j < existingCommunities.length; j++) {
                const existingCommunity = existingCommunities[j];
                if (community.id === existingCommunity.id) {
                    communities.splice(i, 1);
                }
            };
        };
        console.log('post-filter:', communities.length)

        dispatch({
            type: C.SET_SELECTED_COMMUNITIES,
            payload: [...existingCommunities, ...communities],
        });
        dispatch({
            type: C.SET_COMMUNITY_LOAD_FEEDBACK,
            payload: loadedString,
        });
        dispatch({
            type: C.LAUNCH_COMMUNITY_BROWSER,
            payload: false
        });
        dispatch({
            type: C.SET_FETCHING_COMMUNITY,
            payload: false
        });

        if (existingCommunities.length + communities.length - 1 > 1) {
            dispatch({
                type: C.SET_SHOW_CHANGE_COMMUNITY_TIP,
                payload: true,
            });
        }

        // console.log('adding length:', existingCommunities.length + communities.length);
        // console.log('actual length:', [...existingCommunities, ...communities].length);
        // console.log('setting index:', existingCommunities.length + communities.length - 1);
        dispatch({
            type: C.SET_ACTIVE_INDEX,
            payload: existingCommunities.length + communities.length - 1
        });

    }).catch(error => {
        console.log('Error fetching community in batch:', error);
        // if error encountered during QR load then go to normal community setup
        if (loadingFromQr) {
            dispatch(SetLoadingCommunityByQr(false));
        }
        console.log(error);
        dispatch({
            type: C.SET_FETCHING_COMMUNITY,
            payload: false
        });
        dispatch({
            type: C.LAUNCH_COMMUNITY_BROWSER,
            payload: true
        });
        dispatch({
            type: C.SET_COMMUNITY_LOAD_FEEDBACK,
            payload: 'error',
        });
    });
}

export const SetUserCommunity = (id, language) => (dispatch, getState) => {
    dispatch({
        type: C.SET_FETCHING_COMMUNITY,
        payload: true
    });

    const existingCommunities = getState().communities.selectedCommunities;

    var existingIndex = -1;
    for (var i = 0; i < existingCommunities.length; i++) {
        if (existingCommunities[i].id === id) {
            existingIndex = i;
        }
    }

    nativeApi.get(`select-community?id=${id}&language=${language}`).then(response => {
        var user = auth.currentUser;
        let userEmail = '';
        
        const wasteType = response.data.waste_type;
        if (user != null) {
            console.log(user.email);
            userEmail = user.email;
            analytics.logEvent('set_community', {
                id: user.uid,
                community: response.data[K.COMMUNITY.DISPLAY_NAME],
                email: userEmail,
                waste_type: wasteType,
            });
            nativeApi({
                method: 'post',
                url: 'sendgrid-user-community',
                data: {
                    community: response.data[K.COMMUNITY.DISPLAY_NAME],
                    email: userEmail,
                    wasteType: wasteType,
                },
            });
            
            if (wasteType === 'recycle') {
                analytics.setUserProperties('recycle_community',  response.data[K.COMMUNITY.DISPLAY_NAME]);
                database.ref('users/' + user.uid + '/accountInformation/recycleCommunities').child(response.data[K.COMMUNITY.DISPLAY_NAME]).set(true);
            } else if (wasteType === 'compost') {
                analytics.setUserProperties('compost_community',  response.data[K.COMMUNITY.DISPLAY_NAME]);
                database.ref('users/' + user.uid + '/accountInformation/compostCommunities').child(response.data[K.COMMUNITY.DISPLAY_NAME]).set(true);
            }
        }
        console.log(response.data);

        if (wasteType === 'recycle') {
            dispatch({
                type: C.SET_RECYCLE_COMMUNITY,
                payload: response.data,
            });
        } else if (wasteType === 'compost') {
            dispatch({
                type: C.SET_COMPOST_COMMUNITY,
                payload: response.data,
            });
        }

        // update in-place in existing list vs. appending if community is already loaded
        console.log('communities:', existingCommunities);
        if (existingIndex !== -1) {
            console.log('updating in place');
            existingCommunities[existingIndex] = response.data;
            console.log('updated in place:', existingCommunities);
            dispatch({
                type: C.SET_SELECTED_COMMUNITIES,
                payload: existingCommunities,
            });
        } else {
            console.log('communities combined', [...existingCommunities, response.data]);
            dispatch({
                type: C.SET_SELECTED_COMMUNITIES,
                payload: [...existingCommunities, response.data],
            });
        }

        dispatch({
            type: C.SET_WASTE_TYPE,
            payload: wasteType,
        });

        dispatch({
            type: C.SET_USER_COMMUNITY,
            payload: {
                selected: response.data,
                alternate: null,
            },
        });
        dispatch({
            type: C.SET_COMMUNITY_LOAD_FEEDBACK,
            payload: response.data[K.COMMUNITY.DISPLAY_NAME],
        });
        dispatch({
            type: C.SET_FETCHING_COMMUNITY,
            payload: false
        });
        dispatch({
            type: C.SET_STORED_COMMUNITY_IDS,
            payload: "",
        });

        if (existingIndex !== -1) {
            dispatch({
                type: C.SET_ACTIVE_INDEX,
                payload: existingIndex
            });
        } else {
            dispatch({
                type: C.SET_ACTIVE_INDEX,
                payload: existingCommunities.length
            });
        }
    }).catch(error => {
        console.log('ERROR:', error);
        dispatch({
            type: C.SET_FETCHING_COMMUNITY,
            payload: false
        });
        dispatch({
            type: C.LAUNCH_COMMUNITY_BROWSER,
            payload: true
        });
        dispatch({
            type: C.SET_STORED_COMMUNITY_IDS,
            payload: "",
        });
    })
}

export const TranslateCommunities = (selectedLanguage) => (dispatch, getState) => {
    
    const selectedCommunities = getState().communities.selectedCommunities;

    dispatch({
        type: C.SET_TRANSLATING_COMMUNITIES,
        payload: true
    });
    nativeApi({
        method: 'post',
        url: 'translate',
        data: {
            selectedCommunities: selectedCommunities,
            selectedLanguage: selectedLanguage
        },
    }).then(response => {
        dispatch({
            type: C.SET_SELECTED_COMMUNITIES,
            payload: response.data,
        });

        dispatch({
            type: C.SET_USER_LANGUAGE,
            payload: selectedLanguage,
        });
        dispatch({
            type: C.SET_TRANSLATING_COMMUNITIES,
            payload: false
        });
    }).catch(error => {
        console.log(error);
        dispatch({
            type: C.SET_TRANSLATING_COMMUNITIES,
            payload: false
        });
    });
}

export const LaunchCommunityBrowser = (open = true) => (dispatch) => {
    console.log('LaunchCommunityBrowser()');
    console.log('open:', open);
    dispatch({
        type: C.LAUNCH_COMMUNITY_BROWSER,
        payload: open
    });
}

export const ResetCommunityLoadFeedback = () => ({
    type: C.SET_COMMUNITY_LOAD_FEEDBACK,
    payload: "",
});

export const FetchCommunities = (wasteType) => (dispatch) => {
    dispatch({
        type: C.SET_FETCHING_COMMUNITY,
        payload: true,
    });

    nativeApi.get(`/get-communities?waste_type=${wasteType}`).then(response => {
        console.log(response.data);
        dispatch({
            type: C.SET_COMMUNITIES_BY_LOCATION,
            payload: response.data
        })
        dispatch({
            type: C.SET_FETCHING_COMMUNITY,
            payload: false,
        });
    }).catch(error => {
        console.log(error);
        dispatch({
            type: C.SET_FETCHING_COMMUNITY,
            payload: false,
        });
    })
}

export const FetchAllCommunities = () => (dispatch) => {
    console.log('FetchAllCommunities()');
    dispatch({
        type: C.SET_COMMUNITY_LOAD_FEEDBACK,
        payload: "",
    });
    dispatch({
        type: C.SET_FETCHING_COMMUNITY,
        payload: true
    });

    nativeApi.get(`/get-all-communities`).then(response => {
        dispatch({
            type: C.SET_COMMUNITIES_BY_LOCATION,
            payload: response.data
        });
        dispatch({
            type: C.SET_FETCHING_COMMUNITY,
            payload: false
        });
    }).catch(error => {
        console.log(error);
        dispatch({
            type: C.SET_FETCHING_COMMUNITY,
            payload: false
        });
    })
}

export const ClearCommunitiesList = () => (dispatch) => {
    dispatch({
        type: C.SET_COMMUNITIES_BY_LOCATION,
        payload: null,
    });
}

export const SwapWasteType = (selected, alternate) => (dispatch) => {
    var swappedTypes = {
        selected: alternate,
        alternate: selected
    }

    dispatch({
        type: C.SET_USER_COMMUNITY,
        payload: swappedTypes
    })
}

export const FetchAlternateHeaderLogo = () => (dispatch, getState) => {
    console.log('FetchAlternateHeaderLogo()');
    let currentState = getState();
    let community = currentState.communities.selectedCommunities[currentState.communities.activeIndex];
    
    nativeApi.get(`/get-alternate-header-logo?id=${community[K.COMMUNITY.ID]}`).then(response => {
        console.log(community.alternate_header_logo[0]);
        console.log(response.data[0]);
        if ((community.alternate_header_logo) && response.data) {
            if (!(response.data[0].thumbnails.large.url === community.alternate_header_logo[0].thumbnails.large.url)) {
                dispatch({
                    type: C.SET_COMMUNITY_ALTERNATE_HEADER_LOGO,
                    payload: {
                        id: community.id,
                        logo: response.data,
                    }
                });
            }
        } else {
            if (response.data) {
                dispatch({
                    type: C.SET_COMMUNITY_ALTERNATE_HEADER_LOGO,
                    payload: {
                        id: community.id,
                        logo: response.data,
                    }
                });
            } else {
                dispatch({
                    type: C.SET_COMMUNITY_ALTERNATE_HEADER_LOGO,
                    payload: {
                        id: community.id,
                        logo: "",
                    }
                })
            }
        }

    }).catch(error => {
        console.log(error);
    });
}

export const FetchCommunityIcon = () => (dispatch, getState) => {
    console.log('FetchCommunityIcon()');
    let currentState = getState();
    let community = currentState.communities.selectedCommunities[currentState.communities.activeIndex];

    nativeApi.get(`/get-community-icon?id=${community[K.COMMUNITY.ID]}`).then(response => {
        if (!(response.data.thumbnails.large.url === community.icon.thumbnails.large.url)) {
            dispatch({
                type: C.SET_COMMUNITY_ICON,
                payload: {
                    id: community.id,
                    icon: response.data,
                }
            });
        }
    }).catch(error => {
        console.log(error);
    });
}

export const FetchCommunityTips = (language) => (dispatch, getState) => {
    const currentState = getState();
    const community = currentState.communities.selectedCommunities[currentState.communities.activeIndex];
    nativeApi.get(`/get-community-tips?language=${language}&id=${community[K.COMMUNITY.ID]}`).then(response => {
        dispatch({
            type: C.SET_COMMUNITY_TIPS,
            payload: {
                id: community.id,
                tips: response.data,
            }
        });
    }).catch(error => {
        console.log('Error fetching community tips:', error);
    });
}

export const FetchCommunityNews = (language) => (dispatch, getState) => {
    console.log('FetchCommunityNews()');
    const activeIndex = getState().communities.activeIndex;
    const community = getState().communities.selectedCommunities[activeIndex];
    
    nativeApi.get(`/get-community-news?language=${language}&id=${community[K.COMMUNITY.ID]}`).then(response => {
        dispatch({
            type: C.SET_COMMUNITY_NEWS,
            payload: {
                id: community.id,
                news: response.data,
            }
        });
    }).catch(error => {
        console.log('Error fetching community news:', error);
    });
}

export const FetchAlternateTabContent = (language) => (dispatch, getState) => {
    const currentState = getState();
    const activeIndex = currentState.communities.activeIndex;
    const community = currentState.communities.selectedCommunities[activeIndex];

    nativeApi.get(`/get-community-alternate-home-tab?language=${language}&id=${community[K.COMMUNITY.ID]}`).then(response => {
        dispatch({
            type: C.SET_COMMUNITY_ALTERNATE,
            payload: {
                id: community.id,
                alternate: response.data,
            }
        });
    }).catch(error => {
        console.log('Error fetching community alternate tab content:', error);
    });
}

export const FetchCommunityDropOffs = (language) => (dispatch, getState) => {
    console.log('FETCHING COMMUNITY DROPS');
    const currentState = getState();
    const activeIndex = currentState.communities.activeIndex;
    const community = currentState.communities.selectedCommunities[activeIndex];

    nativeApi.get(`/get-community-dropoffs?language=${language}&id=${community[K.COMMUNITY.ID]}`).then(response => {
        dispatch({
            type: C.SET_COMMUNITY_DROPOFFS,
            payload: {
                id: community.id,
                dropOffs: response.data,
            }
        });
    }).catch(error => {
        console.log('Error fetching community drop off locations:', error);
    });
}

export const FetchFaqs = () => (dispatch, getState) => {
    const currentState = getState();
    const language = currentState.inputs.language;
    const activeIndex = currentState.communities.activeIndex;
    const community = currentState.communities.selectedCommunities[activeIndex];
    const wasteType = community.waste_type;
    const communityName = community.display_name;

    console.log('Fetchfaqs');
    nativeApi.get(`/get-faqs?wasteType=${wasteType}&communityName=${communityName}&language=${language}`).then(response => {
        console.log(response.data);
        if (wasteType === 'recycle') {
            dispatch({
                type: C.SET_RECYCLE_FAQS,
                payload: response.data.community.concat(response.data.recycle),
            });
        } else if (wasteType === 'compost') {
            dispatch({
                type: C.SET_COMPOST_FAQS,
                payload: response.data.community.concat(response.data.compost),
            });
        }
    }).catch(error => {
        console.log(error);
    });
};

export const FetchCommunitySponsors = () => (dispatch, getState) => {
    console.log('FetchCommunitySponsors');
    let currentState = getState();
    const activeIndex = currentState.communities.activeIndex;
    const community = currentState.communities.selectedCommunities[activeIndex];
    const wasteType = community.waste_type;
    nativeApi.get(`/get-community-sponsors?id=${community[K.COMMUNITY.ID]}`).then(response => {
        dispatch({
            type: C.SET_COMMUNITY_SPONSORS,
            payload: {
                id: community.id,
                sponsors: response.data,
            }
        });
    }).catch(error => {
        console.log('Error fetching community sponsors:', error);
    });
}

export const FetchCommunityContactEmail = () => (dispatch, getState) => {
    console.log('FetchCommunityContactEmail()');

    let currentState = getState();
    const communityId = currentState.communities.selectedCommunities[currentState.communities.activeIndex].id;

    nativeApi.get(`/get-contact-email?id=${communityId}`).then(response => {
        console.log(response.data);
        dispatch({
            type: C.SET_COMMUNITY_CONTACT_EMAIL,
            payload: {
                email: response.data,
                id: communityId,
            },
        });
    }).catch(error => {
        console.log(error);
    });
}