import { connect } from 'react-redux';

import { FetchAllCommunities, LaunchCommunityBrowser, RemoveCommunity, SetLoadingCommunityByQr } from '../../../actions/communityActions';

import Component from './component';

const mapStateToProps = state => {
    return {
        selectedCommunities: state.communities.selectedCommunities,
        recycleCommunity: state.communities.recycle,
        compostCommunity: state.communities.compost,
        selectedWasteType: state.communities.selectedCommunities[state.communities.activeIndex].waste_type,
        communityColor: state.communities.selectedCommunities[state.communities.activeIndex] ? state.communities.selectedCommunities[state.communities.activeIndex].community_color : "#32E29F",
        communityTextColor: state.communities.selectedCommunities[state.communities.activeIndex] ? state.communities.selectedCommunities[state.communities.activeIndex].community_text_color : "#000000de",
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onChangeCommunityClick: () => {
            dispatch(SetLoadingCommunityByQr(false));
            dispatch(FetchAllCommunities());
            dispatch(LaunchCommunityBrowser());
        },
        removeCommunity: (community) => {
            dispatch(RemoveCommunity(community))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Component);