import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';

// pages
import HomePage from '../../pages/HomePage';
import SearchPage from '../../pages/SearchPage';
import InstructionsPage from '../../pages/InstructionsPage';
import DropOffLocationsPage from '../../pages/DropOffLocationsPage';
import ScanBrowsePage from '../../pages/ScanBrowsePage';
import PhotoPromptPage from '../../pages/PhotoPromptPage';
import ProductFeedbackPage from '../../pages/ProductFeedbackPage';
import PersonalWasteTrackerPage from '../../pages/PersonalWasteTrackerPage';
import LoginPage from '../../pages/LoginPage';
import SignUpPage from '../../pages/SignUpPage';
import PasswordResetPage from '../../pages/PasswordResetPage';
import ProfilePage from '../../pages/ProfilePage';
import RewardsPage from '../../pages/RewardsPage';
import RewardDetailsPage from '../../pages/RewardDetailsPage';
import CommunicationPage from '../../pages/CommunicationPage/CommunicationPage';
import ConversationPage from '../../pages/CommunicationPage/ConversationPage/ConversationPage';
import ProductHistoryPage from '../../pages/ProductHistoryPage/ProductHistoryPage';
import LoadLinksPage from '../../pages/LoadLinksPage/LoadLinksPage';

// components
import CommunityBrowser from '../CommunityBrowser';
import BottomTabs from '../BottomTabs/BottomTabs';
import Header from '../Header';

const useStyles = makeStyles({
    scrollContent: {
        display: 'flex',
        position: 'relative',
        top: '10vh',
        root: {
            width: 500,
        },
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
    },
});

const Routes = ({ signedIn, community, communityBrowserOpen }) => {
    const classes = useStyles();

    return (
        <Router>

            { !signedIn &&
                <Redirect to='/'/>
            }

            {/* { !signedIn &&
                <Route path="/:communityId" children={props => <HomePage {...props} />} />
            } */}

            <div className={classes.container}>
                { signedIn &&
                <>
                    <Header />
                </>
                }

                { signedIn &&
                    <CommunityBrowser />
                }

                <Switch>
                    <Route exact path="/signUp"><SignUpPage/></Route>
                    <Route exact path="/"><LoginPage/></Route>
                    {/* { !signedIn &&
                        <Route path="/:linkParams" children={props => <LoginPage {...props} />} />
                    } */}
                    <Route exact path="/resetPassword"><PasswordResetPage/></Route>
                    <Route exact path="/load/:params" children={props => <LoadLinksPage {...props}/>}/>
                    { signedIn && community &&
                    <>
                        {/* <Route path="/home/:params" children={props => <HomePage {...props} />} />  */}
                        <Route exact path="/home"><HomePage /></Route>
                        <Route exact path="/search"><SearchPage /></Route>
                        <Route exact path="/instructions"> <InstructionsPage /></Route>
                        <Route exact path="/dropOffLocations"> <DropOffLocationsPage /></Route>
                        <Route exact path="/browse"><ScanBrowsePage /></Route>
                        <Route exact path="/photo"><PhotoPromptPage/></Route>
                        <Route exact path="/productFeedback"><ProductFeedbackPage/></Route>
                        <Route exact path="/communication"><CommunicationPage/></Route>
                        <Route exact path="/profile"><ProfilePage/></Route>
                        <Route exact path="/personalWasteTracker"><PersonalWasteTrackerPage/></Route>
                        <Route exact path="/rewards"><RewardsPage/></Route>
                        <Route exact path="/rewardDetails"><RewardDetailsPage/></Route>
                        <Route exact path="/conversation"><ConversationPage /></Route>
                        <Route exact path="/productHistory"><ProductHistoryPage /></Route>
                        {/* <Route path="/:communityId" children={props => <HomePage {...props} />} /> */}
                    </>
                    }
                </Switch>

                { signedIn && !communityBrowserOpen &&
                    <BottomTabs />
                }
            </div>
        </Router >
    )
}

const mapStateToProps = (state) => {
    return {
        signedIn: state.user.signedIn,
        community: state.communities.selectedCommunities[state.communities.activeIndex],
        communityBrowserOpen: state.communities.launchCommunityBrowser,
    }
}

export default connect(mapStateToProps)(Routes);