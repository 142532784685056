import React, { useState, useEffect } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core/styles';
import { GridList, GridListTile, CircularProgress, Typography } from '@material-ui/core';
import { BatchLoadCommunities, SetUserCommunity } from '../../actions/communityActions';
import { StoreCommunityId } from '../../actions/appActions';


const useStyles = makeStyles({
    container: {
        width: '100%',
        bottom: 0,
        left: 0,
        right: 0,
        overflow: 'hidden',
        overflowY: 'scroll',
        marginBottom: '10vh',
        top: '10vh',
        position: 'absolute',
        paddingLeft: 10,
        paddingRight: 10,
        marginTop: 10,
    },
});

const LoadLinksPage = ({ signedIn, setCommunity, batchLoadCommunities, storeCommunityId }) => {
    const history = useHistory()
    const {t} = useTranslation('common');

    const classes = useStyles();

    let { params } = useParams();
    useEffect(() => {
        console.log('params:', params);
        console.log('params split:', params.split(','));
        if (params) {
            if (params.includes('rec')) {
                if (signedIn) {
                    // setCommunity(params);
                    batchLoadCommunities(params);
                    history.push('/home');
                } else {
                    storeCommunityId(params);
                    history.push('/');
                }
            }
        }
    }, []);

    return (
        <div className={classes.container}>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        signedIn: state.user.signedIn,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setCommunity: (id) => {
            dispatch(SetUserCommunity(id));
        },
        storeCommunityId: (id) => {
            dispatch(StoreCommunityId(id));
        },
        batchLoadCommunities: (ids) => {
            dispatch(BatchLoadCommunities(ids, true, 'en'));
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoadLinksPage);