import React, { useEffect, useState } from 'react';
import { useHistory, Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// material-ui
import { Avatar, Typography, Button, Dialog } from '@material-ui/core';
import ProgressiveImage from 'react-progressive-image';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';

import useStyles from './styles';
import { withStyles } from '@material-ui/core';

const ConfirmRemoveCommunity = ({ open, setOpen, communityName, onPressYes, onPressCancel }) => {
    const {t} = useTranslation('common');

    const classes = useStyles();

    return (
        <Dialog
            open={open}
            maxWidth='xl'
            fullWidth={true}
            classes={{paper: classes.root}}
        >
            <div className={classes.dialog}>
                <div className={classes.messageContainer}>
                    <Typography className={classes.message}>
                        {t('profileCommunities.confirmRemovalStart')} {communityName} {t('profileCommunities.confirmRemovalEnd')}
                    </Typography>
                </div>

                <div className={classes.dialogButtonsContainer}>
                    <Button 
                        className={classes.dialogButtonHalf}
                        onClick={onPressCancel}
                    >
                        {t('profileCommunities.cancel')}
                    </Button>
                    <Button 
                        className={classes.dialogButtonHalf}
                        onClick={onPressYes}
                    >
                        {t('profileCommunities.yes')}
                    </Button>
                </div>
            </div>
        </Dialog>
    );
}

const RemoveCommunitiesExplainer = ({ open, setOpen }) => {
    const {t} = useTranslation('common');

    const classes = useStyles();

    const onPressOk = () => {
        setOpen(!open);
    }

    return (
        <Dialog
            open={open}
            maxWidth='xl'
            fullWidth={true}
            classes={{paper: classes.root}}
        >
            <div className={classes.dialog}>
                <div className={classes.messageContainer}>
                    <Typography className={classes.message}>
                        {t('profileCommunities.howToRemoveCommunities')}
                    </Typography>
                </div>

                <div className={classes.dialogButtonsContainer}>
                    <Button 
                        className={classes.dialogButtonFull}
                        onClick={onPressOk}
                    >
                            {t('profileCommunities.ok')}
                    </Button>
                </div>
            </div>
        </Dialog>
    );
}

const CommunityCard = ({ community, onPress }) => {
    const classes = useStyles();
    
    return (
        <div className={classes.community} onClick={onPress}>
            <ProgressiveImage src={community.icon.thumbnails.large.url} placeholder={community.icon.thumbnails.small.url}>
                {(src) => <Avatar className={classes.logo} alt='location icon' src={src}></Avatar>}
            </ProgressiveImage>
            <Typography className={classes.communityText} >{community.display_name}</Typography>
        </div>
    )
}

const NoCommunityCard = () => {
    const classes = useStyles();
    const {t} = useTranslation('common');

    return (
        <div className={classes.community}>
            <div className={classes.noCommunitySpacer}>
                <RemoveCircleOutlineIcon height={60} width={60}/>
            </div>
            <Typography className={classes.communityText} >{t('profileCommunities.noneSelected')}</Typography>
        </div>
    )
}

const Component = ({ removeCommunity, selectedCommunities, communityColor, communityTextColor, onChangeCommunityClick }) => {

    const [removingCommunities, setRemovingCommunities] = useState(false);
    const [showRemovalExplanation, setShowRemovalExplanation] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [selectedCommunityName, setSelectedCommunityName] = useState("");
    const [selectedCommunity, setSelectedCommunity] = useState(null);

    const classes = useStyles({
        backgroundColor: communityColor ? communityColor : '#32E29F',
        textColor: communityTextColor ? communityTextColor : '#000000de',
    });
    let history = useHistory();
    const {t} = useTranslation('common');

    const onPressRemoveCommunities = () => {
        setRemovingCommunities(true);
        setShowRemovalExplanation(true);
    };

    const onPressConfirmRemove = () => {
        removeCommunity(selectedCommunity);
        setShowConfirmation(false);
        setSelectedCommunityName("");
    }

    const onPressCancelRemove = () => {
        setSelectedCommunityName("");
        setShowConfirmation(false);
    }

    const onPressDoneRemoving = () => {
        setRemovingCommunities(false);
    }

    const onPressCommunity = (community) => {
        console.log('pressed:', community.display_name);
        if (removingCommunities) {
            setSelectedCommunity(community);
            setSelectedCommunityName(community.display_name);
            setShowConfirmation(true);
        }
    }

    return (
        <div className={classes.container}>
            <RemoveCommunitiesExplainer 
                open={showRemovalExplanation}
                setOpen={setShowRemovalExplanation}
            />
            <ConfirmRemoveCommunity 
                open={showConfirmation}
                setShow={setShowConfirmation}
                communityName={selectedCommunityName}
                onPressYes={onPressConfirmRemove}
                onPressCancel={onPressCancelRemove}
            />
            <Typography className={classes.label}>{t('profileCommunities.recycle')}</Typography>
            <div className={classes.communityList}>
                { selectedCommunities.map((item, index) => {
                        if (item.waste_type === 'recycle') {
                            return (
                                <CommunityCard community={item} onPress={() => onPressCommunity(item)} />
                            )
                        }
                    })
                }
            </div>

            <Typography className={classes.label}>{t('profileCommunities.compost')}</Typography>
            <div className={classes.communityList}>
                { selectedCommunities.map((item, index) => {
                        if (item.waste_type === 'compost') {
                            return (
                                <CommunityCard community={item} onPress={() => onPressCommunity(item)} />
                            )
                        }
                    })
                }
            </div>

            <div className={classes.buttonsContainer}>
            { removingCommunities ?
                <Button 
                    className={classes.doneRemovingButton} 
                    variant="contained" 
                    disableElevation
                    onClick={onPressDoneRemoving}
                >
                    {t('profileCommunities.doneRemoving')}
                </Button>
            :
            <>
                <Button 
                    className={classes.selectCommunitiesButton} 
                    variant="contained" 
                    disableElevation
                    onClick={onPressRemoveCommunities}
                >
                    {t('profileCommunities.remove')}
                </Button>
                <Button 
                    className={classes.selectCommunitiesButton} 
                    variant="contained" 
                    disableElevation
                    onClick={onChangeCommunityClick}
                >
                    {t('profileCommunities.add')}
                </Button>
            </>
            }
            </div>
        </div>
    )
}

export default Component;