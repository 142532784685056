import React, { useEffect, useState } from 'react';
import { useHistory, Redirect, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import useStyles from './styles';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { FormControl, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core';
import { isMobile } from '../../utils/helperFunctions';

import betterbinLogo from '../../images/BetterBinWordLogo_WHITEandBLUE.png';
import Divider from 'material-ui/Divider';

const StyledSelect = withStyles({
    icon: {
        color: '#f5fbf9',
    }
})(Select);

const Component = ({ storeCommunityId, setLanguage, language, feedback, emailPasswordLogin, signedIn, clearFeedbackMessage }) => {
    const classes = useStyles();
    const history = useHistory();
    const {t, i18n} = useTranslation('common');

    let { linkParams } = useParams();

    useEffect(() => {
        if (linkParams) {
            if (linkParams.includes("rec")) { 
                storeCommunityId(linkParams);
                // store community ID for loading after sign in or account creation

                // setCommunity(communityId, selectedLanguage);
                // history.push('/home');
            }
        }
    }, []);

    const [accountInputs, setAccountInputs] = useState({
        email: "",
        password: "",
    });

    const handleChange = event => {
        setAccountInputs({
            ...accountInputs,
            [event.target.name]: event.target.value
        });
    };

    const handleEmailPasswordSubmit = (event) => {
        event.preventDefault();
        emailPasswordLogin(accountInputs.email, accountInputs.password);
    };

    const handleResetPasswordClick = () => {
        history.push('/resetPassword');
    };

    const handleTranslate = (event) => {
        i18n.changeLanguage(event.target.value);
        setLanguage(event.target.value);
    };

    useEffect(() => {
        clearFeedbackMessage();
    }, [clearFeedbackMessage]);

    useEffect(() => {
        i18n.changeLanguage(language);
    }, [language, i18n])

    return (
        <>
            { signedIn && 
                <Redirect to='/home' />
            }

            <div className={classes.backDrop}>
                <div className={classes.colorOverlay}></div>

                <div className={classes.container}>
                    <div className={classes.top}>
                        <img className={classes.betterbinLogo} src={betterbinLogo} alt="betterbin" />
                    </div>

                    <div className={classes.middle}>
                        <Typography className={classes.haveAccountText}>
                            Already have an account?
                        </Typography>

                        <form onSubmit={handleEmailPasswordSubmit}> 
                            <div className={classes.input}>
                                <input className={classes.inputText} type="text"
                                    label="email"
                                    placeholder={t('users.email')}
                                    value={accountInputs.email}
                                    name="email"
                                    onChange={handleChange}
                                />
                            </div>

                            <div className={classes.input}>
                                <input className={classes.inputText} type="password"
                                    label="password"
                                    placeholder={t('users.password')}
                                    value={accountInputs.password}
                                    name="password"
                                    onChange={handleChange}
                                />
                            </div>
                        
                            <Button 
                                className={classes.button} 
                                variant="contained" 
                                color="primary" 
                                disableElevation
                                type="submit"
                            >
                                {t('users.login')}
                            </Button>
                        </form>

                        <Typography 
                            className={classes.resetPassword}
                            onClick={handleResetPasswordClick}
                        >
                            {t('users.forgotYourPassword')}
                        </Typography>
                    </div>

                    {/* <div className={classes.divider}>
                        <Divider style={{background: 'black'}} variant='middle'/>
                    </div> */}

                    <div className={classes.bottom}>
                        <Button 
                            className={classes.button} 
                            variant="contained" 
                            color="primary" 
                            disableElevation
                            onClick={() => {history.push('/signUp')}}
                        >
                            {t('users.createNewAccount')}
                        </Button>

                        <FormControl className={classes.languageContainer} size={isMobile() ? "small" : ""}>
                            <StyledSelect
                                className={classes.languageSelect}
                                labelId="language-select"
                                id="language-select"
                                value={language}
                                onChange={handleTranslate}
                                variant='outlined'
                            >
                                <MenuItem value={'en'}>{t('settings.english')}</MenuItem>
                                <MenuItem value={'es'}>{t('settings.spanish')}</MenuItem>
                                <MenuItem value={'ja'}>{t('settings.japanese')}</MenuItem>
                                <MenuItem value={'zh'}>{t('settings.chinese')}</MenuItem>
                                <MenuItem value={'ko'}>{t('settings.korean')}</MenuItem>
                                <MenuItem value={'vi'}>{t('settings.vietnamese')}</MenuItem>
                                <MenuItem value={'tl'}>{t('settings.tagalog')}</MenuItem>
                                <MenuItem value={'ar'}>{t('settings.arabic')}</MenuItem>
                                <MenuItem value={'fr'}>{t('settings.french')}</MenuItem>
                                <MenuItem value={'ro'}>{t('settings.romanian')}</MenuItem>
                            </StyledSelect>
                        </FormControl>

                        <div className={classes.feedback}>
                            {feedback}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Component;