import C from './actionTypes';
import { nativeApi, database, firebase } from '../utils/admin';

export const FetchChat = () => (dispatch, getState) => {
    console.log('FetchChat()');
    var user = firebase.auth().currentUser;
    if (user) {
        // check for chat, initialize if non-existent
        var chatRef = database.ref('/users/' + user.uid + '/chat');

        chatRef.on('value', (snapshot) => {
            const chatData = snapshot.val();
            console.log(chatData);
            


            if (chatData) {
                dispatch({
                    type: C.SET_CHAT_CONVERSATIONS,
                    payload: chatData.conversations,
                });
                dispatch({
                    type: C.SET_CHAT_UNREAD_COUNT,
                    payload: chatData.unreadCount,
                });
                dispatch({
                    type: C.SET_CHAT_LANGUAGE,
                    payload: chatData.language
                });
            } else {
                const isChatInitialized = getState().chat.initialized;
                if (!isChatInitialized) {
                    dispatch({
                        type: C.SET_CHAT_INITIALIZED,
                        payload: true,
                    });
                    initializeChat(user);
                }
            }
        });
    }
}

const initializeChat = (user) => {
    console.log('initializeChat');
    // initialize with welcome message
    const timestamp = Date.now();
    const topic = 'Welcome!'

    const message = {
        'unread': true,
        'sender': 'Betterbin',
        'content': `Welcome to the Betterbin message center! Here you’ll find disposal instructions for products you’ve scanned or searched.`
    }

    const conversation = {
        topic: topic,
    }
    conversation[timestamp] = message;

    database.ref('/users/' + user.uid + '/chat/conversations/' + timestamp).update(conversation).then(() => {
        console.log('message sent!')
    }).catch(error => {
        console.log(error.message);
    });

    database.ref('/users/' + user.uid + '/chat/').update({'unreadCount': 1}).then(() => {
        console.log('message sent!')
    }).catch(error => {
        console.log(error.message);
    });

    database.ref('/users/' + user.uid + '/chat/').update({'language': 'en'}).then(() => {
        console.log('message sent!')
    }).catch(error => {
        console.log(error.message);
    });
}

export const TranslateChat = (conversations, language) => (dispatch) => {
    console.log('TranslateChat');

    dispatch({
        type: C.SET_TRANSLATING_CHAT,
        payload: true,
    });

    var user = firebase.auth().currentUser;

    if (user) {
        nativeApi({
            method: 'post',
            url: '/translate-conversations',
            data: {
                conversations: conversations,
                language: language,
            }
        }).then(response => {
            var chatRef = database.ref('/users/' + user.uid + '/chat');
            chatRef.update({'language': language}).then(result => {
                
            }).catch(error => {
                console.log('Error updating readCount');
            });
            chatRef.update({'conversations': response.data}).then(result => {

            }).catch(error => {
                console.log('Error uploading translated conversations');
            });
            dispatch({
                type: C.SET_CHAT_CONVERSATIONS,
                payload: response.data,
            });
            dispatch({
                type: C.SET_CHAT_LANGUAGE,
                payload: language
            });
            dispatch({
                type: C.SET_TRANSLATING_CHAT,
                payload: false,
            });
        }).catch(error => {
            console.log('Error translating messages:', error);
            dispatch({
                type: C.SET_TRANSLATING_CHAT,
                payload: false,
            });
        });
    }
}

export const SelectConversation = (conversation, id) => (dispatch, getState) => {
    var user = firebase.auth().currentUser;
    var unreadCount = getState().chat.unreadCount;

    let readCount = 0;
    for (var message in conversation) {
        console.log('message:', message);
        if (message !== 'topic') {
            if (conversation[message].unread) {
                readCount += 1;
            }
            conversation[message].unread = false;
        }
    }

    // update unread statuses in DB and unreadCount
    var conversationRef = database.ref('/users/' + user.uid + '/chat/conversations/' + id);
    conversationRef.update(conversation).then(snapshot => {
        var unreadCountRef = database.ref('/users/' + user.uid + '/chat');
        const updatedUnreadCount = {
            'unreadCount': Math.max(unreadCount - readCount, 0)
        };
        unreadCountRef.update(updatedUnreadCount).then(snapshot => {
            console.log('Updated unread count');
        }).catch(error => {
            console.log('Error updating unread count:', error);
        });
    }).catch(error => {
        console.log('Error updating conversation:', error);
    });

    dispatch({
        type: C.SELECT_CONVERSATION,
        payload: conversation
    });
}