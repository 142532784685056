import React,  { useEffect } from 'react';
import { connect } from 'react-redux';
import Divider from '@material-ui/core/Divider';
import NewsItem from './NewsItem';
import { makeStyles } from '@material-ui/core';
import { FetchCommunityNews } from '../../../actions/communityActions';

const useStyles = makeStyles({
    newsContainer: {
        backgroundColor: 'white',
        paddingBottom: '12vh',
    },
});

export const Component = ({ language, news, fetchNews }) => {
    const classes = useStyles();

    useEffect(() => {
        fetchNews(language);
    }, [fetchNews, language]);

    return (
        <div className={classes.newsContainer} >
            {news.map((item, i) => (
                <div key={i}>
                    {i > 0 && <Divider light />}
                    <NewsItem 
                        title={item.title}
                        image={item.image}
                        message={item.content}
                        link={item.direct_link}
                    />
                </div>
            ))}
        </div>
    )
}

const mapStateToProps = state => {
    return {
        news: state.communities.selectedCommunities[state.communities.activeIndex] ? state.communities.selectedCommunities[state.communities.activeIndex].local_information : [],
        language: state.inputs.language ? state.inputs.language : 'en',
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchNews: (language) => {
            dispatch(FetchCommunityNews(language));
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Component);