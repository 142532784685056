import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    card: {
        height: 100,
        width: '100%',
        display: 'flex',
        textAlign: 'left',
        justifyContent: 'flex-start',
        alignItems: 'center',
        padding: '10px',
        borderRadius: 15,
    },
    media: {
        height: 80,
        width: 80,
        objectFit: 'contain',
    },
    text: {
        marginLeft: 15,
        fontSize: 16,
    }
});

export default useStyles;