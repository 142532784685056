import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    gridContainer: {
        display: 'grid',
        gridTemplateColumns: props => `repeat(${props.cols}, ${props.columnSize})`,
        gridTemplateRows: props => `repeat(${props.rows}, ${props.rowSize})`,
        gridGap: props => props.gapSize,
        justifyContent: 'stretch',
        margin: 15,
        paddingBottom: '10vh',
    },
    gridItem: {
        justifySelf: 'stretch',
    },
    media: {
        objectFit: 'cover',
        width: '100%',
        height: '100%',
        borderRadius: props => props.radius
    },
    overlay: {
        position: 'absolute',
        display: 'flex',
        width: props => props.columnSize,
        height: props => props.rowSize,
        alignItems: 'center',
        textAlign: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(53,53,61,0.5)',
        borderRadius: props => props.radius,
        fontSize: props => props.fontSize,
    },
    overlayText: {
        display: 'flex',
        color: '#ffffff',
        //text: 'center',
        fontFamily: 'helvetica-neue-lt-std-75-bold !important',
        fontSize: props => props.fontSize,
    }
}));

export default useStyles;
