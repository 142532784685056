import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core/styles';
import { Dialog, DialogTitle, Container } from '@material-ui/core';

import { SetActiveCommunityIndex } from '../../../actions/communityActions';

import K from '../../../utils/keys';

import { Avatar, Typography } from '@material-ui/core';
import ProgressiveImage from 'react-progressive-image';

const useStyles = makeStyles({
    root: {
        borderRadius: 15,
    },
    community: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: '#ffffff',
        borderRadius: 50,
        height: 50,
        marginTop: 10,
        marginBottom: 10,
        borderWidth: 1,
        paddingLeft: 5,
        boxShadow: '0px 2px 4px -1px rgb(0 0 0 / 10%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)'
    },
    communityText: {
        fontSize: 16,
    },
    logo: {
        marginRight: 5,
        objectFit: 'contain',
    },
    communityList: {
        height: 200,
        overflow: 'auto',
        width: '100%',
    },
    title: {
        textAlign: 'center',
    }
});

const CommunityCard = ({ community, setWasteType }) => {
    const classes = useStyles();
    
    return (
        <div className={classes.community} onClick={() => setWasteType(community.waste_type)}>
            <ProgressiveImage src={community.icon.thumbnails.large.url} placeholder={community.icon.thumbnails.small.url}>
                {(src) => <Avatar className={classes.logo} alt='location icon' src={src}></Avatar>}
            </ProgressiveImage>
            <Typography className={classes.communityText} >{community.display_name}</Typography>
        </div>
    )
}

const WasteTypeSelect = ({ open, setOpen, selectedCommunities, selectCommunity }) => {

    const history = useHistory();
    const {t, i18n} = useTranslation('common');
    const classes = useStyles();

    const onPressCommunity = (community) => {
        let index = 0;
        const communityId = community.id;
        for (var i = 0; i < selectedCommunities.length; i++) {
            if (selectedCommunities[i].id === communityId) {
                index = i;
            }
        }
        selectCommunity(index);
        history.push('/home');
        setOpen(false);
    }

    return (
        <Dialog
            open={open}
            className={classes.dialog}
            onClose={() => setOpen(false)}
            maxWidth='lg'
            fullWidth={true}
            classes={{paper: classes.root}}
        >
            <DialogTitle className={classes.title}>{t('wasteTypeSelect.select')}</DialogTitle>
            <Container className={classes.container}>

                <Typography>{t('wasteTypeSelect.recycle')}</Typography>
                <div className={classes.communityList}>
                    { selectedCommunities.map((item, index) => {
                            if (item.waste_type === 'recycle') {
                                return (
                                    <CommunityCard setWasteType={() => onPressCommunity(item)} community={item} />
                                )
                            }
                        })
                    }
                </div>


                <Typography>{t('wasteTypeSelect.compost')}</Typography>
                <div className={classes.communityList}>
                    { selectedCommunities.map((item, index) => {
                            if (item.waste_type === 'compost') {
                                return (
                                    <CommunityCard setWasteType={() => onPressCommunity(item)} community={item} />
                                )
                            }
                        })
                    }
                </div>

            </Container>

        </Dialog>
    );
}

const mapStateToProps = state => {
    return {
        selectedCommunities: state.communities.selectedCommunities,
        community: state.communities.selectedCommunities[state.communities.activeIndex] ? state.communities.selectedCommunities[state.communities.activeIndex][K.COMMUNITY.DISPLAY_NAME] : '',
        wasteType: state.communities.selectedCommunities[state.communities.activeIndex] ? state.communities.selectedCommunities[state.communities.activeIndex]['waste_type'] : null,
        selectedWasteType: state.communities.selectedCommunities[state.communities.activeIndex].waste_type,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        selectCommunity: (index) => {
            dispatch(SetActiveCommunityIndex(index));
        }   
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WasteTypeSelect);