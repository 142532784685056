import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// images
import GoogleTranslate from '../../images/google-translate.png'

// material-ui
import SearchIcon from '@material-ui/icons/Search';
import HistoryIcon from '@material-ui/icons/History';
import { Avatar, Typography } from '@material-ui/core';
import WasteTrackerIcon from '@material-ui/icons/Equalizer';

// styles
import useStyles from './styles';
import RewardsAlert from '../RewardsAlert';

import { ReactComponent as RewardsIcon } from '../../icons/Header/Rewards.svg'
import betterbinLogo from '../../images/BetterBinWordLogo_WHITEandBLUE.png';

import SearchField from '../SearchField';
import WasteTypeSelect from './WasteTypeSelect/WasteTypeSelect';


const Component = ({ showRewardsAlert, wasteTrackerEnabled, recycleCommunity, compostCommunity, fetchUserProductHistory, signedIn, selectedWasteType, rewardsPoints, communityColor, communityTextColor, community, communityExists, language}) => {

    const classes = useStyles({
        backgroundColor: communityColor ? communityColor : '#32E29F',
        textColor: communityTextColor ? communityTextColor : '#000000de',
    });
    const {t} = useTranslation('common');
    let history = useHistory();
    let location = useLocation();

    const [open, setOpen] = useState(false);
    const [openWasteTypeSelect, setOpenWasteTypeSelect] = useState(false);

    const handleWasteTrackerClick = () => {
        if (history.location.pathname !== '/personalWasteTracker' && wasteTrackerEnabled) {
            history.push('/personalWasteTracker');
        }
    }

    const handleRewardsClick = () => {
        if (history.location.pathname !== '/rewards') {
            history.push('/rewards');
        }
    }

    const handleCommunityIconClick = () => {
        // showRewardsAlert();
        setOpenWasteTypeSelect(true);
    }

    const handleProductHistoryClick = () => {
        fetchUserProductHistory();
        history.push('/productHistory')
    }

    return (
        <>
        { signedIn && community &&
        <>
            <RewardsAlert />
            <WasteTypeSelect open={openWasteTypeSelect} setOpen={setOpenWasteTypeSelect} />
            <div id='fixed-header' className={classes.header}>
                <div className={classes.appBar}>
                    <div className={classes.form}>
                        {communityExists &&
                            <div className={classes.communityIconContainer} onClick={handleCommunityIconClick}>
                                <Avatar className={classes.communityLogo} alt={community.display_name} src={community.icon.thumbnails.large.url}></Avatar> 
                            </div>
                        }

                        { location.pathname === '/search' || location.pathname === '/instructions' || location.pathname === '/browse' ?
                            <div className={classes.searchBar}>
                                <SearchIcon className={classes.searchIcon}/>
                                <SearchField />
                                { selectedWasteType === 'recycle' &&
                                <div className={classes.historyButtonContainer}>
                                    <HistoryIcon className={classes.historyIcon} onClick={handleProductHistoryClick}/>
                                </div>
                                }
                            </div>
                        :
                            <img 
                                className={classes.betterbinLogo}
                                onClick={() => history.push('/home')}
                                alt='betterbin' 
                                src={community.alternate_header_logo ? community.alternate_header_logo[0].thumbnails.large.url : betterbinLogo}
                            />
                        }


                        { (selectedWasteType === 'recycle') &&
                            <div className={classes.rewardsButton} onClick={handleRewardsClick}>
                                <RewardsIcon height={30} width={30} />
                                <Typography className={classes.rewardsPoints}>{rewardsPoints}</Typography>
                            </div>
                        }

                        { (selectedWasteType === 'compost') &&
                            <div className={classes.wasteTrackerButton} onClick={handleWasteTrackerClick}>
                                { wasteTrackerEnabled &&
                                    <WasteTrackerIcon className={classes.wasteTrackerIcon} />
                                }
                            </div>
                        }
                    </div>
                </div >
                { language !== 'en' && language !== null &&
                    <img className={classes.googleAttribution} align='right' alt="" src={GoogleTranslate}></img>
                }
            </div>
        </>
        }
        </>
    );
};

export default Component;