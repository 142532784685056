import React,  { useEffect } from 'react';
import { connect } from 'react-redux';

// material UI
import Divider from '@material-ui/core/Divider';
import DropOffLocation from './DropOffLocation';
import { makeStyles } from '@material-ui/core';
import { FetchCommunityDropOffs } from '../../actions/communityActions';

const useStyles = makeStyles({
    dropOffLocationContainer: {
        position: 'absolute',
        top: '10vh',
        backgroundColor: 'white',
        overflow: "hidden",
        overflowY: "scroll",
        flex: 1,
        bottom: 56,
    },
})

const Component = ({ language, dropOffLocations, fetchDropOffLocations }) => {
    const classes = useStyles();

    useEffect(() => {
        fetchDropOffLocations(language);
    }, [fetchDropOffLocations, language]);

    return (
        <div className={classes.dropOffLocationContainer}>
            {dropOffLocations.map((item, i) => (
                <div key={i}>
                    {i > 0 && <Divider light />}
                    <DropOffLocation 
                        title={item.address_line_1}
                        link={item.google_maps_url}
                        hours={item.hours}
                        image={item.image}
                        description={item.drop_off_description}
                    />
                </div>
            ))}
        </div>
    )
}

const mapStateToProps = state => {
    return {
        dropOffLocations: state.communities.selectedCommunities[state.communities.activeIndex] && state.communities.selectedCommunities[state.communities.activeIndex].drop_off_locations ?
            state.communities.selectedCommunities[state.communities.activeIndex].drop_off_locations
            : [],
        language: state.inputs.language ? state.inputs.language : 'en',
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchDropOffLocations: (language) => {
            dispatch(FetchCommunityDropOffs(language));
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Component);