import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    container: {
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        padding: 10,
    },
    label: {
        display: 'flex',
        justifyContent: 'left',
        marginTop: 10,
    },
    communityRow: {
        display: 'flex',
        flexDirection: 'row',
    },
    content: {
        fontFamily: 'helvetica-neue-lt-std-65-medium',
        fontSize: 16,
    },
    buttonsContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
    },
    selectCommunitiesButton: {
        fontFamily: 'helvetica-neue-lt-std-65-medium',
        fontSize: 16,
        textTransform: 'none',
        display: 'flex',
        width: '48%',
        borderRadius: 50,
        color: (props) => props.textColor,
        backgroundColor: (props) => props.backgroundColor,
        marginTop: 10,
        padding: '3% 0',
    },
    doneRemovingButton: {
        fontFamily: 'helvetica-neue-lt-std-65-medium',
        fontSize: 16,
        textTransform: 'none',
        display: 'flex',
        width: '100%',
        borderRadius: 50,
        color: (props) => props.textColor,
        backgroundColor: (props) => props.backgroundColor,
        marginTop: 10,
        padding: '3% 0',
    },
    community: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: '#ffffff',
        borderRadius: 50,
        paddingLeft: 5,
        height: 50,
        marginTop: 10,
        marginBottom: 10,
        boxShadow: '0px 2px 4px -1px rgb(0 0 0 / 10%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
    },
    communityText: {
        fontSize: 16,
    },
    noCommunitySpacer: {
        marginRight: 20,
        marginLeft: 10,
    },
    logo: {
        margin: '0 1em 0 0',
        objectFit: 'contain',
    },
    communityList: {
        height: 200,
        overflow: 'auto',
        width: '100%',
    },
    root: {
        borderRadius: 15,
    },
    dialog: {
        borderRadius: 15,
        margin: 15,
    },
    messageContainer: {
        margin: 15,
        textAlign: 'center',
    },
    dialogButtonsContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
    },
    dialogButtonHalf: {
        fontFamily: 'helvetica-neue-lt-std-65-medium',
        fontSize: 16,
        textTransform: 'none',
        display: 'flex',
        width: '48%',
        borderRadius: 50,
        color: 'white',
        backgroundColor: 'black',
        marginTop: 10,
        padding: '3% 0',
    },
    dialogButtonFull: {
        fontFamily: 'helvetica-neue-lt-std-65-medium',
        fontSize: 16,
        textTransform: 'none',
        display: 'flex',
        width: '100%',
        borderRadius: 50,
        color: 'white',
        backgroundColor: 'black',
        marginTop: 10,
        padding: '3% 0',
    },
}));

export default useStyles;