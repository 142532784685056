import appReducer from './reducers'
import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import defaultState from './defaultState';
import K from '../utils/keys';
//const debugState = require('./debugStates/infiniteFetching.json');


export default () => {
    const localState = localStorage['redux-store'] ? JSON.parse(localStorage['redux-store']) : null;
    const state = localState && localState.version && localState.version === K.VERSION ? localState : defaultState;
    
    // if localState.version
    
    state.inputs.fetchingCommunity = false;
    const store = applyMiddleware(thunk)(createStore)(appReducer, state);
    
    return store;
}