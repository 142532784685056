import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Carousel from 'react-material-ui-carousel';
import ReactMarkdown from 'react-markdown';

// material UI
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Skeleton from '@material-ui/lab/Skeleton';
import HelpOutline from '@material-ui/icons/HelpOutline';

// components
import ClassificationButton from '../../components/ClassificationButton';
import SponsorItem from '../../components/SponsorItem';

// styles
import useStyles from './styles';

// keys constants
import K from '../../utils/keys';
import { useHistory } from 'react-router-dom';

const Component = ({ community, selectedProduct, wasteType }) => {
    const {t} = useTranslation('common');
    const history = useHistory();
    let props = { fit: wasteType === 'recycle' ? 'contain' : 'cover' }
    const classes = useStyles(props);

    const handleSubmitClick = () => {
        history.push('/photo');
    }

    const showModelWarning = () => {
        console.log('showModelWarning')
        if (selectedProduct.modelOutput) {
            if (selectedProduct.modelOutput.trust === 'warn') {
                return true;
            } else {
                return false;
            }
        }
        return false;
    }

    const handleProductFeedbackClick = () => {
        history.push('/productFeedback');
    }

    useEffect(() => {
        if (selectedProduct) {
            if ('modelOutput' in selectedProduct) {
                if (selectedProduct.modelOutput.trust === 'skip' || selectedProduct.modelOutput.trust === 'warn') {
                    history.push('/photo');
                } 
            }
        }
    }, [selectedProduct]);

    return (
        <div className={classes.container}>
            <div className={classes.card}>
                <HelpOutline 
                    fontSize='large'
                    style={{ alignSelf: 'flex-end', position: 'absolute' }}
                    onClick={handleProductFeedbackClick}
                />
                {selectedProduct ?
                    <>
                        <div className={classes.mediaWrapper}>
                            <img className={classes.media}
                                alt={selectedProduct[K.PRODUCT.DISPLAY_NAME]}
                                src={selectedProduct[K.PRODUCT.IMAGE_URL]}
                            />
                        </div>
                        <div>
                            <Typography 
                                className={classes.title}
                                variant="h6"
                            >
                                {selectedProduct[K.PRODUCT.DISPLAY_NAME]}
                            </Typography>

                            <ClassificationButton 
                                className={classes.classificationButton}
                                classification={selectedProduct[K.PRODUCT.CLASSIFICATION]}
                            />

                            <ReactMarkdown
                                className={classes.text}
                                linkTarget="_blank"
                            >
                                {selectedProduct[K.PRODUCT.INSTRUCTIONS]}
                            </ReactMarkdown>

                            { showModelWarning() &&
                                <div className={classes.predictionNotice}>
                                    <Typography 
                                        className={classes.predictionText}
                                    >
                                        {t('instructions.lowConfidenceWarning')}
                                    </Typography>
                                    <Button 
                                        variant='outlined'
                                        className={classes.submitButton}
                                        onClick={handleSubmitClick}
                                    >
                                        {t('instructions.requestConfirmation')}
                                    </Button>
                                </div>
                            }
                        </div>
                    </>
                    :
                    <div>
                        <Skeleton variant="rect" className={classes.media} />
                        <Skeleton />
                        <Skeleton animation={false} />
                        <Skeleton animation="wave" />
                    </div>
                }

                { community.sponsors.length !== 0 &&
                    <Carousel animation='slide' indicators={false}>
                        { community.sponsors.map((item, i) => (
                            <div key={i} className={classes.sponsor}>
                                <SponsorItem sponsor={item} page="product" />
                            </div>
                        ))}
                    </Carousel>
                }
            </div>

        </div>
    )
}

export default Component;