import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import Quagga from '@ericblade/quagga2'; // switching to quagga2 due for Google Pixel compatibility
import config from "./config.json";

import { Button, } from '@material-ui/core';
import CachedIcon from '@material-ui/icons/Cached';

import scanIcon from '../../../images/noun_Barcode_619698.svg';
import './styles.css';

const Component = ({ onScan, language, cameraDeviceId, setCameraDeviceId }) => {
    const history = useHistory();

    const detected = result => {
        if (history.location.pathname === '/browse') {
            onDetected(result.codeResult.code);
        }
    };

    const onDetected = result => {
        console.log('quagga onDetected');
        Quagga.stop();
        onScan(result, language);
        history.push('/search');
    };

    const getMedian = (values) => {
        const sorted = [...values].sort((a, b) => a - b);
        const half = Math.floor(sorted.length / 2);
        if (values.length % 2 === 1) {
            return values[half];
        }
        return (values[half - 1] + values[half]) / 2;
    }

    const [devices, setDevices] = useState([]);

    const useNextDevice = () => {
        Quagga.stop();
        const nextCameraId = (cameraDeviceId + 1) % devices.length;
        setCameraDeviceId(nextCameraId);
        config.inputStream.constraints.deviceId = devices[nextCameraId].deviceId;
        Quagga.init(config, err => {
            if (err) {
                console.log(err, "error msg");
            }
            Quagga.start();
        });
    }

    useEffect(() => {
        Quagga.CameraAccess.request(null, {}).then(function() {
            Quagga.CameraAccess.release().then(function() {
                Quagga.CameraAccess.enumerateVideoDevices().then(function (devices) {
                    setDevices(devices);
                    config.inputStream.constraints.deviceId = devices[cameraDeviceId].deviceId;
                    Quagga.init(config, err => {
                        if (err) {
                            console.log(err, "error msg");
                        }
                        Quagga.start();
                    });
                });
          
            })
        });

        // use array to wait for 3 matching codes to reduce erroneous scans
        let codes = [];
        Quagga.onDetected(result => {
            if (result.codeResult.format === 'upc_a') {
                // get the error values from the scanner result
                var rawErrors = result.codeResult.decodedCodes.filter((x) => x.error !== undefined).map((y) => y.error);
                
                // get the median of the errors
                const median = getMedian(rawErrors);
                console.log(rawErrors);
                console.log(result.codeResult);
                
                // skip if median error or any individual error is too high
                if (median > .2 || rawErrors.some((err => err > .2))) return;
                else {
                    // wait until we get three matching barcodes
                    if (codes.length < 3) {
                        codes.push(result.codeResult.code);
                    } else {
                        if (codes.every(v => v === codes[0])) {
                            detected(result);
                        } else {
                            codes.shift();
                        }
                    }
                }
            } else if (result.codeResult.format === 'ean_8') {
                // same idea as above but for 8 digit barcodes
                var rawErrors = result.codeResult.decodedCodes.filter((x) => x.error !== undefined).map((y) => y.error);
                const median = getMedian(rawErrors);
                console.log(rawErrors);
                console.log(result.codeResult);

                if (median > .11 || rawErrors.some((err => err > .2))) return;
                else {
                    if (codes.length < 3) {
                        codes.push(result.codeResult.code);
                    } else {
                        if (codes.every(v => v === codes[0])) {
                            detected(result);
                        } else {
                            codes.shift();
                        }
                    }
                }
            }
        });

        // cleanup
        return () => {
            Quagga.stop()
        }
    }, []);

    return (
        <>
            <Button 
                style={{
                    backgroundColor: '#353535e6',
                    position: 'absolute',
                    zIndex: 50,
                    height: '5%',
                    width: '50%',
                    marginTop: 10,
                    marginLeft: '48vw',
                    borderRadius: 50,
                    color: 'white',
                    textTransform: 'none',
                }}
                onClick={useNextDevice}
            >
                <CachedIcon style={{marginRight: 10}}/>
                Change camera
            </Button>
            <div id="interactive" className="viewport">
                {/* <button style={{zIndex: 50, height: '10%', width: '40%', marginTop: '20vh'}} onClick={useNextDevice}>Change lens</button> */}
                <div id="barcode-container">
                    <img className="overlay" src={scanIcon} alt="barcode" />
                </div>
            </div>
        </>
    )
}

export default Component;