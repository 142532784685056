import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// material UI
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import AddAPhoto from '@material-ui/icons/AddAPhoto';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { FormControl } from '@material-ui/core';
import { withStyles } from '@material-ui/core';

// styles
import useStyles from './styles';

const StyledSelect = withStyles({
    icon: {
        color: '#000000de',
    }
})(Select);

const Component = ({ selectedProduct, onSubmitProductFeedback, searchValue, wasteType }) => {
    const classes = useStyles();
    const history = useHistory();
    const {t} = useTranslation('common');
    
    const [photo, setPhoto] = useState({
        photo: null,
        tempURL: ""
    });

    const [category, setCategory] = useState('');
    const handleChangeCategory = (event) => {
        setCategory(event.target.value);
    }

    const [message, setMessage] = useState('');
    const handleChangeMessage = (event) => {
        setMessage(event.target.value);
    } 

    const handleUserPhoto = (event) => {
        setPhoto({
            photo: event.target.files[0],
            tempURL: URL.createObjectURL(event.target.files[0])
        });
    }

    const handleSubmission = () => {
        onSubmitProductFeedback(selectedProduct, category, photo.photo, message, wasteType, searchValue);
        history.goBack();
    }

    return (
        <>
        <div className={classes.container}>
            <div className={classes.card}>
                <div className={classes.requestPrompt}>
                    {t('productFeedback.promptText')}
                </div>

                <FormControl className={classes.categoryContainer} size='small'>
                    <StyledSelect
                        className={classes.categorySelect}
                        labelId="category-select"
                        id="category-select"
                        value={category}
                        onChange={handleChangeCategory}
                        variant='outlined'
                        displayEmpty
                    >
                        <MenuItem disabled value=''>
                            {t('productFeedback.selectCategory')}
                        </MenuItem>
                        <MenuItem value={"Something's not right"}>
                            {t('productFeedback.instructionsCategory')}
                        </MenuItem>
                        <MenuItem value={"Not my product"}>
                            {t('productFeedback.productCategory')}
                        </MenuItem>
                        <MenuItem value={"Other"}>
                            {t('productFeedback.otherCategory')}
                        </MenuItem>
                    </StyledSelect>
                </FormControl>

                <Typography className={classes.fieldLabel}>
                    {t('productFeedback.message')}
                </Typography>
                <form className={classes.messageField}>
                    <textarea className={classes.messageFieldText}
                        type="text"
                        label="message"
                        placeholder={t('productFeedback.messagePlaceholder')}
                        value={message}
                        name="name"
                        onChange={handleChangeMessage}
                        rows={5}
                    />
                </form>

                <div className={classes.photoPreview}>
                    <img className={classes.photo} src={photo.tempURL} alt=''/>
                </div>

                <Button 
                    component='label'
                    variant="contained"
                    className={classes.photoButton}
                    startIcon={<AddAPhoto/>}
                >
                    {t('productFeedback.takePhoto')}
                    <input
                        onChange={handleUserPhoto}
                        type="file"
                        accept="image/*"
                        capture="environment"
                        hidden
                    />
                </Button>
                
                {photo.photo !== null &&
                    <Button
                        variant="contained"
                        className={classes.photoButton}
                        onClick={handleSubmission}
                    >
                        {t('productFeedback.submit')}
                    </Button>
                }

            </div>
        </div>
        </>
    );
}

export default Component;
