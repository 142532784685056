import { makeStyles } from '@material-ui/core/styles';
import backgroundURL from '../../images/initialpage_background_gabriel-jimenez-jin4W1HqgL4-unsplash.jpg';

const useStyles = makeStyles(theme => ({
    backDrop: {
        display: 'flex',
        alignItems: 'center',
        backgroundImage: `url(${backgroundURL})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        position: 'fixed',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        height: '100%',
        width: '100%',
        minWidth: '100%',
        zIndex: 2, // cover scanner overlay
    },
    container: {
        zIndex: 4,
        position: 'absolute',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        marginLeft: 15,
        marginRight: 15,
    },
    checkboxes: {
        marginBottom: 15,
    },
    checkbox: {
        margin: '3% 0 3% 0',
    },
    colorOverlay: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        // backgroundColor: 'rgba(50, 226, 159, 0.8)',
        backgroundColor: 'rgb(50, 226, 159)',
        zIndex: 2,
    },
    betterbinLogo: {
        width: '65%',
        top: '20px',
        right: '0',
        left: '0',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginBottom: '15%',
        zIndex: 4,
        display: 'block'
    },
    closeButton: {
        position: 'absolute',
        top: 3,
        right: 3,
        zIndex: 5,
    },
    input: {
        width: '100%',
        justifyContent: 'space-between',
        borderRadius: 50,
        backgroundColor: 'rgb(244, 251, 247)',
        zIndex: 5,
        padding: 10,
        marginBottom: 10,
    },
    inputText: {
        flex: 4,
        fontSize: 16,
        backgroundColor: 'Transparent',
        border: '0',
        '&:focus': {
            outline: "none",
        },
        padding: '0 .3em 0 .7em',
        WebkitAppearance: 'none',
        borderRadius: '.3em 0 0 .3em',
        margin: 0,
        minWidth: 0,
        zIndex: 4,
        width: '100%',
    },
    button: {
        fontFamily: 'helvetica-neue-lt-std-65-medium',
        fontSize: 16,
        textTransform: 'none',
        display: 'flex',
        width: '100%',
        borderRadius: 50,
        backgroundColor: '#35353d',
        zIndex: 5,
        marginTop: 10,
        padding: 10,
    },
    termsAndConditions: {
        textAlign: 'center',
    },
    feedback: {
        textAlign: 'center',
    }
}));

export default useStyles;