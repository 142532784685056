import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

// material UI
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import { Divider, withStyles } from '@material-ui/core';

// components
import { ReactComponent as MoreDetailsIcon } from '../../images/noun_down_1684507.svg'
import { ReactComponent as VehicleEquivalencyIcon } from '../../images/car-waste-tracker.svg';
import { ReactComponent as HomeEquivalencyIcon } from '../../images/home-energy-waste-tracker.svg';

// styles
import useStyles from './styles';

const StyledSelect = withStyles({
    icon: {
        color: '#f5fbf9',
    }
})(Select);

const StyledTableHead = withStyles({
    root: {
        borderBottom: '2px solid black',
    }
})(TableHead);

const StyledTableFoot = withStyles({
    root: {
        borderTop: '2px solid black',
        borderBottom: 'none',
    }
})(TableHead);

const StyledTableFootCell = withStyles({
    root: {
        borderBottom: 'none',
        paddingTop: '1%',
    }
})(TableCell);

const NewEarthFarmMenuItems = ({addWasteValue, handleWasteAmountChange}) => {
    const {t} = useTranslation('common');
    const classes = useStyles();
    return (
        <StyledSelect
        className={classes.wasteSelect}
        labelId="add-compost"
        id="add-compost"
        value={addWasteValue}
        onChange={handleWasteAmountChange}
        variant='outlined'
        >
            <MenuItem value={0}>{t('personalWasteTracker.weightSelect')}</MenuItem>
            <MenuItem value={0.2}>1/4 {t('personalWasteTracker.bucket')} - 4 {t('personalWasteTracker.gallon')}</MenuItem>
            <MenuItem value={0.4}>1/2 {t('personalWasteTracker.bucket')} - 4 {t('personalWasteTracker.gallon')}</MenuItem>
            <MenuItem value={0.6}>3/4 {t('personalWasteTracker.bucket')} - 4 {t('personalWasteTracker.gallon')}</MenuItem>
            <MenuItem value={0.8}>1 {t('personalWasteTracker.bucket')} - 4 {t('personalWasteTracker.gallon')}</MenuItem>
            <Divider/>
            <MenuItem value={0.25}>1/4 {t('personalWasteTracker.bucket')} - 5 {t('personalWasteTracker.gallon')}</MenuItem>
            <MenuItem value={0.50}>1/2 {t('personalWasteTracker.bucket')} - 5 {t('personalWasteTracker.gallon')}</MenuItem>
            <MenuItem value={0.75}>3/4 {t('personalWasteTracker.bucket')} - 5 {t('personalWasteTracker.gallon')}</MenuItem>
            <MenuItem value={1.0}>1 {t('personalWasteTracker.bucket')} - 5 {t('personalWasteTracker.gallon')}</MenuItem>
        </StyledSelect>
    )
}

const DefaultMenuItems = ({addWasteValue, handleWasteAmountChange}) => {
    const {t} = useTranslation('common');
    const classes = useStyles();
    return (
        <StyledSelect
        className={classes.wasteSelect}
        labelId="add-compost"
        id="add-compost"
        value={addWasteValue}
        onChange={handleWasteAmountChange}
        variant='outlined'
        >
            <MenuItem value={0}>{t('personalWasteTracker.weightSelect')}</MenuItem>
            <MenuItem value={0.25}>1/4 {t('personalWasteTracker.bucket')} - 5 {t('personalWasteTracker.gallon')}</MenuItem>
            <MenuItem value={0.50}>1/2 {t('personalWasteTracker.bucket')} - 5 {t('personalWasteTracker.gallon')}</MenuItem>
            <MenuItem value={0.75}>3/4 {t('personalWasteTracker.bucket')} - 5 {t('personalWasteTracker.gallon')}</MenuItem>
            <MenuItem value={1.0}>1 {t('personalWasteTracker.bucket')} - 5 {t('personalWasteTracker.gallon')}</MenuItem>
            <Divider/>
            <MenuItem value={0.1}>1/4 {t('personalWasteTracker.bucket')} - 2 {t('personalWasteTracker.gallon')}</MenuItem>
            <MenuItem value={0.2}>1/2 {t('personalWasteTracker.bucket')} - 2 {t('personalWasteTracker.gallon')}</MenuItem>
            <MenuItem value={0.3}>3/4 {t('personalWasteTracker.bucket')} - 2 {t('personalWasteTracker.gallon')}</MenuItem>
            <MenuItem value={0.4}>1 {t('personalWasteTracker.bucket')} - 2 {t('personalWasteTracker.gallon')}</MenuItem>
        </StyledSelect>
    )
}

const KeeneMenuItems = ({addWasteValue, handleWasteAmountChange}) => {
    const {t} = useTranslation('common');
    const classes = useStyles();
    const weights = [];
    weights.push( <MenuItem value={0}>{t('personalWasteTracker.weightSelect')}</MenuItem> );
    for (var i = .5; i <= 11; i+=.5) {
        weights.push(< MenuItem value={i}> {i} lbs</MenuItem> )
    }
    return (
        <StyledSelect
        className={classes.wasteSelect}
        labelId="add-compost"
        id="add-compost"
        value={addWasteValue}
        onChange={handleWasteAmountChange}
        variant='outlined'
        >
            {weights}
        </StyledSelect>
    )
}

const Component = ({ signedIn, userSignedIn, getUserWasteData, addUserWasteData, resetUserWasteDay, userWasteData, compostBucketWeight, community }) => {
    const classes = useStyles();
    const {t} = useTranslation('common');
    let history = useHistory();

    const [addWasteValue, setAddWasteValue] = useState(0)

    useEffect(() => {
        getUserWasteData(compostBucketWeight);
    }, []);

    useEffect(() => {
        userSignedIn();
        if (!signedIn) {
            history.push('/login');
        }
    }, [signedIn]);

    const handleWasteAmountChange = (event) => {
        setAddWasteValue(event.target.value);
    };

    const handleAddWasteData = () => {
        if (addWasteValue !== 0) {
            addUserWasteData(addWasteValue, compostBucketWeight);
        }
    };

    const handleResetWasteData = () => {
        resetUserWasteDay(compostBucketWeight);
    };

    const communityWasteOptions = () => {
        if (community === 'Keene - NRRA Pilot') {
            return (
                <KeeneMenuItems handleWasteAmountChange={handleWasteAmountChange} addWasteValue={addWasteValue}/>
            )
        } else if (community === 'New Earth Farm') {
            return (
                <NewEarthFarmMenuItems handleWasteAmountChange={handleWasteAmountChange} addWasteValue={addWasteValue}/>
            )
        } else {
            return (
                <DefaultMenuItems handleWasteAmountChange={handleWasteAmountChange} addWasteValue={addWasteValue}/>
            )
        }
    };

    return (
        <div className={classes.container}>
            <div className={classes.subHeader}>{t('personalWasteTracker.myWasteTracker')}</div>
            {communityWasteOptions()}
            {/* {community === 'Keene - NRRA Pilot'
                    ? <KeeneMenuItems handleWasteAmountChange={handleWasteAmountChange} addWasteValue={addWasteValue}/>
                    : <DefaultMenuItems handleWasteAmountChange={handleWasteAmountChange} addWasteValue={addWasteValue}/>
            } */}
            <div className={classes.buttonContainer}>
                <Button className={classes.clearButton} onClick={handleResetWasteData} variant="outlined" color="primary" disableElevation>{t('personalWasteTracker.clearEntry')}</Button>
                <Button className={classes.addButton} onClick={handleAddWasteData} variant="contained" color="primary" disableElevation>{t('personalWasteTracker.addAmount')}</Button>
            </div>
            <div className={classes.subHeader}>{t('personalWasteTracker.wasteDiverted')}</div>
            {userWasteData.entries.length ?
                <>
                    <Table className={classes.table}>
                        <StyledTableHead>
                            <TableCell width='20%'align='left' className={classes.tableHeaderCell}>{t('personalWasteTracker.date')}</TableCell>
                            <TableCell width='30%'className={classes.tableHeaderCell} align='center'>{t('personalWasteTracker.amount')}</TableCell>
                            <TableCell width='50%' className={classes.tableHeaderCell} align='right'>{t('personalWasteTracker.emissionsMetricTons')}</TableCell>
                        </StyledTableHead>
                        <Divider/>
                        <TableBody>
                        <>
                            {userWasteData.entries.map((entry) => (
                                <TableRow key={entry.name}>
                                    <TableCell className={classes.tableCell} align="left">{entry.date}</TableCell>
                                    <TableCell className={classes.tableCell} align="center">{entry.weight.toFixed(2)} lbs</TableCell>  {/*{entry.weight > 1.0 ? 'Buckets' : 'Bucket'}*/}
                                    <TableCell className={classes.tableCell} align="right">{entry.emissions.toFixed(4)}</TableCell>
                                </TableRow>
                            ))}
                        </>
                        </TableBody>
                    </Table>
                    <Table style={{marginBottom: 15}}>
                        <StyledTableFoot>
                            <StyledTableFootCell width='70%' colspan={2} className={classes.tableHeaderCell}>{t('personalWasteTracker.totalEmissions')}</StyledTableFootCell>
                            <StyledTableFootCell width='30%' className={classes.tableHeaderCell} align="right">{userWasteData.totalEmissionsReduced.toFixed(3)}</StyledTableFootCell>
                        </StyledTableFoot>
                    </Table>

                    <div className={classes.subHeader}>{t('personalWasteTracker.equivalentOf')}</div>
                    <div className={classes.equivalencyBox}>
                        <VehicleEquivalencyIcon className={classes.carIcon} />
                        <Typography className={classes.equivalencyValue}>{userWasteData.milesReduced.toFixed(1)}</Typography>
                        <Typography className={classes.equivalencyDescription}>{t('personalWasteTracker.milesDriven')}</Typography>
                    </div>

                    <div className={classes.equivalencyBox}>
                        <HomeEquivalencyIcon className={classes.homeIcon} />
                        <Typography className={classes.equivalencyValue}>{userWasteData.homeEnergyReduced.toFixed(3)}</Typography>
                        <Typography className={classes.equivalencyDescription}>{t('personalWasteTracker.homeEnergy')}</Typography>
                    </div>
                </>
            : 
                <div className={classes.content}>
                    <Typography className={classes.content} align='center' variant='body1'>{t('personalWasteTracker.noData')}</Typography>
                </div>
            }

            <div className={classes.subHeader}>{t('personalWasteTracker.moreInfo')}</div>
            <div className={classes.content}>
                <Typography variant="body1">{t('personalWasteTracker.warmDescription')}</Typography>
            </div>
            <button className={classes.readMore} onClick={() => window.open('https://www.epa.gov/warm/basic-information-about-waste-reduction-model-warm', "_blank")} >
                <Typography className={classes.readMoreText} variant='body1'>{t('personalWasteTracker.learnMore')}</Typography>
                <MoreDetailsIcon className={classes.readMoreArrow} />
            </button>
        </div>
    )
}

export default Component;