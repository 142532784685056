import React from 'react';
import { useTranslation } from 'react-i18next';
import ProgressiveImage from 'react-progressive-image';
import ReactMarkdown from 'react-markdown';

import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

const useStyles = makeStyles(theme => ({
    map: {
        minWidth: '100%',
        margin: '1em auto',
        border: 0,
    },
    cardContent: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
    },
    messageImage: {
        objectFit: 'contain',
        width: '100%',
        borderRadius: 10,
    },
    header: {
        color: 'black',
        fontFamily: 'helvetica-neue-lt-std-65-medium',
        marginTop: 10,
        fontSize: 18,
    },
    text: {
        color: 'black',
        fontFamily: 'helvetica-neue-lt-std-65-medium',
        fontSize: 16,
        marginTop: 10,
    }
}));

const Component = ({ title, link, hours, image, description }) => {
    const classes = useStyles();
    const {t} = useTranslation('common');

    const WeekDay = (i) => {
        switch (i) {
            case 0: return t('dropOffPickup.monday');
            case 1: return t('dropOffPickup.tuesday');
            case 2: return t('dropOffPickup.wednesday');
            case 3: return t('dropOffPickup.thursday');
            case 4: return t('dropOffPickup.friday');
            case 5: return t('dropOffPickup.saturday');
            case 6: return t('dropOffPickup.sunday');
            default: return 'none';
        }
    }

    return (
        <Card elevation={0} className={classes.card}>
            <CardContent className={classes.cardContent}>
                {link && 
                    <iframe
                        className={classes.map}
                        title={title}
                        src={link}
                    />
                }

                {image && 
                    <ProgressiveImage 
                        src={image.thumbnails.large.url}
                        placeholder={image.thumbnails.small.url}
                    >
                        {(src) => <img className={classes.messageImage} alt={title} src={src} />}
                    </ProgressiveImage>
                }

                <Typography variant='h6' className={classes.header}>
                    {title}
                </Typography>

                {hours !== "" &&
                    <>
                    { hours.split(';').map((hour, i) => (
                        <div key={i}>
                            <Typography variant='body1' className={classes.text}><b>{WeekDay(i)}</b>{": " + hour}</Typography>
                            {i < 6 && <br />}
                        </div>
                    ))}
                    </>
                }

                {description !== "" &&
                    <ReactMarkdown
                        linkTarget="_blank"
                        className={classes.text}
                    >
                        {description}
                    </ReactMarkdown>
                }
            </CardContent>
        </Card>
    )
}

export default Component;