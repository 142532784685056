import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    container: {
        top: '10vh',
        position: 'relative',
        width: '100%',
    },
    gridContainer: {
        display: 'flex',
        justifyContent: 'center',
    },
    pageButtonsContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    stickyFooter: {
        position: 'absolute',
        bottom: '0',
        marginBottom: '2%',
    },
    backButton: {
        [theme.breakpoints.up('xs')]: {
            height: '25px',
            width: '25px',
        },
        [theme.breakpoints.up('sm')]: {
            height: '40px',
            width: '40px',
        },
        [theme.breakpoints.up('md')]: {
            height: '60px',
            width: '60x',
        },
        [theme.breakpoints.up('lg')]: {
            height: '25px',
            width: '25px',
        },
        fill: '#33e29f',
        transform: 'rotate(180deg)',
    },
    pageNumbers: {
        fontSize: 20,
        margin: '0 4%'
    },
    nextButton: {
        height: '25px',
        width: '25px',
        fill: '#33e29f',
    },
    requestInstructions: {
        color: '#33e29f',
        backgroundColor: 'Transparent',
        backgroundRepeat: 'no-repeat',
        border: 'none',
        cursor: 'pointer',
        overflow: 'hidden',
        outline: 'none',
        padding: '0',
        fontSize: 20,
        textTransform: 'none',
    }
}));

export default useStyles;