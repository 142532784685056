import C from './actionTypes';
import K from '../utils/keys';
import { nativeApi, api, analytics, auth } from '../utils/admin';
import { ResetProducts } from './productActions';

export const SetSearchValue = (value) => ({
    type: C.SET_SEARCH_VALUE,
    payload: value
})

const isModelConfident = (product) => {
    // check to see if product is a prediction and if so if it's over the lowest bound confidence
    if (product.confidence_levels) {
        if ((product.confidence_levels.outer === -1 && 
             product.confidence_levels.inner === -1 && 
             product.confidence_levels.additional === -1)) {
            return false;
        }
    }
    return true;
}

const emitMissingProductAnalytics = (searchValue, userCommunity, userEmail, product, wasteType) => {
    var analyticsPayload = { 
        community: userCommunity,
        email: userEmail,
        product_name: product ? product.product_name : '',
        outer_confidence: product ? product.confidence_levels.outer : '',
        inner_confidence: product ? product.confidence_levels.inner : '',
        additional_confidence: product ? product.confidence_levels.additional : '',
        predicted_annotations: product ? product.annotationString : '',
        model_message: product ? product.modelMessage : '',
        waste_type: wasteType
    };
    if (wasteType === 'recycle') {
        analyticsPayload['gtin'] = searchValue;
    } else if (wasteType === 'compost') {
        analyticsPayload['search_value'] = searchValue;
    }
    analytics.logEvent('missing_product_scanned', analyticsPayload);
}

export const SubmitSearch = (value, language, source) => (dispatch, getState) => {
    let currentState = getState();
    let community = currentState.communities.selectedCommunities[currentState.communities.activeIndex];
    let selectedWasteType = community.waste_type;

    if (!value) value = getState().inputs.search;

    var user = auth.currentUser;
    let userEmail = '';
    let userId = '';

    if (user !== null) {
        userEmail = user.email;
        userId = user.uid;
    }

    if (source === 'scan') {
        analytics.logEvent('scan', {
            id: userId, 
            gtin: value.padStart(14, '0'),
            community: community[K.COMMUNITY.DISPLAY_NAME],
            email: userEmail,
            type: source,
        });
    } else {
        analytics.logEvent('search', {
            id: userId,
            search_value: value,
            community: community[K.COMMUNITY.DISPLAY_NAME],
            email: userEmail,
            type: source,
            waste_type: selectedWasteType,
        });
    }

    dispatch(ResetProducts());
    dispatch({
        type: C.SUBMIT_SEARCH,
        payload: true
    });
    dispatch({
        type: C.SET_SEARCH_ERROR,
        payload: false
    });

    nativeApi({
        method: 'post',
        url: 'search',
        data: {
            searchValue: value,
            index: selectedWasteType,
            language: language,
            communityId: community[K.COMMUNITY.ID],
            email: userEmail,
            community: community[K.COMMUNITY.DISPLAY_NAME],
        },
    }).then(response => {
        console.log(response.data);

        // catch lowest probability cases to prevent showing the list...
        if (response.data.length === 1) {

            // determine if this is a prediction or an existing product
            var productStatus = 'exists';
            if (response.data[0].modelOutput) {
                console.log(response.data[0]);
                productStatus = response.data[0].modelOutput.trust;
                // if the model can't make a prediction the trust field isn't present on the response
                // if (!productStatus) {
                //     productStatus = 'skip';
                // }
                emitMissingProductAnalytics(value, getState().communities.selectedCommunities[getState().communities.activeIndex][K.COMMUNITY.DISPLAY_NAME], userEmail, response.data[0], selectedWasteType);
            }

            // if (productStatus === 'exists' || productStatus === 'trust' || productStatus === 'warn') {
            if (productStatus || response.data[0].product_name) {
                // either a trusted prediction or an existing annotated product
                dispatch({
                    type: C.SET_PRODUCTS,
                    payload: response.data
                });
                dispatch({
                    type: C.SET_PHOTO_PROMPT_DATA,
                    payload: {
                        name: response.data[0].product_name,
                        upc: value,
                    }
                });
            } else {
                // store name and upc for use by photo prompt page
                dispatch({
                    type: C.SET_PRODUCTS,
                    payload: []
                });
                dispatch({
                    type: C.SET_PHOTO_PROMPT_DATA,
                    payload: {
                        name: response.data[0].product_name,
                        upc: value,
                    }
                });
            }
        } else if (response.data.length === 0) {
            emitMissingProductAnalytics(value, getState().communities.selectedCommunities[getState().communities.activeIndex][K.COMMUNITY.DISPLAY_NAME], userEmail, response.data[0], selectedWasteType);
            dispatch({
                type: C.SET_PRODUCTS,
                payload: response.data
            });
            dispatch({
                type: C.SET_PHOTO_PROMPT_DATA,
                payload: {
                    name: "",
                    upc: value,
                }
            });
        } else {
            // show the user the list of products returned from the search
            dispatch({
                type: C.SET_PRODUCTS,
                payload: response.data
            });
            dispatch({
                type: C.SET_PHOTO_PROMPT_DATA,
                payload: {
                    name: "",
                    upc: value,
                }
            });
        }
        dispatch({
            type: C.SUBMIT_SEARCH,
            payload: false
        });
    }).catch(error => {
        console.log(error);
        console.log('ERROR SETTING PRODUCTS');
        dispatch({
            type: C.SET_SEARCH_ERROR,
            payload: true
        });
        dispatch({
            type: C.SUBMIT_SEARCH,
            payload: false
        });
    })
}