import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

// material-ui
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';

// styles
import useStyles from './styles';
import { Typography } from '@material-ui/core';

import { GetRewardsData, SetRewardsAlert } from '../../actions/rewardsActions';
import MissingProductPointsBadge from '../../images/10-points-rewards-badge-green.svg';
import ScanPointsBadge from '../../images/25-points-rewards-badge-green.svg';

const StyledDialog = withStyles({
    paper: {
        borderRadius: '45px',
        backgroundColor: '#ffffffff',
    },
})(Dialog);

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

const Component = ({ fetchRewardsData, showAlert, communityColor, communityTextColor, resetRewardsAlert }) => {

    const classes = useStyles({
        backgroundColor: communityColor ? communityColor : '#32E29F',
        textColor: communityTextColor ? communityTextColor : '#000000de',
    });
    const {t} = useTranslation('common');

    const [rewardsAlert, setRewardsAlert] = React.useState(false);

    useEffect(() => {
        if (showAlert) {
            setRewardsAlert(true);
            setTimeout(() => {
                setRewardsAlert(false);
                resetRewardsAlert();
                fetchRewardsData();
            }, 2500);
        }

    }, [showAlert, setRewardsAlert, resetRewardsAlert])

    return (
        <>
            <StyledDialog 
                TransitionComponent={Transition} 
                open={rewardsAlert} 
                className={classes.rewardsAlert} 
                BackdropProps={{style: {opacity: '0%'}}}
            >
                <Typography className={classes.pointsAlertText} variant='overline'>{t('rewards.youEarned')}</Typography>
                { showAlert === 'scan' &&
                    <img className={classes.pointsBadge} src={ScanPointsBadge}></img>
                }
                { showAlert === 'photo-prompt' &&
                    <img className={classes.pointsBadge} src={MissingProductPointsBadge}></img>
                }
                <Typography className={classes.pointsAlertBottomText} variant='overline'>{t('rewards.points')}</Typography>
            </StyledDialog>
        </>
    );
};

const mapStateToProps = state => {
    return {
        showAlert: state.rewards.showAlert,
        communityColor: state.communities.selectedCommunities[state.communities.activeIndex] ? state.communities.selectedCommunities[state.communities.activeIndex].community_color : "#32E29F",
        communityTextColor: state.communities.selectedCommunities[state.communities.activeIndex] ? state.communities.selectedCommunities[state.communities.activeIndex].community_text_color : "#000000de",
    }
}

const mapDispatchToProps = dispatch => {
    return {
        resetRewardsAlert: () => {
            dispatch(SetRewardsAlert(''));
        },
        fetchRewardsData: () => {
            dispatch(GetRewardsData());
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Component);