const state = {
    products: {
        selected: null,
        list: [],
        missing: {
            list: [],
            status: "",
            productRequest: "",
        },
        photoPromptProduct: {
            name: "",
            upc: "",
        }
    },
    rewards: {
        catalog: [],
        selected: null,
        points: 0,
        monthlyPoints: 0,
        redemptionHistory: [],
        showAlert: '',
        redemptionStatus: 'none'
    },
    inputs: {
        language: "en",
        search: "",
        searchError: false,
        searching: false,
        fetchingCommunity: false,
        email: "",
        materialTypes: {
            outer: [
                {
                    text: "",
                    id: "",
                }
            ],
            inner: [
                {
                    text: "",
                    id: "",
                }
            ],
            additional: [
                {
                    text: "",
                    id: "",
                }
            ]
        }
    },
    communities: {
        loadCommunityByQr: false,
        selected: null,
        alternate: null, // used to swap between waste types
        list: [], // array of community id, display name, and icon for both recycling and commposting
        launchCommunityBrowser: false,
        selectedCommunities: [],
        activeIndex: 0,
        recycle: null,
        compost: null,
        communitiesByLocation: null,
        loadFeedback: "",
    },
    app: {
        qrIds: "",
        selectedWasteType: 'recycle',
        initialSignIn: true,
        storedCommunityIds: '',
        storedPublicThreadId: '',
        showChangeCommunityTip: false,
        cameraDeviceId: 1,
    },
    categories: {
        selected: null,
        list: [],
        items: []
    },
    user: {
        personalWasteTracker: { 
            entries: [], 
            totalEmissionsReduced: 0, 
            milesReduced: 0, 
            homeEnergyReduced: 0
        },
        requestStatus: 'idle',
        createUserAccount: "",
        signedIn: false,
        info: {
            name: "",
            phoneNumber: "",
            enableEmails: false,
            enableTexts: false,
        },
        feedback: "",
        installPromptState: -1,
        emailVerified: false,
        scanHistory: [],
        fetchingUserScanHistory: false,
        publicThread: {
            registrationCode: "",
            status: "",
        }
    },
    faqs: {
        recycle: [],
        compost: [],
    },
    chat: {
        unreadCount: 0,
        conversations: {},
        selectedConversation: null,
        language: 'en',
        translating: false,
        initialized: false,
    },
}

export default state;