import { connect } from 'react-redux';
import { RedeemReward, ResetRedemptionStatus } from '../../actions/rewardsActions';
// import { SubmitPredictedProduct } from '../../actions/productActions';
import Component from './component';

const mapStateToProps = state => {
    return {
        selectedBrand: state.rewards.selected,
        userPoints: state.rewards.points,
        communityColor: state.communities.selectedCommunities[state.communities.activeIndex] ? state.communities.selectedCommunities[state.communities.activeIndex].community_color : "#32E29F",
        communityTextColor: state.communities.selectedCommunities[state.communities.activeIndex] ? state.communities.selectedCommunities[state.communities.activeIndex].community_text_color : "#000000de",
        redemptionStatus: state.rewards.redemptionStatus ? state.rewards.redemptionStatus : 'none',
    }
}

const mapDispatchToProps = dispatch => {
    return {
        redeemReward: (reward, price) => {
            dispatch(RedeemReward(reward, price));
        },
        resetRedemptionStatus: () => {
            dispatch(ResetRedemptionStatus());
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Component);