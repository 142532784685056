import { connect } from 'react-redux';
import { ClearFeedbackMessage, SendPasswordResetLink } from '../../actions/userActions';
import Component from './component';

const mapStateToProps = state => {
    return {
        language: state.inputs.language,
        signedIn: state.user.signedIn,
        feedback: state.user.feedback
    }
}

const mapDispatchToProps = dispatch => {
    return {

        sendPasswordResetLink: (email, password) => {
            dispatch(SendPasswordResetLink(email, password));
        },
        clearFeedbackMessage: () => {
            dispatch(ClearFeedbackMessage());
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Component);