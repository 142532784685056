import React, { useEffect, useState } from 'react';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Categories from '../../components/CompostCategories';
import Scanner from './Scanner';
import { isMobile, isInStandaloneMode, isAndroid } from '../../utils/helperFunctions';

import useStyles from './styles';

const Component = ({ installPromptState, clearPhotoPromptProduct, incrementInstallPrompt, signedIn, 
                     selectedLanguage, setCommunity, clearSelectedProduct, selectingCommunity, community, userSignedIn }) => {
    let { communityId } = useParams();
    let location = useLocation();
    let history = useHistory();
    const {t, i18n} = useTranslation('common');
    const classes = useStyles();
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (communityId && communityId.includes("rec")) { 
            setCommunity(communityId, selectedLanguage);
            history.push('/home');
        }

        clearSelectedProduct();
        clearPhotoPromptProduct();
        userSignedIn();

        if (!signedIn) {
            history.push('/login');
        }

        if (!isInStandaloneMode() && isMobile() && !selectingCommunity) {
            setOpen(true);
        }

        // add 1 to the install prompt state counter (show every ten home page visits)
        incrementInstallPrompt(installPromptState);
    }, []);

    useEffect(() => {
        if (!isInStandaloneMode() && isMobile() && !selectingCommunity) {
            setOpen(true);
        }
    }, [selectingCommunity])

    // verify that the user hasn't been signed out (can happen when resetting password)
    useEffect(() => {
        userSignedIn();
        if (!signedIn) {
            history.push('/login');
        }
    }, [signedIn, userSignedIn, history]);

    useEffect(() => {
        i18n.changeLanguage(selectedLanguage);
    }, [location.pathname, i18n, selectedLanguage]);


    // Prevent install prompt from being shown again
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <div className={classes.container}>
                {community &&
                    <>{ (community['waste_type'] === 'recycle') ?
                        <div className={classes.scannerContainer}>  
                            { !selectingCommunity && <Scanner /> }
                        </div>
                    : 
                        <Categories />}
                    </>
                }
            </div>
        </>
    )
}

export default Component;