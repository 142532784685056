import React from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core/styles';
import { Dialog, Typography, Button } from '@material-ui/core';

import { LaunchCommunityBrowser, ResetCommunityLoadFeedback, SetLoadingCommunityByQr } from '../../../actions/communityActions';
import { SetShowChangeCommunityTip } from '../../../actions/appActions';

const useStyles = makeStyles({
    root: {
        borderRadius: 15,
    },
    dialog: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'center',
        minHeight: '20vh',
        borderRadius: 15,
        // margin: 15,
    },
    message: {
        fontFamily: 'helvetica-neue-lt-std-65-medium',
        fontSize: 16,
        marginLeft: 10,
        marginRight: 10,
        marginTop: 10,
        marginBottom: 10,
    },
    buttonsContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        marginBottom: 15,
    },
    compostButton: {
        fontFamily: 'helvetica-neue-lt-std-65-medium',
        fontSize: 16,
        width: '45%',
        borderRadius: 50,
        color: 'white',
        backgroundColor: 'rgb(50, 226, 159)',
        textTransform: 'none',
    },
    recycleButton: {
        fontFamily: 'helvetica-neue-lt-std-65-medium',
        fontSize: 16,
        width: '45%',
        borderRadius: 50,
        backgroundColor: '#4D6376',
        color: 'white',
        textTransform: 'none',
    },
    compostDoneButton: {
        fontFamily: 'helvetica-neue-lt-std-65-medium',
        fontSize: 16,
        width: '45%',
        borderRadius: 50,
        backgroundColor: '#4D6376',
        color: 'white',
        textTransform: 'none',
    },
    recycleDoneButton: {
        fontFamily: 'helvetica-neue-lt-std-65-medium',
        fontSize: 16,
        width: '45%',
        borderRadius: 50,
        backgroundColor: 'rgb(50, 226, 159)',
        color: 'white',
        textTransform: 'none',
    },
    compostDoneButtonLong: {
        fontFamily: 'helvetica-neue-lt-std-65-medium',
        fontSize: 16,
        width: '95%',
        borderRadius: 50,
        backgroundColor: '#4D6376',
        color: 'white',
        textTransform: 'none',
    },
    recycleDoneButtonLong: {
        fontFamily: 'helvetica-neue-lt-std-65-medium',
        fontSize: 16,
        width: '99%',
        borderRadius: 50,
        backgroundColor: 'rgb(50, 226, 159)',
        color: 'white',
        textTransform: 'none',
    },
});

const CommunityLoadFeedback = ({ selectedCommunities, setShowChangeCommunityTip, loadCommunityByQr, setLoadingByQr, selectAnother, wasteType, loadFeedback, open, resetCommunityLoadFeedback, closeCommunityBrowser }) => {
    const history = useHistory()
    const {t} = useTranslation('common');

    const classes = useStyles();

    const handlePressAnother = () => {
        const nextWasteType = (wasteType === 'recycle' ? 'compost' : 'recycle');
        selectAnother(nextWasteType);
        resetCommunityLoadFeedback();
    }

    const handlePressDone = () => {
        if (selectedCommunities.length > 1) {
            setShowChangeCommunityTip(true);
        }
        setLoadingByQr(false);
        resetCommunityLoadFeedback();
        closeCommunityBrowser();
        history.push('/home');
    }

    return (
        <Dialog
            open={open}
            maxWidth='xl'
            fullWidth={true}
            classes={{paper: classes.root}}
        >
            <div className={classes.dialog}>
                <div className={classes.messageContainer}>
                    { !loadCommunityByQr &&
                        <Typography className={classes.message}>
                            {t('communityBrowser.loadFeedback.youSet')} {loadFeedback} {t('communityBrowser.loadFeedback.asYour')} {t('communityBrowser.loadFeedback.community')}
                        </Typography>
                    }

                    { loadCommunityByQr &&
                        <Typography className={classes.message}>
                            {t('communityBrowser.loadFeedback.allSet')}
                        </Typography>
                    }
                </div>
                <div className={classes.buttonsContainer}>
                    { !loadCommunityByQr ? 
                    <>
                        <Button 
                            className={wasteType === 'recycle' ? classes.recycleButton : classes.compostButton}
                            onClick={handlePressAnother}
                        >
                            { t('communityBrowser.loadFeedback.addAnother') }
                        </Button>
                        <Button 
                            className={wasteType === 'recycle' ? classes.recycleDoneButton : classes.compostDoneButton}
                            onClick={handlePressDone}
                        >
                                {t('communityBrowser.loadFeedback.done')}
                        </Button>
                    </>
                    :
                        <Button 
                            className={wasteType === 'recycle' ? classes.recycleDoneButtonLong : classes.compostDoneButtonLong}
                            onClick={handlePressDone}
                        >
                                {t('communityBrowser.loadFeedback.continue')}
                        </Button>
                    }

                </div>
            </div>
        </Dialog>
    );
}

const mapStateToProps = state => {
    return {
        loadFeedback: state.communities.loadFeedback,
        loadCommunityByQr: state.communities.loadCommunityByQr,
        selectedCommunities: state.communities.selectedCommunities,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        closeCommunityBrowser: () => {
            dispatch(LaunchCommunityBrowser(false));
        },
        resetCommunityLoadFeedback: () => {
            dispatch(ResetCommunityLoadFeedback());
        },
        setLoadingByQr: (value) => {
            dispatch(SetLoadingCommunityByQr(value));
        },
        setShowChangeCommunityTip: (value) => {
            dispatch(SetShowChangeCommunityTip(value));
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CommunityLoadFeedback);