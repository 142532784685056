import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    card: {
        height: '100%',
        width: '100%',
        textAlign: 'left',
        justifyContent: 'flex-start',
        alignItems: 'center',
        padding: '10px',
        borderRadius: 15,
    },
    media: {
        height: 80,
        width: 80,
        objectFit: 'contain',
    },
    leftText: {
        marginLeft: 10,
        fontSize: 16,
        textAlign: 'left'
    },
    rightText: {
        marginLeft: 'auto',
        fontSize: 16,
    },
    claimReward: {
        height: 80,
        width: 80,
        objectFit: 'contain',
        marginLeft: 'auto',
    },
    redemptionHeader: {
        display: 'flex'
    },
    redemptionFooter: {
        display: 'flex',
        marginTop: 'auto',
    }
});

export default useStyles;