import React,  { useEffect } from 'react';
import { connect } from 'react-redux';
import Divider from '@material-ui/core/Divider';
import TipItem from './TipItem';
import { makeStyles } from '@material-ui/core';
import { FetchCommunityTips } from '../../../actions/communityActions';

const useStyles = makeStyles({
    tipsContainer: {
        backgroundColor: 'white',
        paddingBottom: '12vh',
    },
})

export const Component = ({ language, tips, fetchTips }) => {
    const classes = useStyles();

    useEffect(() => {
        fetchTips(language);
    }, [fetchTips, language]);

    return (
        <div className={classes.tipsContainer} >
            {tips.map((item, i) => (
                <div key={i}>
                    {i > 0 && <Divider light />}
                    <TipItem title={item.title} image={item.image} message={item.content} link={item.link}/>
                </div>
            ))}
        </div>
    )
}

const mapStateToProps = state => {
    return {
        tips: state.communities.selectedCommunities[state.communities.activeIndex] && state.communities.selectedCommunities[state.communities.activeIndex].tips ?
            state.communities.selectedCommunities[state.communities.activeIndex].tips
            : [],
        language: state.inputs.language ? state.inputs.language : 'en',
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchTips: (language) => {
            dispatch(FetchCommunityTips(language));
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Component);