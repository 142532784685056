import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import useStyles from './styles';

const Component = ({ value, onInput, onSubmitSearch, language }) => {
    const classes = useStyles();
    const {t} = useTranslation('common');
    let history = useHistory();

    const handleSubmit = event => {
        console.log('searching...');
        event.preventDefault();
        onSubmitSearch(value, language);
        history.push('/search');
    }

    return (
        <form onSubmit={handleSubmit} style={{display: 'flex', alignItems: 'center',}}>
            <input
                className={classes.inputField}
                id="search bar"
                label="search bar"
                placeholder={t('searchField.placeholder')}
                type="search"
                value={value}
                onChange={event => onInput(event.currentTarget.value)}
            />
        </form>

    )
}

export default Component;